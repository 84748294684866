import { z } from "zod";

export const NotificationSchema = z.object({
  description: z.string().min(1, "Notification name is required"),
  trigger: z.string().min(1, "Trigger is required"),
  medium: z.string().min(1, "Platform identifier is required"),
  audience: z.number().optional(), // For audience
  template: z.string().min(1, "Template is required"),
  // tags: z.string().array().default([]),
  // parameters: z.string().array().default([]),
});
