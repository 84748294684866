import React from "react";
import Modal from "../../../../common/Modal/Modal";
import Text from "../../../../common/Text/Text";
import { TransactionIdLink, TransactionModalContainer, TransactionWrapper } from "./styles";
import ScrollableSection from "../../../../common/ScrollableSection";
import Flex from "../../../../common/Flex";
import { capitalizeFirstLetter, formatTimeStamp, truncateWithMiddleDots } from "../../../../../utilities";

export const TransactionHistoryModal = ({ showModal, toggleModal, transactionHistoryData = [] }) => {

  return (
    <Modal showModal={showModal} toggleModal={toggleModal}>
      <TransactionModalContainer>
        <Text fontSize="28px" fontWeight={325}>
          Transaction History
        </Text>
        <Flex flexDirection="column" style={{ marginTop: "20px" }}>
          <div style={{ width: "100%", display: "grid", gridTemplateColumns: "1fr 1fr 1fr", padding: "0 10px 10px" }}>
            <Text fontWeight={325} fontSize="13px" color="#A498ED">
              ID
            </Text>
            <Text fontWeight={325} fontSize="13px" color="#A498ED">
              Status
            </Text>
            <Text fontWeight={325} fontSize="13px" color="#A498ED">
              TX ID
            </Text>
          </div>
          <ScrollableSection>
            <Flex flexDirection="column" rowGap="10px">
              {transactionHistoryData.map(transaction => (
                <TransactionWrapper>
                  <Text>{formatTimeStamp(transaction.date, "MMM Do YYYY")}</Text>
                  <Text>{capitalizeFirstLetter(transaction.status)}</Text>
                  <TransactionIdLink
                    href={`https://polygonscan.com/tx/${transaction.txHashId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {truncateWithMiddleDots(transaction.txHashId)}
                  </TransactionIdLink>
                </TransactionWrapper>
              ))}
            </Flex>
          </ScrollableSection>
        </Flex>
      </TransactionModalContainer>
    </Modal>
  );
};
