import moment from "moment";
import DatePicker from "react-datepicker";
import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import * as S from "./styles";
import Container from "../../common/Container/Container";
import nameMap from "../../../constants/analyticsNameMap";
import LoadingSkeleton from "../../common/LoadingSkeleton";
import { getAnalyticsTable } from "../../../api/analytics";
import { notifyWarning } from "../../common/ToastComponent";
import { useLogin } from "../../../providers/Loginprovider";
import { useGetSecHeaders } from "../../../hooks/useHeaders";
import { SpinnerLoader } from "../../common/SpinningLoader";

import "react-datepicker/dist/react-datepicker.css";
import { CalendarPurple } from "../../../assets/svg";

const customDatePickerStyles = { position: "absolute", right: 4, top: "3px" };

const AnalyticsTable = () => {
  const headers = useGetSecHeaders();
  const { orgIdState } = useLogin();
  const now = moment();

  const [startDate, setStartDate] = useState(new Date());
  const [selectedMonth, setSelectedMonth] = useState(() => now?.month() + 1);
  const [selectedYear, setSelectedYear] = useState(() => now?.year());

  const {
    data: resp,
    isLoading,
    isError,
    isFetching,
  } = useQuery({
    queryKey: ["analytics-table", selectedMonth, selectedYear, orgIdState],
    queryFn: () => getAnalyticsTable({ organizationId: orgIdState, month: selectedMonth, year: selectedYear }, headers),
    refetchOnWindowFocus: false,
    enabled: !!headers?.encryptedHeader && !!orgIdState,
    keepPreviousData: true,
    meta: {
      disableGlobalErrorHandler: true,
    },
  });

  const data = resp?.data?.data;
  const tableError = resp?.error;

  useEffect(() => {
    if (tableError || isError) notifyWarning("There was a problem loading analytics table data");
  }, [isError, tableError]);

  if (isLoading) {
    return <LoadingSkeleton height="400px" />;
  }
  return (
    <div>
      <Container flex columnGap="18px">
        <S.TableDatePicker
          showIcon
          toggleCalendarOnIconClick
          icon={<CalendarPurple style={customDatePickerStyles} />}
          dateFormat="MMMM YYYY"
          showMonthYearPicker
          selected={startDate}
          onChange={date => {
            setStartDate(date);
            setSelectedMonth(moment(date).month() + 1);
            setSelectedYear(moment(date).year());
          }}
          disabled={isFetching}
          calendarClassName="month-year-calendar"
          maxDate={now._d}
        />
        {isFetching && <SpinnerLoader />}
      </Container>

      {data && Array.isArray(data) && data.length ? (
        <S.TableContainer>
          <S.TableHeader>
            {Object.keys(data?.[0])?.map(item => (
              <div>{nameMap[item] || item}</div>
            ))}
          </S.TableHeader>

          <Container>
            {data.map(row => (
              <S.TableRow>
                {Object.values(row)?.map(item => (
                  <div>{item}</div>
                ))}
              </S.TableRow>
            ))}
          </Container>
        </S.TableContainer>
      ) : (
        <Container flex justifyContent="center" alignItems="center" height="200px">
          No data was found for the selected month
        </Container>
      )}
    </div>
  );
};

export default AnalyticsTable;
