import React, { useState } from "react";
import styled from "styled-components";
import Text from "../components/common/Text/Text";
import Modal from "../components/common/Modal/Modal";
import { notifyError } from "../components/common/ToastComponent";
import { ButtonUnstyled, ButtonRound } from "../components/common/Button";

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  column-gap: 6px;
  margin-top: 24px;
`;

const ConfirmButton = styled(ButtonRound)`
  ${props => `
    background: ${
      props.btnVariant === "delete" ? "#ED6140" : "linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)"
    };
`}
`;

const MessageText = styled.p`
  color: #a498ed;
  font-size: 16px;
`;

const useConfirm = ({ callback, message, onSuccess, onFailure, btnVariant = "", title = "" }) => {
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(prev => !prev);
  const triggerModal = () => setOpenModal(true);

  async function handleCb() {
    if (!callback) return;
    try {
      // NOTE: Mutate functions from useMutation will not wait return a promise so the code below will not wait for the API call to return.
      // If it is a requirement to wait, use mutateAsync instead
      const res = await callback();
      if (res?.error) {
        throw new Error(res.error);
      }
      if (onSuccess) onSuccess();
      setOpenModal(false);
    } catch (error) {
      let message = "";
      if (typeof error === "string") {
        message = error;
      } else {
        message = error?.message || error?.data || "Something went wrong";
      }
      notifyError(message);
      if (onFailure) onFailure();
    }
  }
  function ConfirmDialogue({ isLoading = false }) {
    return (
      <Modal
        customStyles={{ minWidth: "400px", paddingTop: "20px", maxWidth: "600px" }}
        showModal={openModal}
        toggleModal={toggleModal}
      >
        {title && <Text fontSize="24px">{title}</Text>}
        <MessageText>{message || "Are you sure?"}</MessageText>

        <ButtonContainer>
          <ConfirmButton btnVariant={btnVariant} onClick={handleCb} disabled={isLoading}>
            {btnVariant === "delete" ? "Yes, delete" : "Confirm"}
          </ConfirmButton>
          <ButtonUnstyled onClick={() => setOpenModal(false)} disabled={isLoading}>
            Cancel
          </ButtonUnstyled>
        </ButtonContainer>
      </Modal>
    );
  }

  return { ConfirmDialogue, triggerModal };
};

export default useConfirm;
