import React, { useState } from "react";
import { UserActivityWrapper } from "./styles";
import Text from "../../../../common/Text/Text";
import { capitalizeFirstLetter, truncateWithMiddleDots } from "../../../../../utilities";
import { TransactionHistoryModal } from "./TransactionHistoryModal";

export const UserActivityRow = ({ data, index }) => {
  const [showTransactionHistory, setShowTransactionHistory] = useState(false);

  const toggleTransactionHistoryModal = () => {
    setShowTransactionHistory(!showTransactionHistory);
  };

  return (
    <>
      <UserActivityWrapper key={index} onClick={toggleTransactionHistoryModal}>
        <Text fontSize="13px">{data.poolId}</Text>
        <Text fontSize="13px">{truncateWithMiddleDots(data.poolName)}</Text>
        <Text fontSize="12px">{data.isTeam === "" ? "SOLO" : "TEAM"}</Text>
        <Text fontSize="13px">{data.positionInPool}</Text>
        <Text fontSize="13px">
          {data.poolPrizeWon} {data.tokenSymbol}
        </Text>
        <Text fontSize="13px">{capitalizeFirstLetter(data.status)}</Text>
      </UserActivityWrapper>
      <TransactionHistoryModal showModal={showTransactionHistory} toggleModal={toggleTransactionHistoryModal} transactionHistoryData={data?.transactionHistory}/>
    </>
  );
};
