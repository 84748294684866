import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";

import * as S from "./styles";
import { getGamesList } from "../../../../api/game";
import CustomDateTimePicker from "../../../common/CustomDateTimePicker";
import { STATUS_MAP, STATUS_OBJECT } from "../../../../constants/status";
import { poolStatusFormatter } from "../../../../utilities";
import useOnClickOutside from "../../../../hooks/useClickOutside";

export const TournamentFilter = ({
  toggleFilter,
  showFilter,
  setShowFilter,
  filterProperties,
  setFilterProperties,
  propertyToRemove,
  setPropertyToRemove,
}) => {
  // The following function will fetch data from the cache as the games function is already called before in the lobby page
  const {
    data: activeGames,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["games"],
    queryFn: getGamesList,
    refetchOnWindowFocus: false,
  });

  const [statusFilter, setStatusFilter] = useState("");
  const [gameFilter, setGameFilter] = useState("");
  const [endDateInput, setEndDateInput] = useState("");
  const [startDateInput, setStartDateInput] = useState("");

  const status = Object.values(STATUS_MAP).map(item => ({
    value: item,
    label: poolStatusFormatter(item),
  }));

  function handleStatusFilterChange(value) {
    if (value === statusFilter) {
      setStatusFilter("");
    } else {
      setStatusFilter(value);
    }
  }

  function handleGameFilterChange(value) {
    if (value === gameFilter) {
      setGameFilter("");
    } else {
      setGameFilter(value);
    }
  }

  useEffect(() => {
    let temp = { ...filterProperties };

    if (statusFilter) {
      temp["poolStatus"] = statusFilter;
    } else {
      delete temp["poolStatus"];
    }

    if (gameFilter) {
      temp["game"] = gameFilter;
    } else {
      delete temp["game"];
    }

    if (startDateInput && !isNaN(startDateInput)) {
      temp["startDate"] = startDateInput;
    } else {
      delete temp["startDate"];
    }

    if (endDateInput && !isNaN(endDateInput)) {
      temp["endDate"] = endDateInput;
    } else {
      delete temp["endDate"];
    }

    setFilterProperties(temp);
  }, [statusFilter, gameFilter, startDateInput, endDateInput]);

  // The following useEffect is used to handle filter updates made from the filter chips in the main lobby page
  useEffect(() => {
    if (!propertyToRemove) return;

    if (propertyToRemove === "poolStatus") setStatusFilter("");
    if (propertyToRemove === "game") setGameFilter("");
    if (propertyToRemove === "startDate") setStartDateInput(null);
    if (propertyToRemove === "endDate") setEndDateInput(null);

    let temp = { ...filterProperties };
    delete temp[propertyToRemove];
    setFilterProperties(temp);
    setPropertyToRemove(null);
  }, [propertyToRemove]);

  const ref = useRef(null);

  useOnClickOutside(ref, () => {
    if (setShowFilter) {
      setShowFilter(false);
    }
  });

  return (
    <S.FilterContainer ref={ref} isOpen={showFilter} onBackgroundClick={toggleFilter} onEscapeKeydown={toggleFilter}>
      {activeGames && (
        <S.RowWrapper>
          <S.FilterItem>Game</S.FilterItem>
          <S.FilterContainerSelector>
            {activeGames?.map((game, index) => (
              <S.FilterChips
                key={index}
                selectedFilter={gameFilter === game.id}
                onClick={() => handleGameFilterChange(game.id)}
              >
                {game.visibleName}
              </S.FilterChips>
            ))}
          </S.FilterContainerSelector>
        </S.RowWrapper>
      )}

      <S.RowWrapper>
        <S.FilterItem>Status</S.FilterItem>
        <S.FilterContainerSelector>
          {status.map((item, index) => (
            <S.FilterChips
              key={index}
              selectedFilter={statusFilter === item.value}
              onClick={() => handleStatusFilterChange(item.value)}
            >
              {item.label}
            </S.FilterChips>
          ))}
        </S.FilterContainerSelector>
      </S.RowWrapper>

      <S.RowWrapper>
        <S.FilterItem>Start Date</S.FilterItem>
        <S.DateSelectorWrapper>
          <CustomDateTimePicker
            onChange={setStartDateInput}
            value={startDateInput}
            allowClear
            className="custom-calendar-container home-calendar-container"
          />
        </S.DateSelectorWrapper>
      </S.RowWrapper>

      <S.RowWrapper>
        <S.FilterItem>End Date</S.FilterItem>
        <S.DateSelectorWrapper>
          <CustomDateTimePicker
            onChange={setEndDateInput}
            value={endDateInput}
            allowClear
            className="custom-calendar-container home-calendar-container"
          />
        </S.DateSelectorWrapper>
      </S.RowWrapper>
    </S.FilterContainer>
  );
};
