import React, { useEffect, useState } from "react";

import * as S from "./styles";
import TeamMemberRow from "./TeamMemberRow";
import TeamMemberAccountRow from "./TeamMemberAccountRow";
import Text from "../../../../common/Text/Text";
import Container from "../../../../common/Container/Container";
import DetailsWrapperModal from "../../shared/DetailsWrapperModal";
import { Add, DiscordViolet, EmailViolet, WalletViolet } from "../../../../../assets/svg";
import { copyTextToClipboard, truncateWithMiddleDots } from "../../../../../utilities";
import ScrollableSection from "../../../../common/ScrollableSection";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { changeTeamStatus, getTeamById } from "../../../../../api/teams";
import { useLogin } from "../../../../../providers/Loginprovider";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../../../../common/SpinningLoader";
import { useGetSecHeaders } from "../../../../../hooks/useHeaders";
import { Button, ButtonOutline } from "../../../../common/Button";
import TeamAddMemberModal from "../TeamAddMemberModal";
import { ACTIVE, DELETE, INACTIVE } from "../../../../../constants/status";
import Flex from "../../../../common/Flex";
import { Actions } from "../../../../common/Actions/Actions";
import { notifyError, notifySuccess } from "../../../../common/ToastComponent";

const TeamDetailModal = ({ showModal, toggleModal, teamId }) => {
  const [activeSection, setActiveSection] = useState("users");
  const { orgIdState } = useLogin();
  const headers = useGetSecHeaders();
  const queryClient = useQueryClient();
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [showAllPlayers, setShowAllPlayers] = useState(false);

  function handleClick(e) {
    setActiveSection(e.target?.textContent?.toLowerCase() || "");
  }

  const [showStatusMenu, setShowStatusMenu] = useState(false);

  const toggleStatusMenu = () => {
    setShowStatusMenu(!showStatusMenu);
  };

  const {
    data: team,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["team", teamId, showAllPlayers],
    queryFn: () => getTeamById({ organizationId: orgIdState, teamId, viewAll: showAllPlayers }, headers),
    refetchOnWindowFocus: false,
    enabled: showModal && !!headers?.encryptedHeader && !!orgIdState,
  });

  const dataNotFound = !!headers && !!orgIdState && !isLoading && !team;
  const [activeTeamStatus, setActiveTeamStatus] = useState(team?.status);

  let allAccounts = [];
  if (team?.players) {
    try {
      team?.players?.forEach(userData =>
        userData?.accounts?.forEach(item => {
          item["blastUsername"] = userData.nick;
          item["avatar"] = userData.thumbnail;
          allAccounts.push(item);
        }),
      );
    } catch (err) {
      console.log("Error setting accounts");
    }
  }

  useEffect(() => {
    if (!dataNotFound) {
      setActiveTeamStatus(team?.status);
    } else {
      setActiveTeamStatus("");
    }
  }, [dataNotFound, team?.status]);

  const statsMap = team?.stats?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.name]: curr.value,
    }),
    {},
  );

  function toggleAddMemberModal() {
    setShowAddMemberModal(!showAddMemberModal);
  }

  const teamStatus = [
    {
      label: "Active",
      value: ACTIVE,
    },
    {
      label: "Inactive",
      value: INACTIVE,
    },
    {
      label: "Delete",
      value: DELETE,
    },
  ];

  const { mutate: mutationUpdateTeamStatus, isLoading: mutationUpdateTeamStatusIsLoading } = useMutation({
    mutationFn: () => {
      return changeTeamStatus({ teamId: teamId, status: activeTeamStatus }, headers);
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: e => {
      toggleStatusMenu();
      notifySuccess(e?.message || e?.data);
      queryClient.invalidateQueries({
        queryKey: ["team", teamId],
      });
    },
  });

  const { mutate: mutationShowAllPlayers, isLoading: mutationShowAllPlayersIsLoading } = useMutation({
    mutationFn: () => {
      setShowAllPlayers(!showAllPlayers);
      queryClient.invalidateQueries({
        queryKey: ["team", teamId, showAllPlayers],
      });
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: e => {
      return;
    },
  });

  return (
    <DetailsWrapperModal
      showModal={showModal}
      toggleModal={toggleModal}
      data={{ avatarUrl: team?.thumbnail, bannerUrl: team?.banner }}
      afterClose={() => {
        setActiveSection("users");
        setShowStatusMenu(false);
      }}
      customStyles={{ width: "793px", padding: "0" }}
    >
      {dataNotFound ? (
        <Container height="300px" flex alignItems="center" justifyContent="center">
          Data Not Found
        </Container>
      ) : isLoading ? (
        <SpinnerLoaderWrapper style={{ height: "300px" }}>
          <SpinnerLoader />
        </SpinnerLoaderWrapper>
      ) : (
        <>
          <S.TopRow>
            <Container flex columnGap="16px" rowGap="12px" alignItems="center" className="flex-col-mobile">
              <Text fontSize="28px" mobileFontSize="16px">
                {team?.name}
              </Text>
              <S.StatusContainer>
                <S.StatusWrapper onClick={() => toggleStatusMenu()}>
                  <S.StatusDot status={team?.status} />
                  <S.StatusText>{team?.status}</S.StatusText>
                </S.StatusWrapper>
                <Actions
                  showActions={showStatusMenu}
                  top="40px"
                  right="0px"
                  minWidth="130px"
                  closeActionsMenu={() => setShowStatusMenu(false)}
                >
                  <Flex flexDirection="column" alignItems="center" rowGap="9px">
                    <Flex flexDirection="column">
                      {teamStatus.map(status => (
                        <Flex alignItems="center" columnGap="5px">
                          <S.StatusSelector
                            value={status.value}
                            checked={status.value === activeTeamStatus}
                            onClick={() => {
                              setActiveTeamStatus(status.value);
                            }}
                          />
                          <S.StatusLabel>{status.label}</S.StatusLabel>
                        </Flex>
                      ))}
                    </Flex>
                    <S.Divider />
                    <Button
                      width="99px"
                      borderRadius="30px"
                      height="31px"
                      disabled={mutationUpdateTeamStatusIsLoading}
                      onClick={mutationUpdateTeamStatus}
                    >
                      Save
                    </Button>
                  </Flex>
                </Actions>
              </S.StatusContainer>
              <S.AddressBlock
                onClick={() => {
                  copyTextToClipboard(team.id, "Team ID copied to clipboard successfully");
                }}
              >
                {truncateWithMiddleDots(team?.id, 8, 5)}
              </S.AddressBlock>
            </Container>

            <ButtonOutline
              borderRadius="24px"
              border="2px solid #6C47FF"
              padding="14px, 18px, 14px, 18px"
              width="140px"
              height="37px"
              onClick={() => setShowAddMemberModal(true)}
            >
              <Container flex columnGap="16px" alignItems="center" justifyContent="space-between">
                <Add />
                <S.ButtonText>Add Member</S.ButtonText>
              </Container>
            </ButtonOutline>
          </S.TopRow>

          {!!statsMap && (
            <S.StatsContainer>
              <S.StatBlock>
                <Text fontSize="24px">
                  $
                  {statsMap["MONEY_EARNED"] && typeof statsMap["MONEY_EARNED"] === "number"
                    ? `${statsMap["MONEY_EARNED"].toFixed(2)}`
                    : `0`}
                </Text>
                <Text fontSize="12px" color="#A498ED">
                  Money earned
                </Text>
              </S.StatBlock>
              <S.StatBlock>
                <Text fontSize="24px">{statsMap["POOLS_PLAYED"] || 0}</Text>
                <Text fontSize="12px" color="#A498ED">
                  Tournaments Played
                </Text>
              </S.StatBlock>
              <S.StatBlock>
                <Text fontSize="24px">{statsMap["POOLS_WON"] || 0}</Text>
                <Text fontSize="12px" color="#A498ED">
                  Tournaments Won
                </Text>
              </S.StatBlock>
            </S.StatsContainer>
          )}

          <Container marginTop="28px" flex justifyContent="space-between" alignItems="center">
            <Flex columnGap="14px">
              <S.SelectorButton
                active={activeSection === "users"}
                borderRadius="50px"
                height="39px"
                onClick={handleClick}
              >
                Users
              </S.SelectorButton>
              <S.SelectorButton
                active={activeSection === "accounts"}
                borderRadius="50px"
                height="39px"
                onClick={handleClick}
              >
                Accounts
              </S.SelectorButton>
            </Flex>
            {activeSection === "users" && (
              <Flex alignItems="center" columnGap="5px">
                <S.CustomCheckbox type="checkbox" onChange={() => mutationShowAllPlayers()} checked={showAllPlayers} />
                <Text fontSize="12px">Show/hide all users</Text>
              </Flex>
            )}
          </Container>

          {/* Users Section */}
          {activeSection === "users" && (
            <Container marginTop="28px" paddingBottom="24px">
              <ScrollableSection minWidth="400px" scrollHeight="10px">
                <Container paddingLeft="28px" paddingRight="28px" marginBottom="10px">
                  <S.TeamMemberGrid>
                    <Text fontWeight={325} fontSize="13px" color="#A498ED">
                      Nickname
                    </Text>
                    <Text fontWeight={325} fontSize="13px" color="#A498ED">
                      ID
                    </Text>
                    <Text fontWeight={325} fontSize="13px" color="#A498ED">
                      Role
                    </Text>
                    <Text fontWeight={325} fontSize="13px" color="#A498ED">
                      Status
                    </Text>

                    {/* The div below is to align the headers with the data row below.The rows below have a Trash icon, so the div is addedto take up a similar space  */}
                    <div style={{ width: "25px", height: "21px" }} />
                  </S.TeamMemberGrid>
                </Container>
                <Container flex flexDirection="column" rowGap="12px" style={{ minHeight: "250px" }}>
                  {team?.players?.map(player => (
                    <TeamMemberRow data={player} teamData={team} />
                  ))}
                </Container>
              </ScrollableSection>
            </Container>
          )}

          {/* Accounts Section */}
          {activeSection === "accounts" && (
            <Container marginTop="28px">
              <ScrollableSection minWidth="400px" scrollHeight="10px">
                <Container paddingLeft="28px" paddingRight="28px" marginBottom="10px">
                  <S.TeamMemberAccountGridHeader>
                    <Text fontWeight={325} fontSize="13px" color="#A498ED">
                      Nickname
                    </Text>
                    <Text fontWeight={325} fontSize="13px" color="#A498ED">
                      Platform
                    </Text>
                    <Text fontWeight={325} fontSize="13px" color="#A498ED">
                      Game ID
                    </Text>
                  </S.TeamMemberAccountGridHeader>
                </Container>
                {/* <TeamMemberAccountRow /> */}
                <Container flex flexDirection="column" rowGap="12px" paddingBottom="24px">
                  {allAccounts?.map((account, index) => (
                    <TeamMemberAccountRow key={index} data={account} teamId={teamId} />
                  ))}
                </Container>
              </ScrollableSection>
            </Container>
          )}
          <TeamAddMemberModal
            currentPlayers={team?.players}
            toggleModal={toggleAddMemberModal}
            showModal={showAddMemberModal}
            teamId={teamId}
          />
        </>
      )}
    </DetailsWrapperModal>
  );
};

export default TeamDetailModal;
