import styled from "styled-components";
import { Button } from "../../../../common/Button";

export const AddressBlock = styled.div`
  height: 29px;
  display: flex;
  color: #a498ed;
  padding: 6px 11px;
  align-items: center;
  border-radius: 14.5px;
  justify-content: center;
  background-color: #3f2faa;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  @media (max-width: 450px) {
    font-size: 10px;
  }
`;

export const StatBlock = styled.div`
  height: 82px;
  flex-grow: 1;
  display: flex;
  row-gap: 12px;
  padding: 13px 19px;
  border-radius: 6px;
  flex-direction: column;
  background-color: #4e3cca;
`;
export const StatsContainer = styled.div`
  /* marginTop="33px" flex columnGap="15px" */
  display: grid;
  column-gap: 15px;
  margin-top: 33px;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: 665px) {
    row-gap: 15px;
    grid-template-columns: 1fr;
    flex-direction: column;
  }
`;

export const SelectorButton = styled(Button)`
  background: ${props => (props.active ? "linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)" : "#3F2FAA")};
`;

export const OrganizationRowWrapper = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 2fr;
`;

export const AccountRoWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 0.65fr 1fr;
`;

export const AddressButton = styled.button`
  background-color: transparent;
  border: none;
  color: unset;
`;

export const TeamContainer = styled.div`
  width: 226px;
  height: 50px;
  display: flex;
  background: #3f2faa;
  border-radius: 6px;
  padding: 12px;
  align-items: center;
  justify-content: space-between;
`;

export const TeamsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const TeamCounter = styled.span`
  font-size: 12px;
  font-weight: 325;
  line-height: 20px;
  text-align: left;
  color: #877add;
`;

export const TeamName = styled.span`
  font-size: 12px;
  font-weight: 325;
  line-height: 15.6px;
  text-align: left;
`;

export const UserActivityWrapper = styled.div`
  height: 50px;
  padding: 0 28px 0 28px;
  background: #3f2faa;
  border-radius: 6px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(6, 1fr);
  cursor: pointer;
`;

export const TransactionModalContainer = styled.div`
  width: 460px;
`;

export const TransactionWrapper = styled.div`
  height: 50px;
  border-radius: 6px;
  background-color: #4e3cca;
  padding: 0 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
`;

export const TransactionIdLink = styled.a`
  &:hover {
    text-decoration: underline;
  }
`;

export const DetailsRow = styled.div`
  margin-top: 56px;
  display: flex;
  column-gap: 16px;
  row-gap: 12px;
  align-items: center;

  p {
    font-size: 28px;
    margin: 0;
  }
  @media (max-width: 768px) {
    p {
      font-size: 18px;
    }
  }
  @media (max-width: 665px) {
    flex-direction: column;
    p {
      font-size: 18px;
    }
  }
`;

export const InfoBlock = styled.div`
  display: none;
  color: #a498ed;
  margin-top: 14px;
  font-size: 13px;
  column-gap: 17px;
  justify-content: center;

  @media (max-width: 665px) {
    display: flex;
  }
`;
