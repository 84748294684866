import React from "react";
import * as S from "./styles";
import CustomDateTimePicker from "../../../common/CustomDateTimePicker";
import Modal from "../../../common/Modal/Modal";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import Flex from "../../../common/Flex";
import { ButtonRound, ButtonUnstyled } from "../../../common/Button";

export const RoundDateSwitchModal = ({
  showModal,
  toggleModal,
  bracketDetail,
  mutateUpdateRoundDate,
  isLoadingUpdateRoundTime,
  startDateInput,
  setStartDateInput,
  endDateInput,
  setEndDateInput,
}) => {

  return (
    <Modal showModal={showModal} toggleModal={toggleModal} header={<S.ModalTitle>Edit round date</S.ModalTitle>}>
      <S.ModalContainer>
        <Flex justifyContent="center" columnGap="13px">
          <Flex flexDirection="column" rowGap="17px">
            <span>Start</span>
            <S.DateSelector>
              <CustomDateTimePicker
                className="custom-calendar-container"
                cal
                onChange={setStartDateInput}
                value={startDateInput}
                format="dd/MM/yy - HH:mm"
              />
            </S.DateSelector>
          </Flex>
          <Flex flexDirection="column" rowGap="17px">
            <span>End</span>
            <S.DateSelector>
              <CustomDateTimePicker
                className="custom-calendar-container"
                onChange={setEndDateInput}
                value={endDateInput}
                format="dd/MM/yy - HH:mm"
              />
            </S.DateSelector>
          </Flex>
        </Flex>
        <Flex justifyContent="flex-end">
          <ButtonUnstyled onClick={toggleModal}>Cancel</ButtonUnstyled>
          <ButtonRound
            onClick={() => mutateUpdateRoundDate({ startDate: startDateInput, endDate: endDateInput })}
            disabled={isLoadingUpdateRoundTime}
          >
            Save
          </ButtonRound>
        </Flex>
      </S.ModalContainer>
    </Modal>
  );
};
