import React, { useContext, useState } from "react";
import * as S from "./styles";
import Avatar from "../../../common/Avatar";
import { executePoolAction } from "../../../../api/pool";
import { notifyError, notifySuccess } from "../../../common/ToastComponent";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLogin } from "../../../../providers/Loginprovider";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import { useParams } from "react-router-dom";
import { SpinnerLoader } from "../../../layout/Loader/Loader";
import Flex from "../../../common/Flex";
import { EditPurple, GroupMoveIcon, MovePlayerIcon, SwapPurple, Trash } from "../../../../assets/svg";
import { Popover, Tooltip } from "antd";
import { Button, IconButton } from "../../../common/Button";
import { POOL_TYPES, STAGE_TYPES } from "../../../../constants";
import { TournamentContext } from "../../../../providers/TournamentContext";
import Container from "../../../common/Container/Container";
import { FINISHED } from "../../../../constants/status";
import { Actions } from "../../../common/Actions/Actions";
import { truncateWithDots } from "../../../../utilities";
import { TournamentTeamDetailModal } from "../TeamDetailModal";
import useScreenSizes from "../../../../hooks/useScreenSizes";
import { useMedia } from "use-media";

export const PlayerRow = ({
  data = {},
  poolType = POOL_TYPES.LEADERBOARD,
  poolMode,
  poolStatus,
  isMultiStagePool,
  stageId,
  refetchMultiStagePlayersQuery,
  setPlayersToMoveStage,
  joinMode,
  setPlayersToMoveGroup,
  setPlayerToSwapGroup,
}) => {
  const { poolId } = useParams();
  const { orgIdState } = useLogin();
  const headers = useGetSecHeaders();
  const queryClient = useQueryClient();

  const [showTeamDetailModal, setShowTeamDetailModal] = useState(false);

  const { setShowReportModal, setExtraReportData, stageInfo } = useContext(TournamentContext);
  const [toggleEditUserMenu, setToggleEditUserMenu] = useState(false);
  const [actionSelected, setActionSelected] = useState(false);

  const isLargeScreen = useMedia({ minWidth: 2300 });
  const isMediumScreen = useMedia({ minWidth: 1535 });
  const isSmallScreen = useMedia({ minWidth: 1200 });
  const isMobile = useMedia({ maxWidth: 768 });

  const { stageType, groups } = stageInfo || {};

  let truncateLength = 9;
  if (isLargeScreen) truncateLength = 28;
  else if (isMediumScreen) truncateLength = 13;
  else if (isSmallScreen) truncateLength = 9;
  else if (isMobile) truncateLength = 70; // allow more text when showing one card per row (on mobile screen)

  const toggleTeamDetailModal = () => {
    if (joinMode === "TEAM") {
      setShowTeamDetailModal(!showTeamDetailModal);
    } else {
      return;
    }
  };

  const { mutate: removePlayer, isLoading: isRemoving } = useMutation({
    mutationFn: () => {
      return executePoolAction(
        {
          organizationId: orgIdState,
          poolId,
          action: "remove_pool_player",
          data: {
            aTag: data?.aTag,
            userId: data?.userId,
            stageId: stageId && isMultiStagePool ? stageId : undefined,
          },
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: responseData => {
      if (responseData?.error) {
        notifyError(responseData?.message);
        return;
      }
      if (!isMultiStagePool) {
        queryClient.setQueryData(["pool", poolId], prevData => {
          return {
            ...prevData,
            data: {
              ...prevData.data,
              leaderboard: prevData?.data?.leaderboard?.filter(item => item?.userId !== data?.userId),
            },
          };
        });
      } else {
        // queryClient.invalidateQueries(["players", orgIdState, Number(poolId), stageId]);
        refetchMultiStagePlayersQuery();
      }
      notifySuccess(responseData?.message || responseData?.data);
    },
  });

  function handleOpenReportModal(data) {
    setExtraReportData({
      leaderboardRowId: data.id,
      userId: data.userId,
    });
    setShowReportModal(true);
  }

  // Popover states and functions
  const [openDelPopover, setOpenDelPopover] = useState(false);

  const hideDelPopover = () => {
    setOpenDelPopover(false);
  };

  const showDelPopover = () => {
    setOpenDelPopover(true);
  };

  const handleOpenChange = val => {
    setOpenDelPopover(val);
  };

  const toggleEditUsersMenu = () => {
    setToggleEditUserMenu(!toggleEditUserMenu);
  };

  const closeEditUsersMenu = () => {
    setToggleEditUserMenu(false);
  };

  const userActions = [
    //! Buttons removed temporary
    // { label: "Ban", id: "ban-player", icon: <Restricted /> },
    // { label: "Status", id: "status-player", icon: <Status /> },
    { label: "Kick Out", id: "delete-player", icon: <Trash /> },
  ];

  function clickUserAction(id) {
    if (id === "delete-player") {
      removePlayer();
    }
  }

  return (
    <>
      <S.PlayerRowContainer joinMode={joinMode} onClick={() => toggleTeamDetailModal()}>
        <S.PlayerData>
          <Avatar src={data?.avatarURL} size="30px" />
          <S.PlayerName>{truncateWithDots(data?.nick, truncateLength)}</S.PlayerName>
        </S.PlayerData>
        <Flex columnGap="4px" className="score-row">
          {poolMode === POOL_TYPES.ROUND_ROBIN
            ? data.gamesData?.map(item =>
                item.score > item.opponent?.score ? (
                  <S.ScoreWrapper winner>W</S.ScoreWrapper>
                ) : item.score < item.opponent?.score ? (
                  <S.ScoreWrapper>L</S.ScoreWrapper>
                ) : (
                  <S.ScoreWrapper draw>D</S.ScoreWrapper>
                ),
              )
            : null}
        </Flex>
        <S.BtnContainer>
          {/* {poolType === POOL_TYPES.LEADERBOARD ? (
            <Tooltip
              title={poolStatus === FINISHED ? "Action not allowed for finished pools" : "Report"}
              placement="top"
              color="#7265C8"
            >
              <S.RowActionButton
                onClick={e => {
                  e.stopPropagation();
                  handleOpenReportModal(data);
                }}
                disabled={poolStatus === FINISHED}
              >
                <Flex columnGap="11px" alignItems="center">
                  <ReportIcon />
                  <span>Report</span>
                </Flex>
              </S.RowActionButton>
            </Tooltip>
          ) : null} */}
          {isRemoving ? (
            <SpinnerLoader className="loader" />
          ) : poolType === POOL_TYPES.MULTI_STAGE ? (
            <Container flex>
              <S.EditUserContainer>
                <Button
                  paddingLeft="0"
                  paddingRight="0"
                  background="transparent"
                  onClick={e => {
                    e.stopPropagation();
                    toggleEditUsersMenu();
                  }}
                >
                  <EditPurple />
                </Button>
                <Actions
                  closeActionsMenu={closeEditUsersMenu}
                  toggleActions={toggleEditUsersMenu}
                  showActions={toggleEditUserMenu}
                  top="40px"
                  right="0px"
                >
                  {userActions && userActions.length ? (
                    userActions.map((action, index) => (
                      <S.ActionButton
                        key={index}
                        id={action.id}
                        reasonSelected={actionSelected === action.id}
                        onClick={() => {
                          setActionSelected(action.id);
                          clickUserAction(action.id);
                        }}
                      >
                        {action.icon}
                        <S.ActionLabel id={action.id}>{action.label}</S.ActionLabel>
                      </S.ActionButton>
                    ))
                  ) : (
                    <S.ActionButton disabled> No Reasons available</S.ActionButton>
                  )}
                </Actions>
              </S.EditUserContainer>

              {/* Move player next stage */}
              {/* <Button background="transparent"><MoveNextRound /></Button> */}
            </Container>
          ) : (
            <Popover
              content={
                <Container marginTop="22px">
                  <Button
                    width="100%"
                    background="#3F2FAA"
                    height="30px"
                    borderRadius="30px"
                    onClick={() => {
                      hideDelPopover();
                      removePlayer();
                    }}
                  >
                    Confirm
                  </Button>
                </Container>
              }
              zIndex="9999"
              title="Are you sure you want to remove this player from the pool?"
              trigger="click"
              open={openDelPopover}
              onOpenChange={handleOpenChange} // This bool allows thepopup to close when clicking outside
              color="#7265C8"
              overlayInnerStyle={{
                padding: "19px",
              }}
              overlayStyle={{
                color: "green !important",
              }}
              overlayClassName="player-remove-popover"
            >
              <S.RowActionButton
                onClick={e => {
                  e.stopPropagation();
                  showDelPopover();
                }}
                disabled={isRemoving}
              >
                <Flex columnGap="11px" alignItems="center">
                  <Trash />
                  <span>Remove</span>
                </Flex>
              </S.RowActionButton>
            </Popover>
          )}
          {poolType === POOL_TYPES.MULTI_STAGE && (
            <Tooltip title="Move player" placement="top" color="#7265C8">
              <IconButton
                onClick={e => {
                  e.stopPropagation();
                  setPlayersToMoveStage(prev => [...prev, data]);
                }}
                style={{ padding: 0, margin: 0 }}
              >
                <MovePlayerIcon />
              </IconButton>
            </Tooltip>
          )}
        </S.BtnContainer>
      </S.PlayerRowContainer>
      <TournamentTeamDetailModal
        showModal={showTeamDetailModal}
        toggleModal={toggleTeamDetailModal}
        data={data?.playerData?.players}
      />
    </>
  );
};
