import { Checkbox } from "antd";
import styled from "styled-components";
import Text from "../../../common/Text/Text";

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-width: 300px;
`;

export const ModalTitle = styled.h2`
  font-size: 28px;
  font-weight: 325;
  text-align: left;
  margin: 0;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 340px;
`;

export const Input = styled.input`
  width: 100%;
  height: 41px;
  gap: 0px;
  border-radius: 6px;
  opacity: 0px;
  background: #4e3cca;
  border: none;
  color: #ffffff;
  padding-left: 12px;

  &::placeholder {
    color: #a498ed;
  }
`;

export const SuggestionsList = styled.ul`
  position: absolute;
  top: 140%;
  left: 0;
  width: 100%;
  background: #4e3cca;
  border-radius: 4px;
  max-height: 344px;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  list-style: none;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 7px;
    height: 40px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #4e3cca;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #342788f0;
    border-radius: 6px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #342788;
  }
`;

export const SuggestionItem = styled.li`
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color: #5d49e5;
  }
`;

export const ArrowButton = styled.div`
  width: 41px;
  height: 41px;
  background: #4e3cca;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlayerList = styled.div`
  width: 340px;
  height: 344px;
  overflow-y: auto;
  border-radius: 6px;
  background: #4e3cca;
  padding: 17px 14px;
  display: flex;
  flex-direction: column;
`;

export const PlayerListText = styled(Text)`
  font-size: 14px;
  line-height: 28px;
`;

export const CustomCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    background-color: transparent;
    border-color: #6c47ff;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #6c47ff;
    border-color: transparent;
  }
`;

export const ConfirmationText = styled(Text)`
  max-width: 440px;
  font-size: 16px;
  font-weight: 325;
  line-height: 19.2px;
  text-align: left;
  color: #a498ed;
`;
