import React, { useContext, useMemo, useRef, useState } from "react";
import Modal from "../../../common/Modal/Modal";
import { InputUncontrolled, Label } from "../../../common/Input/Input";
import Flex from "../../../common/Flex";
import * as S from "./styles";
import Container from "../../../common/Container/Container";
import Text from "../../../common/Text/Text";
import { Button, ButtonOutline, ButtonUnstyled, IconButton } from "../../../common/Button";
import { notifyError, notifySuccess } from "../../../common/ToastComponent";
import {
  reportMatch as reportMatchApiCall,
  updateReportedMatch as updateReportApiCall,
  deleteReportedMatch as deleteReportApiCall,
  getPoolPlayerAndMatches,
} from "../../../../api/pool";
import { getStageLeaderboard } from "../../../../api/stage";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import { useLogin } from "../../../../providers/Loginprovider";
import { useParams } from "react-router-dom";
import { isNonEmptyObject } from "../../../../utilities";
import { TournamentContext } from "../../../../providers/TournamentContext";
import { POOL_TYPES, STAGE_TYPES } from "../../../../constants";
import { Add, Check, Cross, Edit, SaveIcon, Trash } from "../../../../assets/svg";
import { Tooltip, Popover } from "antd";

const InputRenderer = ({ value = [], required, hideLabel, defaultValue, className = "", onChange }) => {
  return (
    <Container>
      <Flex flexDirection="column" rowGap="8px">
        {value.map(field => {
          if (field.key === "label" && !hideLabel) {
            return (
              <Label>
                {field.value} {required ? "*" : ""}
              </Label>
            );
          }

          if (field.key === "input") {
            return (
              <InputUncontrolled
                required={required}
                name={field.value}
                placeholder={field.placeholder}
                type={field.type}
                style={{ background: "#342788", border: "none" }}
                defaultValue={defaultValue}
                className={className}
                onChange={onChange}
              />
            );
          }
        })}
      </Flex>
    </Container>
  );
};

const SelectRendered = ({
  value = [],
  required = true,
  playersDropdownOptions,
  hideLabel,
  defaultValue,
  className = "",
  index,
  onChange,
}) => {
  return (
    <Container>
      <Flex flexDirection="column" rowGap="8px">
        {!hideLabel && (
          <Label>
            {value} {required ? "*" : ""}
          </Label>
        )}
        <S.Select
          name={value}
          style={{ background: "#342788", border: "none", paddingRight: "4px" }}
          required={required}
          defaultValue={defaultValue?.id}
          className={className}
          onChange={onChange}
        >
          <option value={""}></option>
          {playersDropdownOptions.map(({ name, value }) => (
            <option value={value}>
              {name ? name : value === "p2Data" ? "Player 2" : value === "p1Data" ? "Player 1" : "Player"}
            </option>
          ))}
        </S.Select>
      </Flex>
    </Container>
  );
};
const ComponentRenderer = props => {
  const {
    required = true,
    style = [],
    value = [],
    visible = true,
    componentKey,
    playersDropdownOptions,
    hideLabel = false,
    defaultValue,
    className,
    onChange,
  } = props;

  // if (!visible) return null;

  if (componentKey === "full-input") {
    return (
      <InputRenderer
        value={value}
        required={required}
        hideLabel={hideLabel}
        defaultValue={defaultValue}
        className={className}
        onChange={onChange}
      />
    );
  }
  if (componentKey === "select") {
    return (
      <SelectRendered
        value={value}
        required={required}
        playersDropdownOptions={playersDropdownOptions}
        hideLabel={hideLabel}
        defaultValue={defaultValue}
        className={className}
        onChange={onChange}
      />
    );
  }
  return <div />;
};

const EditField = props => {
  const { compObj, playersDropdownOptions, defaultValue, className = "" } = props;

  return (
    <ComponentRenderer
      {...compObj}
      componentKey={compObj.key}
      playersDropdownOptions={playersDropdownOptions}
      hideLabel
      defaultValue={defaultValue}
      className={className}
    />
  );
};

const Wrapper = ({ isEdit = false, handleSubmit = () => {}, children }) => {
  if (isEdit) {
    return <form onSubmit={handleSubmit}>{children}</form>;
  } else {
    return (
      <Flex justifyContent="space-between" style={{ overflow: "auto" }} className="horizontal-scroll">
        {children}
      </Flex>
    );
  }
};

const ReportRowComponent = props => {
  const [isEdit, setIsEdit] = useState(false);
  const {
    handleSubmit,
    components,
    gameItem,
    playersDropdownOptions,
    handleDeleteRowClick,
    isLoadingAction = false,
  } = props;

  const [openDelPopover, setOpenDelPopover] = useState(false);

  const hideDelPopover = () => {
    setOpenDelPopover(false);
  };

  const handleOpenChange = newOpen => {
    setOpenDelPopover(newOpen);
  };
  return (
    <S.ReportRowContainer isEdit={isEdit}>
      <Wrapper handleSubmit={e => handleSubmit(e, () => setIsEdit(false))} isEdit={isEdit}>
        {components?.map(item => {
          let temp = "";
          if (Array.isArray(item.value)) {
            let val = item.value?.[item?.value?.length - 1]?.value;
            if (val.includes(".")) {
              let arr = val.split(".");
              temp = gameItem?.[arr[0]]?.[arr[1]];
            } else {
              temp = gameItem?.[val];
            }
          } else {
            temp = gameItem?.[item.value];
          }

          if (isEdit) {
            return (
              <EditField
                compObj={item}
                playersDropdownOptions={playersDropdownOptions}
                defaultValue={temp}
                className="edit-field"
              />
            );
          } else {
            if (typeof temp === "object" && !Array.isArray(temp)) {
              return <div className="data-row">{temp?.nick}</div>;
            } else {
              return <div className="data-row">{temp ?? "N/A"}</div>;
            }
          }
        })}

        {/* <button type="submit">Submit</button> */}
        <span>
          {isEdit ? (
            <Flex>
              <Button type="submit" disabled={isLoadingAction} style={{ width: "59px", borderRadius: "30px" }}>
                Save
              </Button>
              <IconButton onClick={() => setIsEdit(false)}>
                <Cross />
              </IconButton>
            </Flex>
          ) : (
            <Flex justifyContent="center">
              <IconButton onClick={() => setIsEdit(prev => !prev)} disabled={isLoadingAction}>
                <Edit />
              </IconButton>

              <Popover
                content={
                  <Container marginTop="22px">
                    <Button
                      width="100%"
                      background="#3F2FAA"
                      height="50.885px"
                      borderRadius="30px"
                      onClick={() => {
                        hideDelPopover();
                        handleDeleteRowClick();
                      }}
                    >
                      Confirm
                    </Button>
                  </Container>
                }
                zIndex="9999"
                title="Are you sure you want to delete this report?"
                trigger="click"
                open={openDelPopover}
                onOpenChange={handleOpenChange}
                color="#7265C8"
                overlayInnerStyle={{
                  color: "brown !important",
                  width: "340px",
                  padding: "19px",
                }}
                overlayStyle={{
                  color: "green !important",
                }}
                overlayClassName="match-report-popover"
              >
                <IconButton onClick={() => setOpenDelPopover(true)} disabled={isLoadingAction}>
                  <Trash />
                </IconButton>
              </Popover>
            </Flex>
          )}
        </span>
      </Wrapper>
    </S.ReportRowContainer>
  );
};

// Note: Further exaplanation of this function is mentioned below.
// This function specifically, takes the stringw we get from the backend. And extracts the fynamic portion.
//So if we get p1Nickname,we return nicknamefrom this function. It takes the dynamic string (such as p1Nickname) and the number (1 for p1,and 2 for p2) as arguments
const extractDynamicPropertyName = (str, num = "1") => {
  let v = str.substring(str.indexOf(num) + 1, str.indexOf("}"));
  v = v?.toLowerCase();
  return v;
};

// Note: The following function is used to handle dynamic properties that need to be displayed.
//For example: We are receiving a property in the shape of ${p1Nickname} & ${p2Nickname}
// The labelFormatter and extractDynamicPropertyName properties are created to handle these.
// The labelformatter will take the string, ${p1Nickname} for example, extract the nickname part, and then get the valye of the nickname from the p1 object

const labelFormatter = (label = "", p1 = {}, p2 = {}) => {
  if (label.startsWith("${p1")) {
    let remainingLabel = label.split(" ")?.[1] || "";
    let v = extractDynamicPropertyName(label, "1");
    return p1[v] ? `${p1[v]} ${remainingLabel}` : "";
  } else if (label.startsWith("${p2")) {
    let remainingLabel = label.split(" ")?.[1] || "";
    let v = extractDynamicPropertyName(label, "2");
    return p2[v] ? `${p2[v]} ${remainingLabel}` : "";
  } else return label || "";
};

const ColumnHeader = ({ value = [], p1Data, p2Data }) => {
  let label = "";
  if (value === "opponent.userId" || value === "opponent") {
    label = "Opponent";
  } else if (Array.isArray(value)) {
    label = value?.filter(item => item.key === "label")?.[0]?.value || "";
  } else {
    label = "";
  }
  // const label = Array.isArray(value) ? value?.filter(item => item.key === "label")?.[0]?.value : "Label";
  return (
    <div className="data-row" style={{ fontSize: "13px", marginLeft: "18px" }}>
      {labelFormatter(label, p1Data, p2Data)}
    </div>
  );
};

const MatchReportModal = ({ showModal = true, setShowModal = () => {} }) => {
  const headers = useGetSecHeaders();
  const queryClient = useQueryClient();
  const { orgIdState: organizationId } = useLogin();
  const { poolId } = useParams();
  const { orgIdState } = useLogin();

  const [dataArr, setDataArr] = useState([]);

  const addRowsBtnRef = useRef(null);

  const { extraReportData, setExtraReportData, poolData, stage, selectedGroupId, stageInfo } =
    useContext(TournamentContext);
  let components = [];
  if (stageInfo && stageInfo?.reportTemplate && stageInfo?.reportTemplate?.components) {
    components = stageInfo?.reportTemplate?.components;
  } else if (poolData?.reportTemplate && poolData?.reportTemplate?.components) {
    components = poolData?.reportTemplate?.components;
  }
  const { match } = extraReportData || {};

  const { data: { data: stageLeaderboardData } = {}, refetch: refetchStageLeaderboardData } = useQuery({
    queryKey: ["stage_leaderboard", orgIdState, Number(poolId), stage.stageId, selectedGroupId],
    queryFn: () =>
      getStageLeaderboard(
        {
          poolId,
          orgId: orgIdState,
          stageId: stage.stageId,
          groupId: selectedGroupId,
        },
        headers,
      ),
    enabled: !!headers?.encryptedHeader && !!orgIdState && !!stage.stageId,
    refetchOnWindowFocus: false,
  });
  const { pageId, roundId, branchId, roundsQuantity } = extraReportData || {};
  const { data: { data: poolPlayersData = {} } = {} } = useQuery({
    queryKey: ["players", orgIdState, poolId, pageId, branchId, roundId, roundsQuantity, stage],
    queryFn: () =>
      getPoolPlayerAndMatches(
        {
          poolId,
          organizationId: orgIdState,
          pageId: pageId,
          branchId: branchId,
          roundId: roundsQuantity ? roundId : 0,
          stageId: stage.stageId && poolData.poolType === POOL_TYPES.MULTI_STAGE ? stage.stageId : undefined,
          onlyAddStageId: stage.stageType === STAGE_TYPES.LEADERBOARD,
        },
        headers,
      ),
    enabled: !!headers?.encryptedHeader && !!orgIdState,
    refetchOnWindowFocus: false,
  });

  const playersDropdownOptions = useMemo(() => {
    if (
      poolData?.poolType === POOL_TYPES.BRACKETS ||
      (poolData?.poolType === POOL_TYPES.MULTI_STAGE && stage?.stageType === STAGE_TYPES.BRACKETS)
    ) {
      if (!match || !isNonEmptyObject(match)) return [];
      const { p1Data, p2Data } = match;
      if (!p1Data || !p2Data) return [];
      return [
        { name: p1Data.nickname || "Player 1", value: "p1Data" },
        { name: p2Data.nickname || "Player 2", value: "p2Data" },
      ];
    } else {
      if (poolData.poolType === POOL_TYPES.MULTI_STAGE && stage?.stageType === STAGE_TYPES.LEADERBOARD) {
        return stageLeaderboardData
          ?.filter(item => item.id !== extraReportData?.userId && item?.userId !== extraReportData?.userId) // adding && condition as some leaderboards have different userId and id
          ?.map(item => ({
            name: item.nick,
            value: item.id,
          }));
      } else {
        return poolData?.leaderboard
          ?.filter(item => item.id !== extraReportData?.userId && item?.userId !== extraReportData?.userId)
          ?.map(item => ({
            name: item.nick,
            value: item.id,
          }));
      }
    }
  }, [match, poolData?.poolType, extraReportData, stageLeaderboardData]);

  const { mutate: reportMatch, isLoading: isLoading } = useMutation({
    mutationFn: data => {
      const { userId, leaderboardRowId, ...rest } = extraReportData;
      if (rest?.roundsQuantity) delete rest.roundsQuantity;
      return reportMatchApiCall(
        {
          organizationId,
          poolId,
          reports: data?.map(reportItem => ({
            ...rest,
            playerId: leaderboardRowId,
            reportData: {
              ...reportItem,
            },
          })),
          userId: userId,
          stageId: stage?.stageId,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: responseData => {
      if (responseData?.error) {
        notifyError(responseData?.message);
        return;
      } else {
        notifySuccess("Report submitted successfully");
        setDataArr([]);
        const { pageId, roundId, branchId, roundsQuantity } = extraReportData || {};
        if (poolData.poolType === POOL_TYPES.MULTI_STAGE) {
          if (stage.stageType === STAGE_TYPES.LEADERBOARD) {
            refetchStageLeaderboardData(); // Needed to refetch query as invalidation was not working
          } else {
            queryClient.invalidateQueries({
              queryKey: ["stage_brackets", orgIdState, poolId, pageId, branchId, roundId, roundsQuantity],
            });
          }
        } else {
          let queryKey = "";
          if (poolData.poolType === POOL_TYPES.BRACKETS) {
            // NOTE: Check for stage here
            queryKey = ["players", orgIdState, poolId, pageId, branchId, roundId, roundsQuantity];
          } else {
            queryKey = ["pool", poolId];
          }
          queryClient.invalidateQueries({
            queryKey: queryKey,
          });
        }
      }
    },
  });

  const { mutate: updateReport, isLoading: isLoadingReportUpdate } = useMutation({
    mutationFn: data => {
      const { userId, leaderboardRowId, ...rest } = extraReportData;
      const stageId = data.stageId;
      delete data.stageId; // removing stageId from

      if (rest?.roundsQuantity) delete rest.roundsQuantity;
      return updateReportApiCall(
        {
          organizationId,
          poolId,
          report: {
            ...rest,
            // Add Stage ID here
            ...(stageId && {stageId: stageId}),
            reportData: {
              ...data,
            },
          },
          playerId: leaderboardRowId,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: (responseData, variables) => {
      if (responseData?.error) {
        notifyError(responseData?.message);
        return;
      } else {
        notifySuccess("Report updated successfully");
        // setShowModal(false);

        const { pageId, roundId, branchId, roundsQuantity } = extraReportData || {};
        if (poolData.poolType === POOL_TYPES.MULTI_STAGE) {
          if (stage.stageType === STAGE_TYPES.LEADERBOARD) {
            refetchStageLeaderboardData(); // Needed to refetch query as invalidation was not working
          } else {
            queryClient.invalidateQueries({
              queryKey: ["stage_brackets", orgIdState, poolId, pageId, branchId, roundId, roundsQuantity],
            });
          }
        } else {
          let queryKey = "";
          if (poolData.poolType === POOL_TYPES.BRACKETS) {
            // NOTE: Check for stage here
            queryKey = ["players", orgIdState, poolId, pageId, branchId, roundId, roundsQuantity];
          } else {
            queryKey = ["pool", poolId];
          }
          queryClient.invalidateQueries({
            queryKey: queryKey,
          });
        }
        // const { pageId, roundId, branchId, roundsQuantity } = extraReportData || {};
        // let queryKey = "";
        // if (poolData.poolType === POOL_TYPES.BRACKETS) {
        //   // NOTE: Check for stage here
        //   queryKey = ["players", orgIdState, poolId, pageId, branchId, roundId, roundsQuantity];
        // } else {
        //   queryKey = ["pool", poolId];
        // }
        // queryClient.invalidateQueries({
        //   queryKey: queryKey,
        // });
        if (variables.cb) {
          variables.cb();
        }
      }
    },
  });

  const { mutate: deleteReport, isLoading: isLoadingDeleteReport } = useMutation({
    mutationFn: report => {
      return deleteReportApiCall(
        {
          organizationId,
          poolId,
          report: report,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: responseData => {
      if (responseData?.error) {
        notifyError(responseData?.message);
        return;
      } else {
        notifySuccess("Report deleted successfully");
        // setShowModal(false);
        const { pageId, roundId, branchId, roundsQuantity } = extraReportData || {};
        if (poolData.poolType === POOL_TYPES.MULTI_STAGE) {
          if (stage.stageType === STAGE_TYPES.LEADERBOARD) {
            refetchStageLeaderboardData(); // Needed to refetch query as invalidation was not working
          } else {
            queryClient.invalidateQueries({
              queryKey: ["stage_brackets", orgIdState, poolId, pageId, branchId, roundId, roundsQuantity],
            });
          }
        } else {
          let queryKey = "";
          if (poolData.poolType === POOL_TYPES.BRACKETS) {
            // NOTE: Check for stage here
            queryKey = ["players", orgIdState, poolId, pageId, branchId, roundId, roundsQuantity];
          } else {
            queryKey = ["pool", poolId];
          }
          queryClient.invalidateQueries({
            queryKey: queryKey,
          });
        }
      }
    },
  });

  function handleDeleteRowClick(rowData) {
    if (
      poolData?.poolType === POOL_TYPES.BRACKETS ||
      (poolData?.poolType === POOL_TYPES.MULTI_STAGE && stage?.stageType === STAGE_TYPES.BRACKETS)
    ) {
      const { branchId, matchId, pageId, roundId } = extraReportData;
      const report = { branchId, matchId, pageId, roundId, reportId: rowData?.matchId };
      if (stage?.stageId) report.stageId = stage?.stageId;
      deleteReport(report);
    } else {
      const report = { playerId: extraReportData?.leaderboardRowId, reportId: rowData.id };
      if (stage?.stageId) report.stageId = stage?.stageId;
      deleteReport(report);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const formDataRefined = Object.fromEntries(formData);
    const reportData = [...dataArr];
    reportData.forEach(data => {
      // The following code can be used in case we need to delete the elements that are not required and have an emmpty value
      Object.keys(data).forEach(item => {
        if (data[item] === "") delete data[item];
      });
      // // Winner property needed to report brackets tournament (NOTE: This property is no longer being used. Keeping code for reference)
      if (data?.winner) {
        let temp = {
          nick: match[data.winner]?.nickname,
          tag: match[data.winner]?.aTag,
          winnerPosition: match?.winner,
        };
        data.winner = temp;
      }

      if (data.opponent) {
        const dataToDisplay =
          poolData?.poolType === POOL_TYPES.MULTI_STAGE ? poolPlayersData?.leaders : poolData?.leaderboard;
        let temp = [];
        if (!dataToDisplay || !Array.isArray(dataToDisplay)) {
          temp = [];
        } else {
          temp = dataToDisplay;
        }
        let playerData = temp?.find(item => item.id === data.opponent);

        if (playerData) {
          data.opponent = {
            aTag: playerData?.aTag,
            id: playerData.id,
            image: playerData.avatarUrl || "",
            nick: playerData.nick || playerData.nickname,
            userId: playerData.userId,
          };
        } else {
          data.opponent = {
            id: data.opponent,
          };
        }
      }
    });
    reportMatch(reportData);
  }

  const multiStageBracketsPlayersData = queryClient.getQueryData([
    "players",
    orgIdState,
    poolId,
    extraReportData?.pageId,
    extraReportData?.branchId,
    extraReportData?.roundId,
    extraReportData?.roundsQuantity,
  ]);

  const selectedPlayer =
    poolData?.poolType === POOL_TYPES.MULTI_STAGE
      ? stage.stageType === STAGE_TYPES.LEADERBOARD
        ? stageLeaderboardData?.find(item => item.id === extraReportData?.leaderboardRowId)
        : {} //multiStageBracketsPlayersData?.data?.leaders?.find(item => item.userId === extraReportData?.userId) (this is not working because extraReportData doesnt get proper ID. sent dani a video about the issue). we use it only to get _gamesData and do not need it to get_gamesData of bracketPools
      : poolData?.leaderboard?.find(
          item =>
            item.id ===
            (poolData?.poolType === POOL_TYPES.BRACKETS ? extraReportData?.userId : extraReportData?.leaderboardRowId),
        );

  function toggleModal() {
    setShowModal(!showModal);
  }

  const _gamesData = useMemo(() => {
    if (poolData?.poolType === POOL_TYPES.MULTI_STAGE) {
      if (stage.stageType === STAGE_TYPES.BRACKETS) {
        return extraReportData?.match?.gamesData || [];
      } else {
        return selectedPlayer?.gamesData || [];
      }
    } else {
      if (poolData?.poolType === POOL_TYPES.BRACKETS) {
        return extraReportData?.match?.gamesData || [];
      } else {
        return selectedPlayer?.gamesData || [];
      }
    }
  }, [poolData, selectedPlayer, match, stage.stageId, stageLeaderboardData, multiStageBracketsPlayersData]);

  const updateReportFormFunction = (e, index = 0, cb = () => {}) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    const data = Object.fromEntries(formData);

    // The following code can be used in case we need to delete the elements that are not required and have an emmpty value
    Object.keys(data).forEach(item => {
      if (data[item] === "") delete data[item];
    });

    data.gameIndex = index;

    // Winner property needed to report brackets tournament (NOTE: This property is no longer being used. Keeping code for reference)
    if (data?.winner) {
      let temp = {
        nick: match[data.winner]?.nickname,
        tag: match[data.winner]?.aTag,
        winnerPosition: match?.winner,
      };
      data.winner = temp;
    }
    if (data.opponent) {
      const dataToDisplay =
        poolData?.poolType === POOL_TYPES.MULTI_STAGE ? poolPlayersData?.leaders : poolData?.leaderboard;
      let temp = [];
      if (!dataToDisplay || !Array.isArray(dataToDisplay)) {
        temp = [];
      } else {
        temp = dataToDisplay;
      }
      let playerData = temp?.find(item => item.id === data.opponent);
      if (playerData) {
        data.opponent = {
          aTag: playerData?.aTag,
          id: playerData.id,
          image: playerData.avatarUrl || "",
          nick: playerData.nick || playerData.nickname,
          userId: playerData.userId,
        };
      } else {
        data.opponent = {
          id: data.opponent,
        };
      }
    }

    if (cb) {
      data.cb = cb;
    }
    if(stage && stage.stageId) {
      data.stageId = stage.stageId
    }
    updateReport(data);
  };

  function handleChange(e, index) {
    let temp = [...dataArr];
    if (temp[index]) {
      temp[index][e.target?.name] = e.target?.value;
    } else {
      temp[index] = { [e.target?.name]: e.target?.value };
    }

    setDataArr(temp);
    // console.log(temp);
  }

  function removeAddFormRow(index) {
    let temp = [...dataArr];
    temp.splice(index, 1);
    setDataArr(temp);
  }

  return (
    <Modal
      showModal={showModal}
      toggleModal={toggleModal}
      customStyles={{
        padding: "30px",
        paddingBottom: "20px",
        background: "#342788",
        maxHeight: "80%",
        width: "90%",
      }}
      tabletStyles={{
        width: "90%",
      }}
      mobileStyles={{
        width: "90%",
      }}
      laptopStyles={{
        width: "90%",
      }}
      afterClose={() => {
        setExtraReportData(null);
        setDataArr([]);
      }}
    >
      <Container marginBottom="24px">
        <Text fontSize="24px">
          Report {extraReportData?.usernameForReport ? `for ${extraReportData?.usernameForReport}` : ""}
        </Text>
      </Container>

      <Container marginBottom="46px">
        <Flex justifyContent="end" columnGap="24px">
          <ButtonOutline
            width="146px"
            padding="20px"
            borderRadius="30px"
            border="2px solid #6C47FF"
            onClick={() => {
              setDataArr(() => [...dataArr, { rowId: dataArr?.length }]); // We need to have row IDs in order to have unique keys. Which are necessary when creating or deleting rows to maintain the state
            }}
          >
            <Flex columnGap="10px" alignItems="center">
              <Add />
              Add Report
            </Flex>
          </ButtonOutline>
        </Flex>
      </Container>

      <Container>
        <S.ReportRowContainer isHeaderCol>
          <Flex justifyContent="space-between" style={{ overflow: "auto" }} className="horizontal-scroll">
            {components.map(compObj => (
              <ColumnHeader value={compObj.value} p1Data={match?.p1Data} p2Data={match?.p2Data} />
            ))}
            <div style={{ fontSize: "13px" }}>Actions</div>
          </Flex>
        </S.ReportRowContainer>
      </Container>
      <form onSubmit={handleSubmit}>
        {dataArr?.map((data, index) => (
          <Container key={data.rowId}>
            <S.ReportRowContainer
              style={{ display: "flex", columnGap: "10px", justifyContent: "space-between", alignItems: "center" }}
            >
              {components.map(compObj => (
                <ComponentRenderer
                  hideLabel
                  {...compObj}
                  componentKey={compObj.key}
                  playersDropdownOptions={playersDropdownOptions}
                  className="edit-field"
                  onChange={e => handleChange(e, index)}
                />
              ))}

              <Flex justifyContent="end">
                <Flex>
                  {/* <Button type="submit" disabled={isLoading} style={{ width: "59px", borderRadius: "30px" }}>
                    Save
                  </Button> */}
                  <IconButton
                    type="button"
                    onClick={() => {
                      removeAddFormRow(index);
                    }}
                  >
                    <Cross />
                  </IconButton>
                </Flex>
              </Flex>
            </S.ReportRowContainer>
          </Container>
        ))}
        <Button
          ref={addRowsBtnRef}
          type="submit"
          disabled={isLoading}
          style={{ width: "59px", borderRadius: "30px", display: "none" }}
        >
          Save
        </Button>
      </form>

      <Container>
        {_gamesData?.map((gameItem, index) => {
          return (
            <ReportRowComponent
              gameItem={gameItem}
              components={components}
              handleSubmit={(e, cb) => updateReportFormFunction(e, index, cb)}
              playersDropdownOptions={playersDropdownOptions}
              handleDeleteRowClick={() => handleDeleteRowClick(gameItem)}
              isLoadingAction={isLoading || isLoadingDeleteReport || isLoadingReportUpdate}
            />
          );
        })}
      </Container>

      <Container flex justifyContent="flex-end" alignItems="center">
        <ButtonUnstyled onClick={() => toggleModal()}>Cancel</ButtonUnstyled>
        <Button
          width="146px"
          padding="20px"
          borderRadius="30px"
          border="2px solid #6C47FF"
          onClick={() => {
            addRowsBtnRef?.current?.click();
          }}
          disabled={!dataArr.length}
        >
          <Flex columnGap="10px" alignItems="center">
            <SaveIcon />
            Save
          </Flex>
        </Button>
      </Container>
    </Modal>
  );
};

export default MatchReportModal;
