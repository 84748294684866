import styled from "styled-components";
import { breakpoints } from "../../themes";

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  padding: 0px 72px 10px 47px;
  ${breakpoints.down(breakpoints.laptopL)} {
    padding: 0px 38px 10px 47px;
  }
  ${breakpoints.down(breakpoints.laptop)} {
    padding: 0px 16px 10px 16px;
  }
`;