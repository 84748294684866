import React, { useState } from "react";
import * as S from "../../../views/Notifications/styles";
import { Edit, Playing, Trash } from "../../../assets/svg";
import DottedMenuComponent from "../../common/DottedMenu";
import Container from "../../common/Container/Container";
import OptionRow from "../../TournamentDetail/Brackets/RoundSelectorDropdown/OptionRow";
import { Button, IconButton } from "../../common/Button";
import { updateNotification } from "../../../api/notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { notifyError } from "../../common/ToastComponent";
import useConfirm from "../../../hooks/useConfirm";

const NotificationRow = ({ notification = {}, headers, handleEdit, handleDel }) => {
  const { description, id, isActive } = notification;

  const [selectedStatus, setSelectedStatus] = useState(isActive); // State for menu options
  const [notifIsActive, setNotifIsActive] = useState(isActive); // State for row status UI

  const { mutate: updateStatus, isLoading } = useMutation({
    mutationFn: variables => {
      const notificationId = variables.notificationId;
      return updateNotification(
        {
          notificationId: notificationId,
          data: {
            isActive: selectedStatus,
          },
        },
        headers,
      );
    },
    onError: (e, variables) => {
      notifyError(e?.data?.message || e?.message || e?.data);
      setSelectedStatus(notification.isActive);
    },
    onSuccess: (data, variables) => {
      if (variables.cb) variables.cb();
      setNotifIsActive(data?.data?.isActive);
    },
  });

  return (
    <>
      <S.NotifRow>
        <p>{description}</p>

        <DottedMenuComponent
          menuStyles={{ marginTop: "10px", marginRight: "80px" }}
          customComponent={
            <S.Status isActive={notifIsActive}>
              {notifIsActive && <Playing />}
              {notifIsActive ? "Active" : "Inactive"}
            </S.Status>
          }
        >
          {({ setShowDotMenu }) => (
            <S.ActivitySelectionMenu>
              <OptionRow
                handleSelect={() => {
                  setSelectedStatus(true);
                }}
                label={"Active"}
                active={selectedStatus === true}
                checkBorder="#705dec"
              />
              <OptionRow
                handleSelect={() => {
                  setSelectedStatus(false);
                }}
                label={"Inactive"}
                active={selectedStatus === false}
                checkBorder="#705dec"
              />

              <Button
                width="99px"
                borderRadius="30px"
                onClick={() => {
                  updateStatus({ notificationId: id, cb: () => setShowDotMenu(false) });
                }}
                disabled={isLoading}
              >
                Save
              </Button>
            </S.ActivitySelectionMenu>
          )}
        </DottedMenuComponent>
        <Container flex columnGap="13px">
          <IconButton onClick={() => handleEdit(notification)}>
            <Edit />
          </IconButton>
          <IconButton onClick={() => handleDel(notification.id)}>
            <Trash />
          </IconButton>
        </Container>
      </S.NotifRow>
    </>
  );
};

export default NotificationRow;
