import styled from "styled-components";
import { Button } from "../../../common/Button";

export const FilterContainer = styled.div`
  background: #3f2faa;
  width: 470px;
  border-radius: 6px;
  padding: 17px;
  position: absolute;
  top: 60px;
  display: ${props => (props.isOpen ? "block" : "none")};
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.25);
  user-select: none;
  z-index: 999;
  @media (max-width: 910px) {
    left: -200px;
  }
  @media (max-width: 320px) {
    left: -170px;
    width: 310px;
  }
`;

export const DateSelectorWrapper = styled.div`
  background-color: #4e3cca;
`;

export const DateSelectorContainer = styled.div`
  height: 40px;
  border-radius: 4px;
  background: #4e3cca;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
`;

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-bottom: 27px;
`;

export const FilterItem = styled.div`
  font-size: 14px;
  font-weight: 325;
`;

export const FilterContainerSelector = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const FilterChips = styled(Button)`
  height: 26px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.selectedFilter ? "#ffffff" : "#4e3cca")};
  border-radius: 5px;
  font-size: 11px;
  font-weight: 325;
  user-select: none;
  color: ${props => (props.selectedFilter ? "#342788" : "#ffffff")};
`;

export const InputContainer = styled.div`
  width: 374px;
  height: 38px;
  background: #3f2faa;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 11px;
  padding: 0 12px;
`;

export const Input = styled.input`
  width: 100%;
  height: 24px;
  background: transparent;
  border: none;
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;
  color: #a498ed;
  outline: none;
  &::placeholder {
    color: rgb(164, 152, 237);
    opacity: 1;
  }

  &::-webkit-input-placeholder {
    color: rgb(164, 152, 237);
    opacity: 1;
  }
`;
