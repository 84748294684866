import React, { useState } from "react";

import * as S from "./styles";
import Text from "../../../../common/Text/Text";
import { IconButton } from "../../../../common/Button";
import Container from "../../../../common/Container/Container";
import DetailsWrapperModal from "../../shared/DetailsWrapperModal";
import { Clock, CopyViolet, DiscordViolet, EmailViolet, Globe, Trash, WalletViolet } from "../../../../../assets/svg";
import { capitalizeFirstLetter, copyTextToClipboard, timeAgo, truncateWithMiddleDots } from "../../../../../utilities";
import AccountRow from "./AccountRow";
import ScrollableSection from "../../../../common/ScrollableSection";
import OrganizationRow from "./OrganizationRow";
import { DataRow } from "../../shared/styles";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getUserById, updateOrganizationPermission } from "../../../../../api/users";
import { useLogin } from "../../../../../providers/Loginprovider";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../../../../common/SpinningLoader";
import { notifyError, notifySuccess } from "../../../../common/ToastComponent";
import { useGetSecHeaders } from "../../../../../hooks/useHeaders";
import Avatar from "../../../../common/Avatar";
import Flex from "../../../../common/Flex";
import { UserActivityRow } from "./UserActivityRow";

const UserDetailModal = ({ showModal, toggleModal, setUserId, userId }) => {
  const [activeSection, setActiveSection] = useState("accounts");
  const { orgIdState } = useLogin();
  const headers = useGetSecHeaders();
  const queryClient = useQueryClient();
  const options = [
    { name: "Accounts", value: "accounts" },
    { name: "Organizations", value: "organizations" },
    { name: "Activity", value: "activity" },
    { name: "Teams", value: "teams" },
  ];

  function handleClick(e) {
    setActiveSection(e.target?.textContent?.toLowerCase() || "");
  }

  const {
    data: user,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["user", userId],
    queryFn: () => getUserById({ organizationId: orgIdState, userId }, headers),
    refetchOnWindowFocus: false,
    enabled: showModal && !!headers?.encryptedHeader && !!orgIdState,
  });

  const { mutate: mutatePermission, isLoading: mutatePoolLoading } = useMutation({
    mutationFn: variables => {
      return updateOrganizationPermission(
        {
          organizationId: variables.organizationId,
          selectedOrganizationId: variables.selectedOrganizationId, // This will take the ID of this organization row
          permissions: variables.permissions,
          userId: variables.userId,
        },
        headers,
      );
    },
    onError: (e, variables) => {
      notifyError(e?.message || e?.data);
      if (variables?.errorCb) variables?.errorCb();
    },
    onSuccess: (e, variables) => {
      queryClient.invalidateQueries({ queryKey: ["user", userId] });
      if (variables?.successCb) variables?.successCb();
    },
  });

  const statsMap = user?.stats?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.name]: curr.value,
    }),
    {},
  );

  const copyAddress = () => {
    if (user?.address) {
      copyTextToClipboard(user?.address, "Address copied successfully");
    }
  };
  const dataNotFound = !!headers && !!orgIdState && !isLoading && !user;

  return (
    <DetailsWrapperModal
      showModal={showModal}
      toggleModal={toggleModal}
      data={{
        avatarUrl: user?.thumbnail,
        bannerUrl: user?.bannerUrl,
        extProvider: user?.extProvider,
        lastLoginOn: user?.lastLoginOn,
        lastLoginCountry: user?.lastLoginGeo?.country,
      }}
      afterClose={() => {
        setUserId("");
        setActiveSection("accounts");
      }}
      isLoadingData={isLoading}
    >
      {dataNotFound ? (
        <Container height="300px" flex alignItems="center" justifyContent="center">
          Data Not Found
        </Container>
      ) : isLoading ? (
        <SpinnerLoaderWrapper style={{ height: "300px" }}>
          <SpinnerLoader />
        </SpinnerLoaderWrapper>
      ) : (
        <>
          <S.DetailsRow>
            <p>{user?.nick}</p>
            <S.AddressBlock>{user?.id}</S.AddressBlock>
          </S.DetailsRow>

          <S.InfoBlock>
            <Container flex columnGap="6px">
              {user?.lastLoginGeo?.country && <Globe />}
              {user?.lastLoginGeo?.country}
            </Container>
            <Container flex columnGap="6px">
              {/* Multiplying by 1000 to convert seconds timestamp to milliseconds timestamp */}
              {user?.lastLoginOn && <Clock />} {timeAgo(user?.lastLoginOn * 1000)}
            </Container>
          </S.InfoBlock>
          <Container marginTop="22px" flex columnGap="32px" rowGap="12px" className="flex-col-mobile">
            {user?.discord && (
              <Container flex alignItems="center" columnGap="9px">
                <DiscordViolet />
                <Text fontSize="13px">@{user.discord}</Text>
              </Container>
            )}
            {user?.email && (
              <Container flex alignItems="center" columnGap="9px">
                <EmailViolet />
                <Text fontSize="13px">{user.email}</Text>
              </Container>
            )}
            {user?.address && (
              <Container flex alignItems="center" columnGap="9px" style={{ cursor: "pointer" }} onClick={copyAddress}>
                <WalletViolet />
                <Text fontSize="13px">{truncateWithMiddleDots(user.address)}</Text>
              </Container>
            )}
          </Container>

          {!!statsMap && (
            <S.StatsContainer>
              <S.StatBlock>
                <Text fontSize="24px">
                  $
                  {statsMap["MONEY_EARNED"] && typeof statsMap["MONEY_EARNED"] === "number"
                    ? `${statsMap["MONEY_EARNED"].toFixed(2)}`
                    : `0`}
                </Text>
                <Text fontSize="12px" color="#A498ED">
                  Money earned
                </Text>
              </S.StatBlock>
              <S.StatBlock>
                <Text fontSize="24px">{statsMap["POOLS_PLAYED"] || 0}</Text>
                <Text fontSize="12px" color="#A498ED">
                  Tournaments Played
                </Text>
              </S.StatBlock>
              <S.StatBlock>
                <Text fontSize="24px">{statsMap["POOLS_WON"] || 0}</Text>
                <Text fontSize="12px" color="#A498ED">
                  Tournaments Won
                </Text>
              </S.StatBlock>
            </S.StatsContainer>
          )}

          <Container marginTop="28px" flex columnGap="14px">
            {options.map(option => (
              <S.SelectorButton
                borderRadius="50px"
                height="39px"
                active={activeSection === option.value}
                onClick={handleClick}
              >
                {option.name}
              </S.SelectorButton>
            ))}
          </Container>

          {/* Accounts Section */}
          {activeSection === "accounts" && (
            <Container marginTop="20px">
              <ScrollableSection minWidth="600px" scrollHeight="10px">
                <Container paddingLeft="28px" paddingRight="28px" marginBottom="10px">
                  <S.AccountRoWrapper>
                    <Text fontWeight={325} fontSize="13px" color="#A498ED">
                      Platform
                    </Text>
                    <Text fontWeight={325} fontSize="13px" color="#A498ED">
                      Game ID
                    </Text>
                  </S.AccountRoWrapper>
                </Container>
                <Container flex flexDirection="column" rowGap="12px">
                  {user?.accounts?.map(account => (
                    <AccountRow account={account} />
                  ))}
                </Container>
              </ScrollableSection>
            </Container>
          )}

          {/* Organizations Section */}
          {activeSection === "organizations" && (
            <Container marginTop="40px">
              <ScrollableSection scrollHeight="10px" customStyles={{ overflow: "visible", paddingBottom: "12px" }}>
                <Container flex paddingLeft="28px" paddingRight="28px">
                  <div style={{ width: "100%", display: "grid", gridTemplateColumns: "1fr 1fr 2fr" }}>
                    <Text fontWeight={325} fontSize="13px" color="#A498ED">
                      ID
                    </Text>
                    <Text fontWeight={325} fontSize="13px" color="#A498ED">
                      Name
                    </Text>
                    <Text fontWeight={325} fontSize="13px" color="#A498ED">
                      Permissions
                    </Text>
                  </div>
                  <Container width="25px" height="25px" />
                </Container>
                <Container flex flexDirection="column" rowGap="10px">
                  {user?.organizations?.map(organization => (
                    <OrganizationRow mutatePermission={mutatePermission} data={organization} userId={user.id} />
                  ))}
                </Container>
              </ScrollableSection>
            </Container>
          )}

          {activeSection === "activity" && (
            <Container marginTop="20px">
              {user?.activities?.length > 0 && Array.isArray(user?.activities) ? (
                <ScrollableSection minWidth="400px" scrollHeight="10px">
                  <Container flex paddingLeft="28px" paddingRight="28px">
                    <div style={{ width: "100%", display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr" }}>
                      <Text fontWeight={325} fontSize="13px" color="#A498ED">
                        ID
                      </Text>
                      <Text fontWeight={325} fontSize="13px" color="#A498ED">
                        Name
                      </Text>
                      <Text fontWeight={325} fontSize="13px" color="#A498ED">
                        Mode
                      </Text>
                      <Text fontWeight={325} fontSize="13px" color="#A498ED">
                        Position
                      </Text>
                      <Text fontWeight={325} fontSize="13px" color="#A498ED">
                        Prize Won
                      </Text>
                      <Text fontWeight={325} fontSize="13px" color="#A498ED">
                        Status
                      </Text>
                    </div>
                  </Container>
                  <ScrollableSection>
                    <Container flex flexDirection="column" rowGap="10px" marginTop="10px">
                      {user?.activities?.map((activity, index) => (
                        <UserActivityRow data={activity} index={index} />
                      ))}
                    </Container>
                  </ScrollableSection>
                </ScrollableSection>
              ) : (
                <Container flex justifyContent="center" alignItems="center">
                  <Text>Activities for this user are not available</Text>
                </Container>
              )}
            </Container>
          )}

          {/* Teams Section */}
          {activeSection === "teams" && (
            <S.TeamsWrapper>
              {user?.teams?.map((team, index) => (
                <S.TeamContainer>
                  <Flex alignItems="center" columnGap="11px">
                    <S.TeamCounter>{index + 1}</S.TeamCounter>
                    <Flex columnGap="10px" alignItems="center">
                      <Avatar src={team?.thumbnail} size="27px" />
                      <Text fontWeight={325} fontSize="13px">
                        {team?.name}
                      </Text>
                    </Flex>
                  </Flex>
                  <IconButton
                    onClick={() => copyTextToClipboard(team?.id, "Team ID has been copied successfully to clipboard")}
                  >
                    <CopyViolet />
                  </IconButton>
                </S.TeamContainer>
              ))}
            </S.TeamsWrapper>
          )}
        </>
      )}
    </DetailsWrapperModal>
  );
};

export default UserDetailModal;
