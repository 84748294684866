import * as S from "./styles";
import { useEffect, useState } from "react";
import Text from "../../components/common/Text/Text";
import { Add } from "../../assets/svg";
import { useHistory } from "react-router-dom";
import NewNotificationModal from "../../components/views/notifications/NewNotificationModal";
import NotificationsList from "../../components/views/notifications/NotificationsList";
import useDebounce from "../../hooks/useDebounce";
import { useSession } from "../../providers/SessionProvider";
import { notifyError } from "../../components/common/ToastComponent";

const Notifications = () => {
  const [openModal, setOpenModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [existingData, setExistingData] = useState(null);

  const { session } = useSession();
  const history = useHistory();

  useEffect(() => {
    if (session?.user?.userType !== "SUPERUSER") {
      notifyError("Unauthorized");
      history.push("/");
    }
  }, [session?.user]);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  function toggleModal() {
    setOpenModal(prev => !prev);
  }

  function handleSearch(e) {
    setSearchValue(e.target?.value);
  }

  function handleEdit(notification) {
    setExistingData(notification);
    setOpenModal(true);
  }

  if (!session?.user?.userType === "SUPERUSER") return null;
  return (
    <>
      <S.MainContainer>
        <S.HeaderContainer>
          <S.SearchContainer>
            <S.HeaderText>Notification Center</S.HeaderText>
            <S.SearchInput placeholder="Search" onChange={handleSearch} />
          </S.SearchContainer>

          <S.NewNotifBtn onClick={toggleModal}>
            <Add style={{ marginRight: "10px" }} /> New <span>Notification</span>
          </S.NewNotifBtn>
        </S.HeaderContainer>

        <S.SearchInput mobile placeholder="Search" onChange={handleSearch} />

        <NotificationsList searchValue={debouncedSearchValue} handleEdit={handleEdit} />
      </S.MainContainer>

      <NewNotificationModal
        open={openModal}
        toggleModal={toggleModal}
        existingData={existingData}
        setExistingData={setExistingData}
      />
    </>
  );
};

export default Notifications;
