import styled from "styled-components";
import { breakpoints } from "../../themes";

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  color: white;
`;

export const AdjustPadding = styled.div`
  height: 100%;
  width: 100%;
  padding: 0px 72px 10px 47px;
  ${breakpoints.down(breakpoints.laptopL)} {
    padding: 0px 38px 10px 47px;
  }
  ${breakpoints.down(breakpoints.laptop)} {
    padding: 0px 16px 10px 16px;
  }
`;

export const InnerContainer = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Title = styled.h2`
  font-style: normal;
  font-weight: 325;
  font-size: 38px;
  line-height: 46px;
  margin: 0;
  ${breakpoints.down(breakpoints.mobileL)} {
    font-size: 26px;
  }
  ${breakpoints.down(breakpoints.mobileM)} {
    font-size: 24px;
  }
  ${breakpoints.down(breakpoints.mobileS)} {
    font-size: 18px;
  }
  `;

export const InputContainer = styled.div`
  width: 374px;
  height: 38px;
  background: #3f2faa;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 11px;
  padding: 0 12px;
`;

export const Input = styled.input`
  width: 100%;
  height: 24px;
  background: transparent;
  border: none;
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;
  color: #a498ed;

  ::placeholder {
    color: #a498ed;
    opacity: 1;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
`;

export const FilterButton  = styled.button`
  width: 128px;
  height: 38px;
  padding: 0 13px;
  gap: 13px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  background: #4e3cca;
  outline: none;
  border: none;
  color: white;
  cursor: pointer;
`;

export const FilterItem = styled.span`
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  padding: 0;
`;

export const FilterCircle = styled.span`
  width: 21px;
  height: 21px;
  display: flex;
  color: #6C47FF;
  font-size: 12px;
  padding-top: 2px;
  border-radius: 30px;
  align-items: center;
  background-color: #fff;
  justify-content: center;
`

export const FilterChip = styled.span`
  color: #fff;
  height: 30px;
  font-size: 12px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  background-color: #6C47FF;
  padding: 8px 10px;
  svg {
    width: 6px;
    height: 6px;
  }

`

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const TableHeader = styled.div`
  width: 100%;
  background-color: #201366;
  height: 50px;
  color: #fff;
  align-items: center;
  padding: 0 22px;
  display: grid;
  grid-template-columns: 0.6fr 2fr repeat(3, 1.5fr) repeat(2, 1fr);

  @media (max-width: 768px) {
    div {
      display: none;
    }

   
    display: flex;
    justify-content: space-between;
  }
`;

export const TableHeaderText = styled.span`
  margin: 0;
  font-weight: 325;
  font-size: 13px;
  line-height: 16px;

  color: #a498ed;
`;

export const TableBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;


export  const PoolListLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`