import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PlayerRow } from "../../../../components/views/tournamentDetail/PlayerRow";
import { useLogin } from "../../../../providers/Loginprovider";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import { useQuery } from "@tanstack/react-query";
import { getPoolPlayerAndMatches } from "../../../../api/pool";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../../../../components/layout/Loader/Loader";
import StageMoveModal from "../../../../components/views/tournamentDetail/StageMoveModal/StageMoveModal";
import { Podium } from "../../../../assets/svg";
import PersonMoving from "../../../../assets/gif/person-moving.gif";
import Container from "../../../../components/common/Container/Container";
import Text from "../../../../components/common/Text/Text";
import Flex from "../../../../components/common/Flex";
import GroupMoveModal from "../../../../components/views/tournamentDetail/GroupMoveModal/GroupMoveModal";
import GroupSwapModal from "../../../../components/views/tournamentDetail/GroupSwapModal/GroupSwapModal";

const PlayerGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const PlayersSection = props => {
  const { isMultiStagePool, poolData, selectedStage } = props;
  const { orgIdState } = useLogin();
  const headers = useGetSecHeaders();
  const { stageId } = selectedStage;
  const { id: poolId, leaderboard: poolDataLeaderboard = [] } = poolData;

  const [dataToDisplay, setDataToDisplay] = useState([]);
  const [playersToMoveStage, setPlayersToMoveStage] = useState([]);
  const [playersToMoveGroup, setPlayersToMoveGroup] = useState([]);
  const [playerToSwapGroup, setPlayerToSwapGroup] = useState(null);

  const {
    data: stagePlayersData,
    isLoading: isLoadingStagePlayers,
    refetch,
  } = useQuery({
    queryKey: ["players", orgIdState, poolId, stageId],
    queryFn: () =>
      getPoolPlayerAndMatches(
        {
          poolId,
          organizationId: orgIdState,
          stageId: stageId && isMultiStagePool ? stageId : undefined,
          onlyAddStageId: true,
        },
        headers,
      ),
    enabled: !!headers?.encryptedHeader && !!orgIdState && !!isMultiStagePool,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!isMultiStagePool) {
      setDataToDisplay(poolData?.leaderboard);
    } else {
      if (
        stagePlayersData &&
        stagePlayersData.data?.leaders &&
        Array.isArray(stagePlayersData.data?.leaders) &&
        !stagePlayersData.error
      ) {
        setDataToDisplay(stagePlayersData.data?.leaders);
      }
    }
  }, [isMultiStagePool, stagePlayersData, poolDataLeaderboard]);

  if (isMultiStagePool && isLoadingStagePlayers)
    return (
      <SpinnerLoaderWrapper>
        <SpinnerLoader />
      </SpinnerLoaderWrapper>
    );
  return (
    <>
      {dataToDisplay?.length > 0 ? (
        <PlayerGrid>
          {dataToDisplay?.map(player => (
            <PlayerRow
              data={player}
              poolType={poolData?.poolType}
              poolMode={poolData?.poolMode}
              poolStatus={poolData?.poolStatus}
              isMultiStagePool={isMultiStagePool}
              stageId={stageId}
              refetchMultiStagePlayersQuery={refetch}
              setPlayersToMoveStage={setPlayersToMoveStage}
              setPlayersToMoveGroup={setPlayersToMoveGroup}
              setPlayerToSwapGroup={setPlayerToSwapGroup}
              joinMode={poolData?.joinMode}
            />
          ))}
        </PlayerGrid>
      ) : (
        <Container flex flexDirection="column" alignItems="center" justifyContent="center" rowGap="20px" height="100%">
          <Flex flexDirection="column" justifyContent="center" alignItems="center">
            <img src={PersonMoving} alt="person moving the head" width={50} height={50} style={{ marginLeft: "5px" }} />
            <Podium />
          </Flex>
          <Text fontSize="24px">No players found</Text>
        </Container>
      )}

      <StageMoveModal
        stageId={stageId}
        poolData={poolData}
        playersToMoveStage={playersToMoveStage}
        setPlayersToMoveStage={setPlayersToMoveStage}
      />
    </>
  );
};

export default PlayersSection;
