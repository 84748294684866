import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import * as S from "../../styles";
import Text from "../../../../common/Text/Text";
import Container from "../../../../common/Container/Container";
import { SpinnerLoader } from "../../../../common/SpinningLoader";
import { Dropdown } from "../../../../common/Dropdown";
import ErrorTooltip from "../../../../common/ErrorTooltip";
import Counter from "../../../../common/Counter";
import { InfoOutlinePurple, MaticWhite, ProfileIcon, TeamIcon, USDTWhite } from "../../../../../assets/svg";
import { getGamesList } from "../../../../../api/game";
import { useNetworkListener } from "../../../../../hooks/useNetworkListener";
import { getTokenList } from "../../../../../api/tokens";
import { useSession } from "../../../../../providers/SessionProvider";
import { Tooltip } from "antd";
import ToggleSwitch from "../../../../common/ToggleSwitch";
import PillDropdown from "../../regularPoolForm/PillDropdown";
import { ButtonUnstyled } from "../../../../common/Button";
import Flex from "../../../../common/Flex";
import { useLogin } from "../../../../../providers/Loginprovider";

const FirstStep = ({
  register,
  errors = {},
  setValue,
  currentInputValues = {},
  clearErrors,
  existingPoolData,
  watch,
}) => {
  const { chainId } = useNetworkListener();
  const { session } = useSession();
  const userOrganizations = session?.user?.organizations;
  const { orgIdState } = useLogin();
  const [addReqFormData, setAddReqFormData] = useState(() => currentInputValues?.reqFormData?.length);
  function toggleAddReqFormData() {
    setAddReqFormData(prev => !prev);
  }
  const {
    data: activeGames,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["games"],
    queryFn: getGamesList,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const {
    data: { data: tokenList, error: tokenListError } = {},
    isLoading: isLoadingChainList,
    isFetching: isFetchingChainList,
  } = useQuery({
    queryKey: ["tokenList", chainId],
    queryFn: () => getTokenList(chainId),
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    enabled: !!chainId,
  });

  useEffect(() => {
    if (
      tokenListError ||
      !tokenList ||
      !Array.isArray(tokenList) ||
      (tokenList && Array.isArray(tokenList) && tokenList?.findIndex(token => !!token.isChainNative) === -1)
    ) {
      return;
    }
    // Setting value here even when defaultValue is being set in the dropdown, because setting defaultValue doesn't set the value for Zod validation
    if (existingPoolData && existingPoolData.tokenSymbol) {
      // Checking if existing token is part of the token list. If it's not, setting it as an empty string.
      // Doing the same as above, in the token list dropdown's value section
      if (tokenList.map(token => token.symbol)?.includes(existingPoolData.tokenSymbol)) {
        setValue("prizeToken", existingPoolData.tokenSymbol);
      } else {
        setValue("prizeToken", "");
      }

      if (watch("copyContactInfo") === undefined) {
        setValue("copyContactInfo", true);
      }
    } else {
      setValue("prizeToken", tokenList.find(token => !!token.isChainNative)?.symbol);
    }
  }, [tokenList, existingPoolData]);

  useEffect(() => {
    if (userOrganizations && Array.isArray(userOrganizations)) {
      setValue("organizationId", orgIdState);
    }
  }, []);

  function handleChange(string) {
    const extraInfoArr = [...(currentInputValues.reqFormData || [])];
    if (extraInfoArr.includes(string)) {
      setValue(
        "reqFormData",
        extraInfoArr.filter(item => item != string),
      );
    } else {
      setValue("reqFormData", [...extraInfoArr, string]);
    }
  }

  const extraInfoNamesMap = {
    whatsapp: "WhatsApp",
    twitter: "Twitter",
    inGameName: "In game name",
  };

  if (!register || !setValue) return <div />;
  return (
    <Container flex flexDirection="column" rowGap="27px">
      <S.RowContainer>
        <label>Organizations</label>
        {isLoading || isFetching ? (
          <SpinnerLoader />
        ) : userOrganizations && Array.isArray(userOrganizations) ? (
          <Dropdown
            hideArrow
            selectionItemCss={{ marginTop: "15px" }}
            options={userOrganizations?.map(organization => ({
              value: organization.organizationId,
              name: organization.organizationName,
              element: (
                <Container flex columnGap="14px">
                  <Text>{organization.organizationName}</Text>
                </Container>
              ),
            }))}
            disabled={userOrganizations?.length <= 1}
            value={watch("organizationId")}
            hasError={!!errors.organizationId}
            onChange={e => {
              clearErrors("organizationId");
              setValue("organizationId", e);
            }}
          />
        ) : null}
        {errors.organizationId && <ErrorTooltip right="40px" message={errors.organizationId?.message} />}
      </S.RowContainer>
      <S.RowContainer>
        <label>Game</label>
        {isLoading || isFetching ? (
          <SpinnerLoader />
        ) : activeGames && Array.isArray(activeGames) ? (
          <Dropdown
            hideArrow
            selectionItemCss={{ marginTop: "15px" }}
            options={activeGames?.map(game => ({
              value: game.id,
              name: game.name,
              element: (
                <Container flex columnGap="14px">
                  <img src={game.assets?.gameThumbnail} style={{ height: "26px", width: "26px" }} />
                  <Text>{game.visibleName}</Text>
                </Container>
              ),
            }))}
            hasError={!!errors.gameId}
            defaultValue={currentInputValues?.gameId}
            setDefaultNull={!currentInputValues?.gameId}
            value={watch("gameId")}
            onChange={e => {
              clearErrors("gameId");
              setValue("gameId", e);
            }}
          />
        ) : null}
        {errors.gameId && <ErrorTooltip right="40px" message={errors.gameId?.message} />}
      </S.RowContainer>
      <S.RowContainer>
        <label>Title</label>
        <S.TextInput {...register("title")} hasError={!!errors.title} onInput={() => clearErrors("title")} />
        {errors.title && <ErrorTooltip message={errors.title?.message} />}
      </S.RowContainer>
      <S.RowContainer>
        <label>Subtitle</label>
        <S.TextInput {...register("subtitle")} hasError={!!errors.subtitle} onInput={() => clearErrors("subtitle")} />
        {errors.subtitle && <ErrorTooltip message={errors.subtitle?.message} />}
      </S.RowContainer>
      <S.RowContainer>
        <label>Prize pool</label>
        <S.PrizePoolRow>
          <S.PrizeInput>
            <S.TextInput
              type="number"
              {...register("prizePool")}
              placeholder={0}
              hasError={!!errors.prizePool}
              onInput={() => clearErrors("prizePool")}
            />
            {errors.prizePool && <ErrorTooltip top="13px" message={errors.prizePool?.message} />}
          </S.PrizeInput>
          {isFetchingChainList || isLoadingChainList ? (
            <SpinnerLoader />
          ) : tokenList && Array.isArray(tokenList) ? (
            <Dropdown
              options={tokenList?.map((token, index) => ({
                name: token.symbol,
                value: index,
                element: (
                  <>
                    {token.thumbnail ? (
                      <>
                        <img
                          src={token.thumbnail}
                          alt="thumbnail"
                          style={{ width: "25px", position: "absolute", bottom: "2px" }}
                        />
                        <span style={{ marginLeft: "35px" }}>{token.symbol}</span>
                      </>
                    ) : (
                      token.symbol
                    )}
                  </>
                ),
              }))}
              onChange={e => {
                clearErrors("prizeToken");
                setValue("prizeToken", tokenList?.[e]?.symbol);
              }}
              value={
                tokenList.findIndex(token => token.symbol === watch("prizeToken")) != -1
                  ? tokenList.findIndex(token => token.symbol === watch("prizeToken"))
                  : ""
              }
              hasError={!!errors.prizeToken}
              defaultValue={
                tokenList && Array.isArray(tokenList) && tokenList?.findIndex(token => !!token.isChainNative) !== -1
                  ? tokenList.findIndex(token => !!token.isChainNative)
                  : 0
              }
            />
          ) : null}
          {errors.prizeToken && <ErrorTooltip right="50px" top="12px" message={errors.prizeToken?.message} />}
        </S.PrizePoolRow>
      </S.RowContainer>
      <S.RowContainer>
        <label>Entry</label>
        <S.TextInput
          type="number"
          {...register("entry")}
          placeholder="Free"
          hasError={!!errors.entry}
          onInput={() => clearErrors("entry")}
        />
        {errors.entry && <ErrorTooltip top="13px" message={errors.entry?.message} />}
      </S.RowContainer>
      <S.RowContainer>
        <label>Join Mode</label>
        <Container
          flex
          columnGap="13px"
          style={!!errors.joinMode ? { border: "1px solid #ED6140", padding: "2px" } : {}}
        >
          <S.JoinModeBtn
            type="button"
            active={currentInputValues.joinMode === "solo"}
            onClick={() => {
              clearErrors("joinMode");
              setValue("joinMode", "solo");
            }}
          >
            <ProfileIcon /> Solo
            {errors.joinMode && <ErrorTooltip message={errors.joinMode?.message} />}
          </S.JoinModeBtn>
          <S.JoinModeBtn
            type="button"
            active={currentInputValues.joinMode === "team"}
            onClick={() => {
              clearErrors("joinMode");
              setValue("joinMode", "team");
              setValue("teamPlayers", 1);
            }}
          >
            <TeamIcon /> Team
            {errors.joinMode && <ErrorTooltip message={errors.joinMode?.message} />}
          </S.JoinModeBtn>
        </Container>
      </S.RowContainer>
      <S.RowContainer>
        <label>Team Players</label>
        <Counter
          hasError={!!errors.teamPlayers}
          disabled={currentInputValues.joinMode !== "team"}
          onChange={e => {
            clearErrors("teamPlayers");
            if (e.target?.value > 20) {
              setValue("teamPlayers", 20);
            } else {
              if (e.target?.value === "") {
                setValue("teamPlayers", 1);
              } else {
                setValue("teamPlayers", Number(e.target.value));
              }
            }
          }}
          onAdd={() => {
            clearErrors("teamPlayers");
            if (currentInputValues.teamPlayers === 20) return;
            setValue("teamPlayers", currentInputValues.teamPlayers ? currentInputValues.teamPlayers + 1 : 1);
          }}
          onSubtract={() => {
            clearErrors("teamPlayers");
            if (currentInputValues?.teamPlayers > 1) {
              setValue("teamPlayers", currentInputValues.teamPlayers - 1);
            }
          }}
          value={currentInputValues.teamPlayers}
        />
        {errors.teamPlayers && <ErrorTooltip right="50px" top="12px" message={errors.teamPlayers?.message} />}
      </S.RowContainer>
      <S.RowContainer>
        <label>{currentInputValues.joinMode === "team" ? "Max Teams" : "Max Players"} </label>
        <Counter
          onChange={e => {
            clearErrors("maxPlayers");
            if (e.target?.value > 999) {
              setValue("maxPlayers", 999);
            } else {
              if (e.target?.value === "") {
                setValue("maxPlayers", 1);
              } else {
                setValue("maxPlayers", Number(e.target.value));
              }
            }
          }}
          hasError={!!errors.maxPlayers}
          onAdd={() => {
            clearErrors("maxPlayers");
            setValue("maxPlayers", currentInputValues.maxPlayers ? currentInputValues.maxPlayers + 1 : 1);
          }} //Note (1) left at bottom of file for explanation
          onSubtract={() => {
            clearErrors("maxPlayers");
            if (currentInputValues?.maxPlayers > 1) {
              setValue("maxPlayers", currentInputValues.maxPlayers - 1);
            }
          }}
          value={currentInputValues.maxPlayers}
        />
        {errors.maxPlayers && <ErrorTooltip right="50px" top="12px" message={errors.maxPlayers?.message} />}
      </S.RowContainer>

      <S.RowContainer style={{ alignItems: "flex-start" }}>
        <label>
          <Container flex alignItems="center" columnGap="8px">
            Extra Info
            <Tooltip color="#7265C8" title="Require extra information when joining a tournament">
              <InfoOutlinePurple width="14px" />
            </Tooltip>
          </Container>
        </label>

        <Container flex flexDirection="column" rowGap="30px">
          <ToggleSwitch onChange={toggleAddReqFormData} checked={addReqFormData} />
          {addReqFormData && (
            <PillDropdown
              options={[
                { name: "WhatsApp", value: "whatsapp" },
                { name: "Twitter", value: "twitter" },
                { name: "In game name", value: "inGameName" },
              ]}
              currentSelected={currentInputValues?.reqFormData}
              onChange={handleChange}
            />
          )}
          {currentInputValues?.reqFormData && currentInputValues?.reqFormData?.length ? (
            <Container flex columnGap="8px" rowGap="8px" style={{ flexWrap: "wrap" }}>
              {currentInputValues.reqFormData.map(item => (
                <S.Pill>
                  <span>{extraInfoNamesMap[item]}</span>
                  <ButtonUnstyled type="button" onClick={() => handleChange(item)} style={{ padding: 0 }}>
                    x
                  </ButtonUnstyled>
                </S.Pill>
              ))}
            </Container>
          ) : null}
        </Container>

        {/* {nameError && <ErrorTooltip message={nameError} top="55%" />} */}
      </S.RowContainer>

      {/* If cloning, show the copy contact info UI */}
      {existingPoolData && (
        <S.RowContainer style={{ alignItems: "flex-start" }}>
          <label>Copy contact info</label>
          <Flex>
            <S.StyledCheckbox
              onChange={e => setValue("copyContactInfo", e.target.checked)}
              checked={watch("copyContactInfo")}
            />
          </Flex>
          {errors.copyContactInfo && <ErrorTooltip message={errors.copyContactInfo?.message} />}
        </S.RowContainer>
      )}
    </Container>
  );
};

export default FirstStep;

// Explanations:
// 1: maxPlayers in the schema has a default value 1, so if player enters no value, 1 will be submitted. The default value in the input box is also set to 1. The actual value for the input is being received from the currentInputValues object prop, which was created using getValues. getValues gets the current form values. The issue is that on clicking the first time, currentInputValues.maxPlayers goes to 1(because form state value of maxPlaeyrs. It does not hold the value set as default in the schema, but default). Because of this, the UI would not change when clicking the add icon the  first time, because on the first click, maxPlayers  goes to 1. Hence we are programatically moving to 2 the first. More clearly, if user does not  click on add, maxPlayers will have the value 1 on form submission. If they do click on add, maxPlayers form value will be set to 2. We do not need to do all this for the teamPlayers, because the form value of teamPlayers is set to 1 automatically when the user selects the team option in the joinMode
