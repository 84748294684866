import React from "react";
import * as S from "./styles";
import Container from "../../components/common/Container/Container";
import AnalyticsBarGraph from "../../components/views/analytics/AnalyticsBarGraph";
import Text from "../../components/common/Text/Text";
import AnalyticsTable from "../../components/views/analytics/AnalyticsTable";

const Analytics = () => {
  return (
    <S.MainContainer>
      <Text fontSize="38px">Analytics</Text>
      <Container marginTop="64px">
        <AnalyticsBarGraph />
      </Container>
      <Container marginTop="96px">
        <AnalyticsTable />
      </Container>
    </S.MainContainer>
  );
};

export default Analytics;
