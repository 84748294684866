import styled from "styled-components";
import Text from "../../../../common/Text/Text";

export const Container = styled.div`
  height: 50px;
  display: grid;
  padding-left: 21px;
  border-radius: 6px;
  align-items: center;
  background-color: #3f2faa;
  justify-items: flex-start;
  grid-template-columns: 2fr 2fr 1fr 1fr;
  min-width: 700px !important;
  &:hover {
    div:nth-child(1) {
      background-color: transparent;
    }
  }
`;
// export const Tabs = styled.div`
//   width: 302px;
//   height: 47px;
//   background: #201366;
//   border-radius: 13px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin-bottom: 43px;
//   margin-top: 56px;
//   @media (max-width: 375px) {
//     width: 100%;
//     padding-left: 2px;
//     padding-right: 2px;
//   }
// `;

export const StatusWrapper = styled.div`
  display: flex;
  height: 29px;
  padding: 7px 10px;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #4e3cca;
  position: relative;
`;

export const StatusDot = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  ${props => `
    display  : ${props.status === "active" ? `block` : "none"};    
  `}
  background: #b2fa4f;
`;

export const StatusText = styled(Text)`
  color: #fff;
  font-size: 11px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
  text-transform: capitalize;
`;
