import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { MultiStageFormDataSchema } from "./schema";
import FirstStep from "./Steps/FirstStep";
import SecondStep from "./Steps/SecondStep";
import ThirdStep from "./Steps/ThirdStep";
import { isNonEmptyObject } from "../../../../utilities";
import { notifyError, notifyWarning } from "../../../common/ToastComponent";
import FourthStep from "./Steps/FourthStep";
import Preview from "../preview";
import { POOL_MODES } from "../../../../constants";
import { useLocation } from "react-router-dom";
import FifthStep from "./Steps/FifthStep";

const Form = ({
  processSubmission,
  previewData,
  setPreviewData,
  currentStep,
  setCurrentStep,
  nextBtnRef,
  prevBtnRef,
  submitBtnRef,
  steps = [],
  existingPoolData, // Data of pool if editing pool data
  stageCount,
}) => {
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(MultiStageFormDataSchema),
  });

  watch(data => setPreviewData(data));
  const currentInputValues = getValues();
  function prev() {
    clearErrors();
    if (currentStep === 0) return;
    setCurrentStep(currentStep - 1);
  }
  async function next() {
    if (currentStep >= steps.length - 1) {
      return;
    }

    const fields = steps[currentStep].fields;
    const output = await trigger(fields, { shouldFocus: true });
    let customValidationErrors = false;
    if (currentStep === 0) {
      if (getValues("joinMode") === "team" && !getValues("teamPlayers")) {
        customValidationErrors = true;
        setError("teamPlayers", { type: "custom", message: "At least 1 team player is required" });
      }
    }
    if (currentStep === 1) {
      // if (currentInputValues.titleInputTemp || currentInputValues.descInputTemp) {
      //   setValue("rules", [
      //     ...(currentInputValues?.rules ? currentInputValues.rules : []),
      //     { title: currentInputValues.titleInputTemp || "", content: currentInputValues.descInputTemp || "" },
      //   ]);

      //   setValue("titleInputTemp", "");
      //   setValue("descInputTemp", "");
      // }
      currentInputValues?.stages?.forEach((stage, i) => {
        if (stage.titleInputTemp || stage.descInputTemp) {
          setValue(`stages.${i}.rules`, [
            ...(currentInputValues?.stages?.[i]?.rules ? currentInputValues?.stages?.[i]?.rules : []),
            { title: stage.titleInputTemp || "", content: stage.descInputTemp || "" },
          ]);
        }
        setValue(`stages.${i}.titleInputTemp`, "");
        setValue(`stages.${i}.descInputTemp`, "");

        // Set error if stageMode is Leaderboard or Round Robin and Score Mode is not selected. (Cannot add this requirement in schema as Score Mode should be disabled for other stageMode. So we cannot mark it as required in schema)

        let curretStageMode = getValues(`stages.${i}.stageMode`);
        if (curretStageMode === POOL_MODES.LEADERBOARD || curretStageMode === POOL_MODES.ROUND_ROBIN) {
          if (!getValues(`stages.${i}.bestMatchSelectMode`)) {
            customValidationErrors = true;
            setError(`stages.${i}.bestMatchSelectMode`, { type: "custom", message: "Score Mode is required" });
          }
        }
      });
    }
    if (currentStep === 2) {
      if (currentInputValues?.dates?.length < stageCount) {
        setError("dates", { type: "custom", message: "Please set the required dates for each stage" });
        notifyError("Please set the required dates for each stage");
      }
    }

    if (!output || customValidationErrors || isNonEmptyObject(errors)) return;
    setCurrentStep(currentStep + 1);
  }

  const stepProps = {
    register,
    errors,
    currentInputValues,
    previewData,
    setValue,
    setError,
    clearErrors,
    existingPoolData,
    watch,
    stageCount: stageCount,
  };

  // console.log("LOG [Form Data]: ", watch());
  // console.log("LOG [Form Errors]: ", errors);
  // console.log("LOG [Current Input Values]: ", currentInputValues);

  // This function is for cloning pools. Multistage pools do not support cloning right now
  // useEffect(() => {
  //   if (existingPoolData) {
  //     populateFieldsWithExistingData(existingPoolData);
  //   }
  // }, [existingPoolData]);

  function populateFieldsWithExistingData(data = {}) {
    // Setting fields with data
    // Object.entries(existingPoolData).forEach(([name, value]) => setValue(name, value));

    // Some fields in the form schema does not have the same preoperty name as the data received from the BE
    // So we're setting the data individually here
    setValue("organizationId", data.organizationId);
    setValue("subtitle", data.description);
    setValue("title", data.name);
    setValue("gameId", data.game);
    setValue("prizePool", data.poolPrize);
    setValue("entry", data.entrance);
    setValue("joinMode", data.joinMode?.toLowerCase());
    if (data.joinMode === "TEAM") {
      setValue("teamPlayers", data.gameDetails?.teamUsersQty);
    }
    if (data.poolMode === POOL_MODES.LEADERBOARD) {
      setValue("poolFormat", "LEADERBOARD");
    } else if (data.poolMode === POOL_MODES.ROUND_ROBIN) {
      setValue("poolFormat", "ROUND_ROBIN");
    } else {
      setValue("poolFormat", "SINGLE_ELIMINATION");
    }
    setValue("poolStageType", data.poolStageType);
    setValue("prizeToken", data.tokenSymbol);
    setValue("maxPlayers", data.maxPlayers);

    setValue("tournamentStartDate", data.startDate);
    setValue("tournamentEndDate", data.endDate);
    setValue("registrationStartDate", data.registrationStartDate);
    setValue("registrationEndDate", data.registrationEndDate);

    setValue("winnerCount", data.winnersFees?.length);
    setValue("percentageList", data.winnersFees);
    setValue("rules", data.rules);
    setValue("reqFormData", data.reqFormData);
    // Not populating image fields as suggested by Dani

    // setValue("thumbnail", data.thumbnail)
    // setValue("bannerBgBack", data.detailHeaderBackImage)
    // setValue("bannerBgFront", data.detailHeaderFrontImage)
  }

  return (
    <div>
      <form onSubmit={handleSubmit(processSubmission)}>
        {/* First Step */}
        {currentStep === 0 && <FirstStep {...stepProps} />}
        {currentStep === 1 && <SecondStep {...stepProps} />}
        {currentStep === 2 && <ThirdStep {...stepProps} />}
        {currentStep === 3 && <FourthStep {...stepProps} />}
        {currentStep === 4 && <FifthStep {...stepProps} />}

        {currentStep === 5 && <Preview previewData={previewData} />}

        {/* The following buttons are hidden in the UI. They will be clicked by buttons on the outside oif the form (in the NewTournament file) using refs */}
        <button className="hidden" type="button" onClick={prev} ref={prevBtnRef}>
          Back
        </button>
        {currentStep < steps.length - 1 && (
          <button className="hidden" type="button" onClick={next} ref={nextBtnRef}>
            Next
          </button>
        )}
        {currentStep === steps.length - 1 && (
          <button className="hidden" type="submit" ref={submitBtnRef}>
            Submit
          </button>
        )}
      </form>
    </div>
  );
};

export default Form;
