import React from "react";
import styled from "styled-components";
import { Select } from "antd";
import { useState } from "react";

const { Option } = Select;

const DropdownContainer = styled.div`
  height: 37px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #3f2faa;
  gap: 10px;
  border: ${props => (props.hasError ? "1px solid #ed6140" : "unset")};
  ${props => ({ ...props.styles })};
`;

const DropdownSelectContainer = styled.div`
  position: relative;
  display: block;
  border: none;
  width: 100%;
  line-height: 3;
  overflow: hidden;
  border-radius: 0.25em;
`;

const DropdownSelect = styled(Select)`
  appearance: none;
  background: transparent;
  border: none;
  height: 100%;
  width: 100%;
  padding-left: 0px;
  display: flex;
  .ant-select-selector {
    ${props => ({ ...props.selectStyles })}
  }

  .ant-select-selection-item {
    ${props => ({ ...props.selectionItemCss })}
  }

  .ant-select-arrow {
    color: #a498ed;
  }

  .ant-select-dropdown {
    display: none !important;
  }
`;

const PillOption = styled.div`
  height: 30px;
  min-width: 77px;
  width: fit-content;
  display: flex;
  padding: 5px 9px;
  font-size: 12px;
  cursor: pointer;
  color: #fff;
  border-radius: 50px;
  justify-content: center;
  border: 1px solid  ${props => (props.selected ? `#6c47ff` : `#ffffff`)};
  background-color:  ${props => (props.selected ? `#6c47ff` : `transparent`)};
  align-items: center;
  /* &:hover {
    background-color: #e0e0e0;
  } */
`;

const DropdownOption = styled(Option)``;

const SelectDropdownStyle = styled.div`
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  background: #3f2faa;
  column-gap: 8px;
  row-gap: 10px;
  border: none;
`;

const PillDropdown = ({
  options = [],
  onChange,
  value,
  placeholder,
  id = "",
  setDefaultNull = false,
  containerStyles = {},
  selectStyles = {},
  disabled = false,
  className = "",
  hasError = false,
  defaultValue,
  dropdownStyles = {},
  dropdownOptionStyles = {},
  selectionItemCss = {},
  hideArrow = false,
  currentSelected = [],
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <DropdownContainer styles={containerStyles} hasError={hasError}>
      <DropdownSelectContainer onClick={toggleOpen} isOpen={isDropdownOpen} hideArrow={hideArrow}>
        <DropdownSelect
          id={id}
          defaultValue={null}
          placeholder={placeholder}
          bordered={false}
          dropdownStyle={{
            zIndex: 9999,
            ...dropdownStyles,
          }}
          dropdownRender={menu => (
            <SelectDropdownStyle>
              {options.map(({ name, value }, index) => (
                <PillOption selected={currentSelected.includes(value)} key={index} onClick={() => onChange(value)}>
                  {name}
                </PillOption>
              ))}
            </SelectDropdownStyle>
          )}
          selectionItemCss={{ ...selectionItemCss }}
          onChange={onChange}
          value={value}
          selectStyles={{ ...selectStyles, color: "#fff !important" }}
          disabled={disabled}
          className={className}
          onDropdownVisibleChange={e => setIsDropdownOpen(e)}
          // dropdownAlign={{
          //   points: ["bl", "tl"], // Align the bottom-left of the dropdown to the top-left of the Select
          //   offset: [0, -4],
          // }}
        >
          {options.map(({ name, value }, index) => (
            <DropdownOption
              key={index}
              value={value}
              style={{ color: "#fff", backgroundColor: "#3f2faa", ...dropdownOptionStyles }}
            >
              {name}
            </DropdownOption>
          ))}
        </DropdownSelect>
      </DropdownSelectContainer>
    </DropdownContainer>
  );
};

export default PillDropdown;
