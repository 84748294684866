import { Checkbox } from "antd";
import styled from "styled-components";

// export const CreateBracketContainer = styled.div`
//   height: 364px;
//   border-radius: 6px;
//   border: 1px solid rgba(114, 101, 200, 0.3);
//   background: #3f2faa;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

export const RowContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: ${props => (props.alignItems ? props.alignItems : "center")};
  column-gap: 84px;
  position: relative;

  label {
    width: 88px;
    font-size: 13px;
  }
`;

export const WinnersModeContainer = styled.div`
  padding: 14px;
  background: #3f2faa;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  row-gap: 7px;
`;
export const WinnersModeRow = styled.div`
  height: 34px;
  border-radius: 4px;
  background-color: #4e3cca;
  display: flex;
  align-items: center;
  padding-left: 13px;
  font-size: 11px;
  opacity: ${props => (props.isSelected ? 1 : 0.4)};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  input {
    width: 42px;
    height: 24px;
    background-color: #6250df;
    text-align: center;
    border-radius: 4px;
    outline: none;
    column-gap: 22px;
    border: none;
    margin: 0 22px;
    color: #fff;
    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export const TextInput = styled.input`
  height: 40px;
  border-radius: 4px;
  padding: 12px 15px;
  border: none;
  color: #fff;
  /* border: 1px solid #7a6ddb; */
  border: ${props => (props.hasError ? "1px solid #ED6140" : "unset")};
  outline: none;
  background-color: #3f2faa;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  /* input[type="number"] {
    -moz-appearance: textfield;
  } */

  width: ${props => (props.width ? "100%" : "")};
`;

export const JoinModeBtn = styled.button`
  height: 40px;
  border-radius: 4px;
  background-color: #3f2faa;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${props => (props.active ? "2px solid #7A6DDB" : "unset")};
  flex: 1;
  font-size: 14px;
  column-gap: 8px;
  cursor: pointer;
  position: relative;
  color: #fff;
  svg {
    width: 14px;
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export const PrizePoolRow = styled.div`
  display: grid;
  column-gap: 16px;
  grid-template-columns: 2fr 1fr;
`;

export const PrizeInput = styled.div`
  position: relative;
  input {
    width: 100%;
  }
  svg {
    top: 13px;
  }
`;

export const UnderlineContainer = styled.div`
  border-bottom: 1px solid #4e3cca;
  padding-bottom: 12px;
`;

export const CalendarPickerContainer = styled.div`
  padding: 12px 15px;
  border-radius: 4px;
  background-color: #4e3cca;
  border: ${props => (props.hasError ? "1px solid #ED6140" : "unset")};
  width: 300px;
`;

export const MultistageCalendarPickerContainer = styled.div`
  padding: 12px 15px;
  border-radius: 4px;
  background-color: #4e3cca;
  border: ${props => (props.hasError ? "1px solid #ED6140" : "unset")};
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 12px;
  width: 365px;
`;

export const FieldsContainer = styled.div`
  background: #3f2faa;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 12px;
  padding-bottom: 12px;
  border: ${props => (props.hasError ? "1px solid #ED6140" : "unset")};
  display: ${props => (props.hidden ? "hidden" : "nonde")};
`;
export const PercentageInputRow = styled.div`
  border-radius: 4px;
  background-color: #4e3cca;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
`;

export const PercentageInput = styled.input`
  width: 42px;
  border-radius: 4px;
  border: none;
  background-color: #6250df;
  height: 24px;
  outline: none;
  color: #fff;
  font-size: 11px;
  text-align: center;
  border: ${props => (props.hasError ? `1px solid  #ED6140` : "unset")};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  margin-top: -10px;
  div {
    background-color: #3f2faa;
    padding: 7px;
    width: fit-content;
    border-radius: 6px;
    display: flex;
    column-gap: 7px;
    button {
      border: none;
      color: #fff;
      height: 26px;
      width: 26px;
      border-radius: 4px;
      cursor: pointer;
    }
    button:nth-child(1) {
      border: 2px solid #4e3cca;
      background-color: transparent;
    }
    button:nth-child(2) {
      background-color: #6c47ff;
    }
  }
`;

export const Thumbnail = styled.div`
  /* width: 180.65px; */
  height: 110.66px;
  border-radius: 6px;
  background: ${props => `url(${props.background})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: ${props => (props.active ? "4px solid #6C47FF" : "unset")};
  cursor: pointer;
`;

export const PoolBannerFront = styled.div`
  width: 119px;
  height: 62.21px;
  border-radius: 6px;
  background: ${props => `url(${props.background})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: ${props => (props.active ? "4px solid #6C47FF" : "unset")};
  cursor: pointer;
`;

export const ThumbnailsContainer = styled.div`
  display: grid;
  row-gap: 14px;
  column-gap: 14px;
  grid-template-columns: repeat(3, 1fr);
`;

export const BannerFrContainer = styled.div`
  display: grid;
  row-gap: 15px;
  column-gap: 14px;
  grid-template-columns: repeat(4, 1fr);
`;

export const ImagePicker = styled.div`
  height: 110.66px;
  border-radius: 6px;
  background-color: #201366;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => (props.loading ? "wait" : "pointer")};
`;

export const BannerContainer = styled.div`
  display: grid;
  row-gap: 15px;
  column-gap: 18px;
  grid-template-columns: repeat(2, 1fr);
`;

export const Banner = styled.div`
  height: 65.02px;
  border-radius: 6px;
  background: ${props => (props.background ? `url(${props.background})` : "#201366")};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: ${props => (props.active ? "3px solid #6C47FF" : "unset")};
  cursor: pointer;
  position: relative;
  cursor: pointer;
`;

export const Image = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 140px;
  height: 70px;
`;

export const Pill = styled.div`
  height: 21px;
  background-color: #fff;
  border-radius: 30px;
  color: #342788;
  font-size: 10px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
  padding: 0 9px;
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-inner {
    /* border-color: #1890ff; */
    border-radius: 4px;
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #6c47ff;
    border-color: #1890ff;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: white;
  }

  &:hover .ant-checkbox-inner {
    border-color: #40a9ff;
  }

  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #40a9ff;
  }
`;

