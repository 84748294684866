import styled from "styled-components";
import { Checkbox } from "antd";

import { ButtonRound } from "../../../../../components/common/Button";

export const RowSpacing = styled.div`
  display: grid;
  grid-template-columns: 1.25fr repeat(5, 1fr) 0.45fr;
  padding: 10px 21px;
`;

export const RowHeadersContainer = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.isMainOrgContactInfo ? "1.75fr 1fr 1.5fr 0.75fr 0.75fr" : " 1.25fr repeat(5, 1fr) 0.45fr"};

  padding: 10px 21px;
  min-width: ${props => (props.isMainOrgContactInfo ? "unset" : "1000px")};
`;

export const ContactRow = styled.div`
  column-gap: 14px;
  width: 100%;
  height: 50px;
  background-color: #3f2faa;
  border-radius: 6px;
  padding: 10px 21px;
  color: #fff;
  min-width: ${props => (props.isMainOrgContactInfo ? "unset" : "1000px")};
  align-items: center;
  display: grid;
  grid-template-columns: ${props =>
    props.isMainOrgContactInfo ? "1.75fr 1fr 1.5fr 0.75fr 0.75fr" : " 1.25fr repeat(5, 1fr) 0.45fr"};
  padding: 10px 21px;
  a {
    text-decoration: underline;
  }
`;

export const ContactRowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  overflow-x: auto;
`;

export const NameContainer = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Input = styled.input`
  height: 41px;
  border-radius: 6px;
  background-color: #4e3cca;
  border: none;
  width: 100%;
  /* color: #a498ed; */
  color: #fff;
  margin-top: 8px;
  font-size: 14px;
  padding-left: 8px;
  border: ${props => (props.hasError ? "2px solid #ED6140" : "unset")};
  &::placeholder {
    color: #a498ed;
  }
  &:focus {
    outline: 2px solid #6c47ff;
  }
`;

export const TextArea = styled.textarea`
  height: auto;
  border-radius: 6px;
  background-color: #4e3cca;
  border: none;
  width: 100%;
  /* color: #a498ed; */
  color: #fff;
  margin-top: 8px;
  font-size: 14px;
  padding-left: 8px;
  padding-top: 8px;
  font-family: "Gotham";
  border: ${props => (props.hasError ? "2px solid #ED6140" : "unset")};
  &::placeholder {
    color: #a498ed;
  }
  &:focus {
    outline: 2px solid #6c47ff;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 325;
  margin-bottom: 50px;
`;

export const Form = styled.form`
  display: flex;
  row-gap: 17px;
  flex-direction: column;
`;

export const RowContainer = styled.div`
  position: relative;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  column-gap: 6px;
  margin-top: 24px;
`;

export const ConfirmButton = styled(ButtonRound)`
  background: #ed6140;
`;

export const ModalMessageText = styled.p`
  color: #a498ed;
  font-size: 16px;
`;

export const CustomCheckbox = styled(Checkbox)`
margin-left: 2px;
.ant-checkbox-inner {
  height: 18px;
  width: 18px;
  background-color: transparent;
  border-color: #6c47ff;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #6c47ff;
  border-color: transparent;
}

& > label {
  color: #a498ed;
  text-transform: capitalize;
}
`;
