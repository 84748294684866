import React, { useState } from "react";
import * as S from "./styles";
import Text from "../../../../common/Text/Text";
import Container from "../../../../common/Container/Container";
import Modal from "../../../../common/Modal/Modal";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../../../../common/SpinningLoader";
import { Clock, DiscordNew, FacebookNew, Globe, GoogleWhiteBg, TwitterNewLogo } from "../../../../../assets/svg";
import LoadingSkeleton from "../../../../common/LoadingSkeleton";
import { timeAgo } from "../../../../../utilities";

const ProviderImage = ({ extProvider }) => {
  if (extProvider === "twitter.com") return <TwitterNewLogo />;
  if (extProvider === "google.com") return <GoogleWhiteBg />;
  else if (extProvider === "facebook.com") return <FacebookNew />;
  else if (extProvider === "discord.com") return <DiscordNew />;
  else return <div />;
};
const DetailsWrapperModal = ({
  children,
  showModal,
  toggleModal,
  customStyles,
  data = {},
  afterClose = () => {},
  isLoadingData = false,
}) => {
  return (
    <Modal
      showModal={showModal}
      toggleModal={toggleModal}
      afterClose={afterClose}
      customStyles={
        customStyles || {
          height: "632px",
          width: "793px",
          padding: "0",
        }
      }
      tabletStyles={{
        width: "90%",
        height: "600px",
      }}
      mobileStyles={{
        width: "90%",
        height: "550px",
      }}
      hideCloseBtn
    >
      {isLoadingData ? (
        <>
          <LoadingSkeleton height="119px" baseColor="#2b207a" />
          <S.AvatarContainer hideBorder>
            <LoadingSkeleton width="100.8px" height="100.8px" circle />
          </S.AvatarContainer>
        </>
      ) : (
        <div>
          <S.ImagesContainer src={data.bannerUrl}>
            <S.AvatarContainer>
              <S.Avatar src={data.avatarUrl} />
            </S.AvatarContainer>
            <S.InfoContainer>
              <S.InfoBlock>
                {data?.lastLoginCountry && <Globe />}
                {data?.lastLoginCountry}
              </S.InfoBlock>
              <S.InfoBlock>
                {/* Multiplying by 1000 to convert seconds timestamp to milliseconds timestamp */}
                {data?.lastLoginOn && <Clock />} {timeAgo(data?.lastLoginOn * 1000)}
              </S.InfoBlock>
            </S.InfoContainer>
            {data.extProvider && (
              <S.ExternalProviderBlock>
                <Container flex alignItems="center" columnGap="12px">
                  <Text fontSize="13px" color="#A498ED">
                    External Provider
                  </Text>
                  <ProviderImage extProvider={data.extProvider} />
                </Container>
              </S.ExternalProviderBlock>
            )}
          </S.ImagesContainer>

          <Container paddingLeft="40px" paddingRight="40px">
            {children}
          </Container>
        </div>
      )}
    </Modal>
  );
};

export default DetailsWrapperModal;
