import React, { useEffect, useState } from "react";
import Modal from "../../../common/Modal/Modal";
import * as S from "./styles";
import Flex from "../../../common/Flex";
import Text from "../../../common/Text/Text";
import { RightArrow } from "../../../../assets/svg";
import { ButtonOutlineRound, ButtonRound } from "../../../common/Button";
import { useMutation } from "@tanstack/react-query";
import { getPlayersFromPoolById, transferPlayers } from "../../../../api/pool";
import { useGetSecHeaders } from "../../../../hooks/useHeaders";
import { useLogin } from "../../../../providers/Loginprovider";
import { notifyError, notifySuccess } from "../../../common/ToastComponent";
import { SpinnerLoader, SpinnerLoaderWrapper } from "../../../common/SpinningLoader";
import { Divider } from "antd";

export const MovePlayerModal = ({ showModal, toggleModal, poolList = [] }) => {
  //! Required variables for service calls
  const headers = useGetSecHeaders();
  const { orgIdState } = useLogin();

  const [queries, setQueries] = useState({ input1: "", input2: "" });
  const [filteredSuggestions, setFilteredSuggestions] = useState({
    input1: [],
    input2: [],
  });
  const [selectedTournaments, setSelectedTournaments] = useState({
    input1: null,
    input2: null,
  });

  const [input1PlayerList, setInput1PlayerList] = useState([]);
  const [input2PlayerList, setInput2PlayerList] = useState([]);
  const [selectPlayersToMove, setSelectPlayersToMove] = useState([]);
  const [removeOriginData, setRemoveOriginData] = useState(false);

  /* The `useEffect` hook in the code snippet is responsible for updating the filtered suggestions based
on the user input in the input fields. */
  useEffect(() => {
    /**
     * The function `updateFilteredSuggestions` filters a list of pools based on a query and updates the
     * filtered suggestions state based on the input name.
     */
    const updateFilteredSuggestions = (inputName, query) => {
      /* The condition `if (!selectedTournaments[inputName] && query.trim())` is checking two things:
      1. `!selectedTournaments[inputName]`: This part checks if there is no selected tournament for
      the input field specified by `inputName` prop.
      2. `query.trim()`: This part checks if the user input query for the input field specified by
      `inputName` is not empty after removing any leading or trailing white spaces */
      if (!selectedTournaments[inputName] && query.trim()) {
        /* The `setFilteredSuggestions` function is updating the state of filtered suggestions based on
        the user input in the input fields. Here it starts showing the UI */
        setFilteredSuggestions(prev => ({
          ...prev,
          [inputName]: poolList.filter(pool => pool.name.toLowerCase().includes(query.toLowerCase())),
        }));
      } else {
        /* If the conditions are not met the suggestion list is set as empty so the UI is not being shown. */
        setFilteredSuggestions(prev => ({ ...prev, [inputName]: [] }));
      }
    };

    updateFilteredSuggestions("input1", queries.input1);
    updateFilteredSuggestions("input2", queries.input2);
  }, [queries, poolList, selectedTournaments]);

  // mutation in charge of get the list of players from an specific tournament
  const [input1Loading, setInput1Loading] = useState(false);
  const [input2Loading, setInput2Loading] = useState(false);
  const { mutate: mutateGetPlayerList } = useMutation({
    mutationFn: variables => {
      // Set loading state for the specific input before mutation starts
      if (variables.inputName === "input1") {
        setInput1Loading(true);
      } else if (variables.inputName === "input2") {
        setInput2Loading(true);
      }

      return getPlayersFromPoolById(
        {
          poolId: variables.poolId,
          organizationId: variables.organizationId,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: (e, variables) => {
      const players = e?.data?.players;

      // Validation for when tournaments doesn't have players
      if (players?.length === 0) {
        notifyError("No players found in this pool.");
      }

      // Update the corresponding player list based on the input name
      if (variables.inputName === "input1") {
        setInput1PlayerList(players);
      } else if (variables.inputName === "input2") {
        setInput2PlayerList(players);
      }
    },
    onSettled: (data, error, variables) => {
      // Reset loading state after mutation settles (whether success or error)
      if (variables.inputName === "input1") {
        setInput1Loading(false);
      } else if (variables.inputName === "input2") {
        setInput2Loading(false);
      }
    },
  });

  const handleInputChange = (e, inputName) => {
    const { value } = e.target;
    setSelectedTournaments(prev => ({ ...prev, [inputName]: null })); // Reset the selection of tournaments if input changes
    setQueries(prev => ({ ...prev, [inputName]: value }));
  };

  const handleSuggestionClick = (suggestion, inputName) => {
    setSelectedTournaments(prev => ({
      ...prev,
      [inputName]: suggestion,
    }));
    setQueries(prev => ({ ...prev, [inputName]: suggestion.name }));
    setFilteredSuggestions(prev => ({ ...prev, [inputName]: [] }));

    mutateGetPlayerList({ organizationId: orgIdState, poolId: suggestion.id, inputName });
  };

  const handleCheckboxChange = player => {
    setSelectPlayersToMove(prev => {
      const isAlreadySelected = prev.some(p => p.nick === player.nick);
      if (isAlreadySelected) {
        // If the player is already selected, remove them from selectPlayersToMove
        setInput2PlayerList(prevList => prevList.filter(p => p.nick !== player.nick));
        return prev.filter(p => p.nick !== player.nick);
      } else {
        // If the player is not selected, add them to selectPlayersToMove
        return [...prev, player];
      }
    });
  };

  /* The above code combines the states `input2PlayerList` and `selectPlayersToMove` by filtering out
any players from `selectPlayersToMove` that already exist in `input2PlayerList` based on the `nick`
property. The resulting array `combinedPlayers` will contain all players from `input2PlayerList` and
any players from `selectPlayersToMove` that are not already in `input2PlayerList`. */
  const combinedPlayers = [
    ...input2PlayerList,
    ...selectPlayersToMove.filter(
      selectedPlayer => !input2PlayerList.some(poolPlayer => poolPlayer.nick === selectedPlayer.nick),
    ),
  ];

  const renderInputWithSuggestions = (inputName, query, suggestions) => (
    <Flex flexDirection="column" rowGap="7px">
      <Text fontSize="14px">{inputName === "input1" ? "Search a tournament name" : "To"}</Text>
      <S.Wrapper>
        <S.Input
          type="text"
          value={query}
          onChange={e => handleInputChange(e, inputName)}
          placeholder="Type the name of the tournament..."
        />
        {suggestions.length > 0 && query && (
          <S.SuggestionsList>
            {suggestions.map((suggestion, index) => (
              <S.SuggestionItem
                key={index}
                onClick={() => {
                  handleSuggestionClick(suggestion, inputName);
                }}
              >
                <span>
                  {suggestion.id} | {suggestion.name}
                </span>
              </S.SuggestionItem>
            ))}
          </S.SuggestionsList>
        )}
      </S.Wrapper>
    </Flex>
  );

  // Confirm player movement modal
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const toggleConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const { mutate: mutateTransferPlayer, isLoading: mutateTransferPlayerIsLoading } = useMutation({
    mutationFn: variables => {
      return transferPlayers(
        {
          organizationId: variables.organizationId,
          poolId: variables.poolId,
          poolIdToMove: variables.poolIdToMove,
          playerList: variables.playerList,
          removeOriginData: variables.removeOriginData,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: e => {
      notifySuccess(e?.message || e?.data);
      setFilteredSuggestions({ input1: [], input2: [] });
      setInput1PlayerList([]);
      setInput2PlayerList([]);
      setQueries({ input1: "", input2: "" });
      setSelectedTournaments({ input1: null, input2: null });
      setSelectPlayersToMove([]);
      setRemoveOriginData(false);
      toggleConfirmationModal();
      toggleModal();
    },
  });

  /* The `MovePlayerConfirmationModal` component is a functional component that renders a modal dialog
box to confirm the move of players from one tournament to another. It displays a title confirming
the move of players to the selected tournament, a text asking if the user wants to delete the user
activity and statistics from the origin tournament, a checkbox to allow the user to choose whether
to remove origin data, and a button to trigger the actual player transfer action. */
  const MovePlayerConfirmationModal = ({ showModal, toggleModal }) => {
    return (
      <Modal
        showModal={showModal}
        toggleModal={toggleModal}
        header={<S.ModalTitle>Confirm move players to {selectedTournaments.input2.name}?</S.ModalTitle>}
      >
        <S.ModalWrapper>
          <S.ConfirmationText>
            Do you want to delete the user activity and statistics from the origin tournament?
          </S.ConfirmationText>
          <Flex flexDirection="column">
            <Flex alignItems="center" columnGap="20px">
              <S.CustomCheckbox
                type="checkbox"
                onChange={e => setRemoveOriginData(e.target.checked)}
                checked={removeOriginData}
              />
              <S.ConfirmationText>Remove origin data</S.ConfirmationText>
            </Flex>
            <Flex justifyContent="flex-end">
              <ButtonRound
                width="80px"
                onClick={() => {
                  mutateTransferPlayer({
                    organizationId: orgIdState,
                    poolId: selectedTournaments.input1.id,
                    poolIdToMove: selectedTournaments.input2.id,
                    playerList: selectPlayersToMove,
                    removeOriginData: removeOriginData,
                  });
                }}
                disabled={mutateTransferPlayerIsLoading}
              >
                {mutateTransferPlayerIsLoading ? <SpinnerLoader /> : "Confirm"}
              </ButtonRound>
            </Flex>
          </Flex>
        </S.ModalWrapper>
      </Modal>
    );
  };

  return (
    <Modal
      showModal={showModal}
      toggleModal={toggleModal}
      beforeClose={() => {
        setQueries({ input1: "", input2: "" });
        setInput1PlayerList([]);
        setInput2PlayerList([]);
        setSelectPlayersToMove([]); // Reset the selected players to move
      }}
      header={<S.ModalTitle>Move Players</S.ModalTitle>}
    >
      <S.ModalWrapper>
        <Flex flexDirection="column" rowGap="20px">
          <Flex alignItems="flex-end" columnGap="29px">
            <Flex flexDirection="column">
              {renderInputWithSuggestions("input1", queries.input1, filteredSuggestions.input1)}
            </Flex>

            <S.ArrowButton>
              <RightArrow />
            </S.ArrowButton>

            <Flex>{renderInputWithSuggestions("input2", queries.input2, filteredSuggestions.input2)}</Flex>
          </Flex>
          <Flex justifyContent="space-between">
            {queries.input1 && filteredSuggestions.input1 && (
              <>
                <S.PlayerList>
                  {input1Loading ? (
                    <SpinnerLoaderWrapper style={{ height: "100%" }}>
                      <SpinnerLoader />
                    </SpinnerLoaderWrapper>
                  ) : (
                    <>
                      {input1PlayerList?.map((player, index) => (
                        <Flex alignItems="center" columnGap="13px" key={index}>
                          <S.CustomCheckbox
                            type="checkbox"
                            onChange={() => handleCheckboxChange(player)}
                            checked={selectPlayersToMove.some(p => p.nick === player.nick)}
                          />
                          <S.PlayerListText fontSize="14px">{player.nick}</S.PlayerListText>
                        </Flex>
                      ))}
                    </>
                  )}
                </S.PlayerList>
              </>
            )}
            {combinedPlayers.length >= 1 && (
              <>
                <S.PlayerList>
                  {input2Loading ? (
                    <SpinnerLoaderWrapper style={{ height: "100%" }}>
                      <SpinnerLoader />
                    </SpinnerLoaderWrapper>
                  ) : (
                    <>
                      {combinedPlayers?.map((player, index) => (
                        <Flex alignItems="center" key={index}>
                          <S.PlayerListText fontSize="14px">{player.nick}</S.PlayerListText>
                        </Flex>
                      ))}
                    </>
                  )}
                </S.PlayerList>
              </>
            )}
          </Flex>
          {selectPlayersToMove.length >= 1 && selectedTournaments.input2 && (
            <Flex justifyContent="flex-end" columnGap="10px">
              {/* <ButtonOutlineRound>Go back</ButtonOutlineRound> */}
              <ButtonRound onClick={toggleConfirmationModal}>Move</ButtonRound>
              <MovePlayerConfirmationModal showModal={showConfirmationModal} toggleModal={toggleConfirmationModal} />
            </Flex>
          )}
        </Flex>
      </S.ModalWrapper>
    </Modal>
  );
};
