import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import * as S from "../../../views/Notifications/styles";
import { deleteNotification, getNotifications, updateNotification } from "../../../api/notifications";
import { useGetSecHeaders } from "../../../hooks/useHeaders";
import NotificationRow from "./NotificationRow";
import Container from "../../common/Container/Container";
import { notifyError } from "../../common/ToastComponent";
import LoadingSkeleton from "../../common/LoadingSkeleton";
import useConfirm from "../../../hooks/useConfirm";
import Flex from "../../common/Flex";

const NotificationsList = ({ searchValue = "", handleEdit }) => {
  const headers = useGetSecHeaders();

  const [notifToDel, setNotifToDel] = useState();
  const queryClient = useQueryClient();

  const {
    data: notifData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["notifications", searchValue],
    queryFn: () => getNotifications({ searchValue }, headers),
    refetchOnWindowFocus: false,
    enabled: !!headers?.encryptedHeader,
  });

  const { mutateAsync: deleteNotificationMutation, isLoading: loadingUpdateNotification } = useMutation({
    mutationFn: async variables => {
      const res = await deleteNotification(
        {
          notificationId: variables.id,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.data?.message || e?.message || e?.data);
    },
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
      });
    },
  });

  const { triggerModal, ConfirmDialogue } = useConfirm({
    callback: async () => {
      if (!notifToDel) {
        return;
      }
      await deleteNotificationMutation({ id: notifToDel });
    },
    message: "You are about to delete this notification. There is no way to recuperate once it is deleted.",
    title: "Delete notification?",
    btnVariant: "delete",
  });

  function handleDel(id) {
    setNotifToDel(id);
    triggerModal();
  }

  const { error, data } = notifData || {};

  if (isLoading) {
    return (
      <Flex flexDirection="column" rowGap="12px">
        <LoadingSkeleton height="50px" width="100%" />
        <LoadingSkeleton height="50px" width="100%" />
        <LoadingSkeleton height="50px" width="100%" />
      </Flex>
    );
  }

  if (isError || error || (!isLoading && Array.isArray(data) && data.length < 1)) {
    return (
      <Container flex justifyContent="center" width="100%" paddingTop="48px">
        Notifications Not Found
      </Container>
    );
  }

  return (
    <>
      <S.NotifContainer>
        {Array.isArray(data) &&
          data.map(notification => (
            <NotificationRow
              notification={notification}
              headers={headers}
              handleEdit={handleEdit}
              handleDel={handleDel}
            />
          ))}
      </S.NotifContainer>
      <ConfirmDialogue isLoading={!!loadingUpdateNotification} />
    </>
  );
};

export default NotificationsList;
