import styled from "styled-components";

export const RoundSelector = styled.div`
  width: 76px !important;
  border-radius: 14.5px;
  border: 2px solid #6c47ff;
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 11px;
  font-size: 14px;
  color: #fff;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.disabled ? "default" : "inherit")};
`;

export const RoundSelectionMenu = styled.div`
  width: 167px;
  height: 163px;
  background-color: #3f2faa;
  border-radius: 4px;
  padding: 11px 8px;
  font-weight: 100;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
`;

export const Tick = styled.div`
  width: 24px;
  height: 24px;
  border: ${props => (props.active ? "#unset" : `2px solid  ${props.checkBorder ? props.checkBorder : "#4E3CCA"}`)};
  display: flex;
  align-items: center;
  border-radius: 12px;
  justify-content: center;
  background-color: ${props => (props.active ? "#6C47FF" : "transparent")};
  cursor: pointer;
`;

export const OptionRowContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 9px;
`;
