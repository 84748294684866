import React, { useEffect, useState } from "react";
import * as S from "./styles";

import Modal from "../../../../../components/common/Modal/Modal";
import Text from "../../../../../components/common/Text/Text";
import { ButtonUnstyled } from "../../../../../components/common/Button";
import Flex from "../../../../../components/common/Flex";

const DeleteConfirmationModal = ({
  contactIdToDelete,
  deleteContactInfoMutation,
  isLoading,
  isMainOrgContactInfo,
  setContactIdToDelete,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => setOpenModal(prev => !prev);

  const [updatePools, setUpdatePools] = useState(false); // Variable to control if change in contact info should update all active pools

  async function handleDelete() {
    try {
      await deleteContactInfoMutation({ id: contactIdToDelete, updatePools });
    } catch (err) {
      console.log("Error: ", err);
    }
  }
  useEffect(() => {
    if (contactIdToDelete) setOpenModal(true);
    else setOpenModal(false);
  }, [contactIdToDelete]);

  return (
    <Modal
      customStyles={{ minWidth: "400px", paddingTop: "20px", maxWidth: "600px" }}
      showModal={openModal}
      toggleModal={toggleModal}
      afterClose={() => setContactIdToDelete(null)}
    >
      <Text fontSize="24px">Delete contact info?</Text>
      <S.ModalMessageText>
        You are about to delete this contact info. There is no way to recuperate once it is deleted
      </S.ModalMessageText>

      {isMainOrgContactInfo && (
        <Flex alignItems="center" columnGap="8px">
          <S.CustomCheckbox checked={updatePools} onChange={e => setUpdatePools(e.target?.checked)} />
          <Text color="#A498ED" fontSize="14px">
            Delete this contact info in all active tournaments
          </Text>
        </Flex>
      )}
      <S.ButtonContainer>
        <S.ConfirmButton onClick={() => handleDelete()} disabled={isLoading}>
          Yes, delete
        </S.ConfirmButton>
        <ButtonUnstyled onClick={() => setOpenModal(false)} disabled={isLoading}>
          Cancel
        </ButtonUnstyled>
      </S.ButtonContainer>
    </Modal>
  );
};

export default DeleteConfirmationModal;
