import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as S from "./styles";
import { Banner } from "../../components/views/home/Banner";
import { ButtonOutlineRound, IconButton } from "../../components/common/Button";
import Flex from "../../components/common/Flex";
import { Add, CloseIcon, Filter, Finish, Search } from "../../assets/svg";
import { TournamentRow } from "../../components/views/home/TournamentRow";
// import { TournamentFilter } from "../../components/Home/Filter/Filter.jsx";
import { useQuery } from "@tanstack/react-query";
import { getPoolsByOrgId } from "../../api/pool";
import { useGetSecHeaders } from "../../hooks/useHeaders";
import Skeleton from "react-loading-skeleton";
import LoadingSkeleton from "../../components/common/LoadingSkeleton";
import { getUserOrganizationId } from "../../helpers/localStorage";
import { useLogin } from "../../providers/Loginprovider";
import { TournamentFilter } from "../../components/views/home/TournamentFilter";
import SearchBar from "../../components/views/home/TournamentFilter/SearchBar.jsx";
import Container from "../../components/common/Container/Container";
import { useSession } from "../../providers/SessionProvider.jsx";
import Menu from "../../components/common/Menu/index.jsx";
import { MovePlayerModal } from "../../components/views/home/MovePlayerModal/index.jsx";
import { getGamesList } from "../../api/game.js";
import { formatTimeStamp, poolStatusFormatter } from "../../utilities/index.js";

const PoolList = ({ poolList = [], isPoolListError = false, isPoolListLoading = false }) => {
  const { session } = useSession();
  if (isPoolListError) {
    return <div>Something went wrong while loading pools. Please try again later</div>;
  }

  // Headers being displayed while data is loading
  if (isPoolListLoading) {
    return (
      <>
        <S.TableHeader>
          <div>
            <S.TableHeaderText>ID</S.TableHeaderText>
          </div>
          <div>
            <S.TableHeaderText>Name</S.TableHeaderText>
          </div>
          <div>
            <S.TableHeaderText>Game</S.TableHeaderText>
          </div>
          <div>
            <S.TableHeaderText>Registration Date</S.TableHeaderText>
          </div>
          <div>
            <S.TableHeaderText>Tournament Date</S.TableHeaderText>
          </div>
          <div>
            <S.TableHeaderText>Status</S.TableHeaderText>
          </div>
          <div>
            <S.TableHeaderText>Disabled/Enabled</S.TableHeaderText>
          </div>
        </S.TableHeader>

        <S.PoolListLoadingContainer>
          <LoadingSkeleton height="88px" />
          <LoadingSkeleton height="88px" />
          <LoadingSkeleton height="88px" />
        </S.PoolListLoadingContainer>
      </>
    );
  }

  if (poolList.length < 1) {
    return (
      <Flex justifyContent="center" alignItems="center" style={{ height: "100px" }}>
        No tournaments to show
      </Flex>
    );
  }

  return poolList && poolList.length > 0 ? (
    <S.InnerContainer>
      <S.TableContainer>
        <S.TableHeader>
          <div>
            <S.TableHeaderText>ID</S.TableHeaderText>
          </div>
          <div>
            <S.TableHeaderText>Name</S.TableHeaderText>
          </div>
          <div>
            <S.TableHeaderText>Game</S.TableHeaderText>
          </div>
          <div>
            <S.TableHeaderText>Registration Date</S.TableHeaderText>
          </div>
          <div>
            <S.TableHeaderText>Tournament Date</S.TableHeaderText>
          </div>
          <div>
            <S.TableHeaderText>Status</S.TableHeaderText>
          </div>
          <div>
            <S.TableHeaderText>Disabled/Enabled</S.TableHeaderText>
          </div>
        </S.TableHeader>
        <S.TableBody>
          {poolList.map(poolData => (
            <TournamentRow data={poolData} userType={session?.user?.userType} />
          ))}
        </S.TableBody>
      </S.TableContainer>
    </S.InnerContainer>
  ) : null;
};

export const HomePage = () => {
  const [filterProperties, setFilterProperties] = useState({});
  const [propertyToRemove, setPropertyToRemove] = useState(null);
  const [displayBanner, setDisplayBanner] = useState(false);
  const [displayFilter, setDisplayFilter] = useState(false);
  const headers = useGetSecHeaders();
  const { orgIdState } = useLogin();
  const { session } = useSession();

  const [showMovePlayerModal, setShowMovePlayerModal] = useState(false);

  const toggleMovePlayerModal = () => {
    setShowMovePlayerModal(!showMovePlayerModal);
  };

  const filterLength = Object.keys(filterProperties)?.length;
  const {
    data: poolList,
    // Replacing isLoading with isInidialLoading because of the following issue: https://github.com/TanStack/query/issues/3584
    isInitialLoading: isPoolListLoading,
    isError: isPoolListError,
  } = useQuery({
    queryKey: ["pools", { organizationId: orgIdState, filters: filterProperties }],
    queryFn: () => getPoolsByOrgId(orgIdState, headers, filterProperties),
    refetchOnWindowFocus: false,
    enabled: !!headers?.encryptedHeader && !!orgIdState, // enabled needs a boolean property. The !! operator converts the value of headers?.encryptedHeader to a boolean
  });

  const { data: activeGames } = useQuery({
    queryKey: ["games"],
    queryFn: getGamesList,
    refetchOnWindowFocus: false,
  });

  const gamesMapById = activeGames
    ? activeGames.reduce((acc, curr) => {
        return { ...acc, [curr["id"]]: curr };
      }, {})
    : {};

  function filterReadableName(key, value) {
    if (key === "poolStatus") return poolStatusFormatter(value);
    if (key === "game") return gamesMapById[value]?.visibleName;
    if(key==="startDate" || key === "endDate") return formatTimeStamp(value, "DD MMM, HH:MM A")
    return value;
  }

  const toggleFilter = () => {
    setDisplayFilter(!displayFilter);
  };

  useEffect(() => {
    const storedDisplayBanner = localStorage.getItem("displayBanner");
    if (storedDisplayBanner !== null) {
      setDisplayBanner(JSON.parse(storedDisplayBanner));
    } else {
      setDisplayBanner(true); // default to showing the banner
    }
  }, []); // this useEffect is in charge of show or not the banner based

  /**
   * The function `updateDisplayBanner` toggle the value of `prevDisplayBanner`, saves the new value in the
   * localStorage, and returns the updated value.
   * @returns The function `updateDisplayBanner` is returning the new value of `newDisplayBanner`, which
   * is a boolean value representing whether the display banner should be shown or not.
   */
  const updateDisplayBanner = prevDisplayBanner => {
    const newDisplayBanner = !prevDisplayBanner;
    localStorage.setItem("displayBanner", newDisplayBanner);
    return newDisplayBanner;
  };

  const toggleBanner = () => {
    setDisplayBanner(prevDisplayBanner => updateDisplayBanner(prevDisplayBanner));
  };

  return (
    <S.MainContainer>
      <S.AdjustPadding>
        <Banner displayBanner={displayBanner} toggleBanner={toggleBanner} />
        <S.InnerContainer>
          <S.Title>My Tournaments</S.Title>
          {/* <Button borderRadius="30px" height="37px" width="112px" fontWeight="500" fontSize="13px">
            <Add style={{ marginRight: "10px" }} /> Create
          </Button> */}
        </S.InnerContainer>
        <S.InnerContainer>
          <Container flex width="100%" justifyContent="space-between" alignItems="center">
            <Container flex columnGap="10px">
              <SearchBar filterProperties={filterProperties} setFilterProperties={setFilterProperties} />
              <S.FilterContainer style={{ position: "relative" }}>
                <S.FilterButton onClick={toggleFilter}>
                  <Filter />
                  <S.FilterItem>Filter</S.FilterItem>
                  {filterLength ? <S.FilterCircle>{filterLength}</S.FilterCircle> : null}
                </S.FilterButton>
                <TournamentFilter
                  toggleFilter={toggleFilter}
                  showFilter={displayFilter}
                  setShowFilter={setDisplayFilter}
                  filterProperties={filterProperties}
                  setFilterProperties={setFilterProperties}
                  propertyToRemove={propertyToRemove}
                  setPropertyToRemove={setPropertyToRemove}
                />
              </S.FilterContainer>
            </Container>

            {session?.user?.userType === "SUPERUSER" ? (
              <Flex alignItems="center" columnGap="10px">
                <ButtonOutlineRound
                  style={{
                    width: "112px",
                    height: "37px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "13px",
                  }}
                  onClick={toggleMovePlayerModal}
                >
                  Move player
                </ButtonOutlineRound>
                <Link
                  to={{ pathname: "/new" }}
                  style={{
                    background: "linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)",
                    width: "112px",
                    height: "37px",
                    borderRadius: "18.5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "13px",
                  }}
                >
                  Create
                </Link>
                <MovePlayerModal
                  poolList={poolList}
                  toggleModal={toggleMovePlayerModal}
                  showModal={showMovePlayerModal}
                />
              </Flex>
            ) : (
              <div />
            )}
          </Container>
        </S.InnerContainer>
        <Container flex columnGap="12px">
          {Object.entries(filterProperties)?.map(([key, value]) => (
            <S.FilterChip>
              <span>{filterReadableName(key, value)}</span>
              <IconButton style={{ display: "flex" }} onClick={() => setPropertyToRemove(key)}>
                <CloseIcon />
              </IconButton>
            </S.FilterChip>
          ))}
        </Container>

        <PoolList poolList={poolList} isPoolListError={isPoolListError} isPoolListLoading={isPoolListLoading} />
      </S.AdjustPadding>
    </S.MainContainer>
  );
};
