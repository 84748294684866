import React, { useState } from "react";
import * as S from "./styles";
// import { InfoIconNew } from "../../../assets/svg";
// import { capitalizeFirstLetter, truncateWithDots } from "../../../helpers/utils";
import { Tooltip } from "antd";
import {
  Add,
  DiscordSquareSmall,
  Edit,
  InfoOutlinePurple,
  Trash,
  TwitchIcon,
  WhatsApp,
} from "../../../../../assets/svg";
import Text from "../../../../../components/common/Text/Text";
import Modal from "../../../../../components/common/Modal/Modal";
import Container from "../../../../../components/common/Container/Container";
import { capitalizeFirstLetter, truncateWithDots } from "../../../../../utilities";
import { ButtonOutline, IconButton } from "../../../../../components/common/Button";
import AddOrEditContactInfoForm from "./AddOrEditContactInfoForm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createContactInfo, deleteContactInfo, updateContactInfo } from "../../../../../api/contactInfo";
import { useParams } from "react-router-dom";
import { useLogin } from "../../../../../providers/Loginprovider";
import { useGetSecHeaders } from "../../../../../hooks/useHeaders";
import { notifyError } from "../../../../../components/common/ToastComponent";
import Flex from "../../../../../components/common/Flex";
import useConfirm from "../../../../../hooks/useConfirm";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

{
  /* isMainContactInfo will be used to provide UI for updating main organization contact info rather than a pool contact info */
}
const ContactSection = ({ organizerInfo = [], isMainOrgContactInfo = false, organizationId }) => {
  const [showModal, setShowModal] = useState(false);
  const [existingData, setExistingData] = useState(null);
  const [contactIdToDelete, setContactIdToDelete] = useState();
  const { poolId } = useParams();
  const { orgIdState } = useLogin();
  const headers = useGetSecHeaders();

  const queryClient = useQueryClient();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const orgIdToSend = organizationId || orgIdState; // If adding contact info in organization modal, we need to send the selected organizaation's id in the API calls. Otherwise, we need to send the current logged in user's organization ID.

  const { mutate: createContactInfoMutation, isLoading: isCreatingContactInfo } = useMutation({
    mutationFn: data => {
      return createContactInfo(
        {
          organizationId: orgIdToSend,
          poolId,
          data,
          isMainOrgContactInfo,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: resp => {
      if (isMainOrgContactInfo) {
        // Updating query cache data instead of invalidating query data because updating cache is simple in this case and we will avoid an extra api call.
        queryClient.setQueryData(["organization", orgIdToSend], prevData => {
          return {
            ...prevData,
            organizerInfo: resp?.data || [...prevData.organizerInfo],
          };
        });
      } else {
        queryClient.invalidateQueries({ queryKey: ["pool", poolId] });
      }
      setShowModal(false);
    },
  });

  const { mutate: updateContactInfoMutation, isLoading: isUpdatingContactInfo } = useMutation({
    mutationFn: data => {
      return updateContactInfo(
        {
          organizationId: orgIdToSend,
          poolId,
          data,
          isMainOrgContactInfo,
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: (resp, variable) => {
      if (isMainOrgContactInfo) {
        // The response brings back all contact info rows but in random order.
        // So manually updating the cache
        queryClient.setQueryData(["organization", orgIdToSend], prevData => {
          return {
            ...prevData,
            organizerInfo: prevData?.organizerInfo?.map(row => {
              if (row.id != variable.id) return row;
              else return resp?.data?.find(info => info.id === variable.id);
            }),
          };
        });
      } else {
        queryClient.invalidateQueries({ queryKey: ["pool", poolId] });
      }
      setShowModal(false);
    },
  });

  const { mutateAsync: deleteContactInfoMutation, isLoading: isDeletingContactInfo } = useMutation({
    mutationFn: async ({ id, updatePools }) => {
      return await deleteContactInfo(
        {
          organizationId: orgIdToSend,
          poolId,
          contactId: id,
          isMainOrgContactInfo,
          ...(isMainOrgContactInfo && { updatePools }),
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.message || e?.data);
    },
    onSuccess: (_, variable) => {
      if (isMainOrgContactInfo) {
        queryClient.setQueryData(["organization", orgIdToSend], prevData => {
          return {
            ...prevData,
            organizerInfo: prevData?.organizerInfo?.filter(row => row.id != variable.id),
          };
        });
      } else {
        queryClient.invalidateQueries({ queryKey: ["pool", poolId] });
      }
      setContactIdToDelete(null);
    },
  });

  function handleEdit(item) {
    setExistingData(item);
    setShowModal(true);
  }

  function handleDelete(id) {
    setContactIdToDelete(id);
  }

  const isLoadingAction = isCreatingContactInfo || isUpdatingContactInfo;
  return (
    <>
      <Container marginBottom="24px" flex justifyContent="flex-end">
        <ButtonOutline
          borderRadius="50px"
          border="2px solid #6C47FF"
          width="189px"
          height="42px"
          onClick={() => setShowModal(true)}
          disabled={isLoadingAction}
        >
          <Add style={{ marginRight: "8px" }} />
          Add contact Info
        </ButtonOutline>
      </Container>
      <S.ContactRowsContainer>
        {organizerInfo.length ? (
          <S.RowHeadersContainer isMainOrgContactInfo={isMainOrgContactInfo}>
            <Text fontSize="14px" color="#A498ED">
              Nickname
            </Text>
            <Text fontSize="14px" color="#A498ED">
              Role
            </Text>
            {isMainOrgContactInfo ? (
              <Text fontSize="14px" color="#A498ED" style={{ paddingLeft: "16px" }}>
                Socials
              </Text>
            ) : (
              <>
                <Text fontSize="14px" color="#A498ED">
                  Twitch
                </Text>
                <Text fontSize="14px" color="#A498ED">
                  Discord
                </Text>
                <Text fontSize="14px" color="#A498ED">
                  Whatsapp
                </Text>
              </>
            )}
            <Text fontSize="14px" color="#A498ED">
              Extra Info
            </Text>
          </S.RowHeadersContainer>
        ) : null}
        {organizerInfo.map(item => (
          <S.ContactRow key={item.id} isMainOrgContactInfo={isMainOrgContactInfo}>
            <S.NameContainer>{item.nickname}</S.NameContainer>
            <span>{capitalizeFirstLetter(item.role)}</span>

            {isMainOrgContactInfo ? (
              <Flex
                columnGap="4px"
                style={{ border: "2px solid transparent", width: "90px", justifyContent: "center" }}
              >
                {item.discordLink && (
                  <a href={item.discordLink} target="_blank">
                    <DiscordSquareSmall />
                  </a>
                )}

                {item.whatsapp && (
                  <a href={item.whatsapp} target="_blank">
                    <WhatsApp />
                  </a>
                )}
                {item.twitchUsername && (
                  <a href={`https://www.twitch.tv/${item.twitchUsername}`} target="_blank">
                    <TwitchIcon />
                  </a>
                )}
              </Flex>
            ) : (
              <>
                <span>{item.twitchUsername || "-"}</span>

                <span style={{ paddingLeft: "20px" }}>
                  {item.discordLink ? (
                    <a href={item.discordLink} target="_blank">
                      <DiscordSquareSmall />
                    </a>
                  ) : (
                    "-"
                  )}
                </span>

                <span style={{ paddingLeft: "20px" }}>
                  {item.whatsapp ? (
                    <a href={item.whatsapp} target="_blank">
                      <WhatsApp />
                    </a>
                  ) : (
                    "-"
                  )}
                </span>
              </>
            )}

            <Flex style={{ paddingLeft: "20px" }}>
              {item.extraInfo ? (
                <Tooltip
                  title={item.extraInfo}
                  color="#7265C8"
                  align="center"
                  overlayStyle={{
                    maxWidth: "256px",
                    fontSize: "11px",
                    padding: "11px 8px",
                    paddingBottom: "0",
                    borderRadius: "6px",
                    zIndex: "99999",
                  }}
                >
                  <InfoOutlinePurple style={{ justifySelf: "self-end" }} />
                </Tooltip>
              ) : null}
            </Flex>

            <Flex columnGap="23px" justifyContent="flex-end">
              <IconButton onClick={() => handleEdit(item)}>
                <Edit />
              </IconButton>

              <IconButton disabled={isDeletingContactInfo} onClick={() => handleDelete(item.id)}>
                <Trash />
              </IconButton>
            </Flex>
          </S.ContactRow>
        ))}
      </S.ContactRowsContainer>

      <Modal
        showModal={showModal}
        toggleModal={toggleModal}
        afterClose={() => setExistingData(null)}
        customStyles={{ width: "585px", padding: "20px 25px" }}
        laptopStyles={{ width: "100%" }}
        mobileStyles={{ width: "100%" }}
        header="Add Contact Info"
      >
        <AddOrEditContactInfoForm
          {...{
            createContactInfoMutation,
            updateContactInfoMutation,
            existingData,
            isLoadingAction,
            isMainOrgContactInfo,
          }}
        />
      </Modal>

      <DeleteConfirmationModal
        deleteContactInfoMutation={deleteContactInfoMutation}
        isLoading={isDeletingContactInfo}
        contactIdToDelete={contactIdToDelete}
        isMainOrgContactInfo={isMainOrgContactInfo}
        setContactIdToDelete={setContactIdToDelete}
      />
    </>
  );
};

export default ContactSection;
