import styled from "styled-components";
import { breakpoints } from "../../../../themes";
import { Button } from "../../../common/Button";
import { PENDING_ADJUSTMENTS, PROCESSING_REGISTERED_USERS, RUNNING, STARTED } from "../../../../constants/status";

export const TournamentRowContainer = styled.div`
  height: 80px;
  width: 100%;
  border-radius: 6px;
  background-color: #3f2faa;
  align-items: center;
  padding: 0 22px;
  display: grid;
  grid-template-columns: 0.6fr 2fr repeat(3, 1.5fr) repeat(2, 1fr);
  border: ${props => (props.poolStatus === PENDING_ADJUSTMENTS ? "2px solid #ED6140" : "unset")};
  ${breakpoints.down(breakpoints.tablet)} {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-top: 17px;
    padding-bottom: 17px;
  }
  ${breakpoints.down(breakpoints.mobileS)} {
    padding: 11px;
  }
`;

export const TournamentText = styled.span`
  font-style: normal;
  font-weight: 325;
  font-size: 14px;
  line-height: 17px;
`;

export const TournamentData = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const StatusIndicator = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  ${props => `
    display  : ${
      props.status === RUNNING || props.status === STARTED || props.status === PROCESSING_REGISTERED_USERS
        ? `block`
        : "none"
    };    
  `}
  background: #b2fa4f;
`;

export const StatusText = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 325;
  line-height: normal;
`;

export const Image = styled.img`
  width: 49px;
  height: 49px;
  border-radius: 8px;
`;

export const PoolLink = styled.a`
  font-size: 14px;
  ${breakpoints.down(breakpoints.mobileL)} {
    font-size: 12px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const Switch = styled.div`
  position: relative;
  width: 45px;
  height: 24px;
  background: #4e3cca;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;
  cursor: ${props => (props.disabled ? "default" : "pointer")};

  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    top: 50%;
    left: 4px;
    background: #a498ed;
    box-shadow: 0px 4px 4px rgba(48, 40, 98, 0.2);
    transform: translate(0, -50%);
  }
`;

export const SwitchInput = styled.input`
  opacity: 0;
  position: absolute;
  display: none;

  &:checked + ${Switch} {
    background: #6c47ff;
    cursor: ${props => (props.disabled ? "wait" : "pointer")};
    &:before {
      transform: translate(20px, -50%);
      background: #fff;
    }
  }
`;

export const ThreeDotsContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const ThreeDotsButtonContainer = styled.div`
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => (props.actionIsToggled ? "#4e3cca" : "transparent")};
  border-radius: 6px;
`;

export const ActionButton = styled(Button)`
  width: 100%;
  height: 34px;
  border-radius: 4px;
  background: #4e3cca;
  justify-content: flex-start;
  gap: 10px;
  &:disabled {
    color: #ffffff80;
  }
`;

export const ActionLabel = styled.span`
  color: #fff;
  font-size: 14px;
  font-weight: 325;
`;

export const LabelSmallScreen = styled.label`
  display: none;
  color: #a498ed;
  font-size: 12px;
  font-weight: 325;
  margin-top: ${props => (props?.marginTop ? props.marginTop : "unset")};
  margin-bottom: ${props => (props?.marginBottom ? props.marginBottom : "unset")};
  ${breakpoints.down(breakpoints.tablet)} {
    display: block;
  }
`;

export const DatesRowContainer = styled.div`
  display: block;
  margin-top: 12px;
  ${breakpoints.down(breakpoints.tablet)} {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 12px;
    div {
      padding: 2px 5px;
      background-color: #4e3cca;
      min-width: 145px;
      border-radius: 4px;
      width: 100%;
    }
  }
  ${breakpoints.down(breakpoints.mobileM)} {
    div {
      min-width: 122px;
      padding: 2px 4px;
    }
  }
`;

export const DateText = styled.div`
  font-size: 13px;
  ${breakpoints.down(breakpoints.tablet)} {
    font-size: 12px;
    font-weight: 325;
  }
  ${breakpoints.down(breakpoints.mobileM)} {
    font-size: 11px;
  }
`;
