import axiosClient from "./axiosClient";

export function getPoolById({ poolId, orgId }, headers) {
  if (!poolId) throw new Error("Please enter a valid pool id");
  if (!orgId) throw new Error("Please enter a valid organization id");
  return axiosClient
    .get(
      `/admPanel/pool/info/${orgId}/${poolId}`,

      { headers: { Authorization: headers?.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function createContactInfo({ organizationId, poolId, data, isMainOrgContactInfo = false }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");

  if (isMainOrgContactInfo) data.organizationId = organizationId; // API call for isMainOrgContactInfo needs org ID in the data
  const endpoint = isMainOrgContactInfo
    ? `/admPanel/organization/contactInfo/${organizationId}`
    : `/admPanel/pool/contactInfo/${organizationId}/${poolId}`;

  return axiosClient
    .post(endpoint, data, {
      headers: { Authorization: headers.encryptedHeader },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}
export function updateContactInfo({ organizationId, poolId, data, isMainOrgContactInfo = false }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");

  if (isMainOrgContactInfo) data.organizationId = organizationId; // API call for isMainOrgContactInfo needs org ID in the data
  const endpoint = isMainOrgContactInfo
    ? `/admPanel/organization/contactInfo/${organizationId}`
    : `/admPanel/pool/contactInfo/${organizationId}/${poolId}`;

  return axiosClient
    .put(endpoint, data, {
      headers: { Authorization: headers.encryptedHeader },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}
export function deleteContactInfo({ organizationId, poolId, contactId, isMainOrgContactInfo, updatePools }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");

  const endpoint = isMainOrgContactInfo
    ? `/admPanel/organization/contactInfo/${organizationId}`
    : `/admPanel/pool/contactInfo/${organizationId}/${poolId}`;

  return axiosClient
    .delete(endpoint, {
      headers: { Authorization: headers.encryptedHeader },
      data: {
        organizationId,
        id: contactId,
        updatePools
      },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}
