import moment from "moment";
import {
  ARCHIVED,
  BRACKETS_CREATED,
  FINISHED,
  FINISHED_COLLECTING_DATA,
  JOINING,
  NOT_STARTED,
  PROCESSING_REGISTERED_USERS,
  RUNNING,
  STARTED,
  PENDING_REVISION,
  PENDING_TO_APROVE,
  PENDING_ADJUSTMENTS,
} from "../constants/status";
import { notifySuccess } from "../components/common/ToastComponent";
import { MaticWhite, USDTWhite } from "../assets/svg";

export const truncateWithDots = (str = "", maxLength) => {
  return str.length > maxLength ? `${str.slice(0, maxLength)}...` : str;
};

export const formatTimeStamp = (timeStamp, format) => {
  return moment(timeStamp)?.format(format) || "";
};

export const poolStatusFormatter = status => {
  if ([STARTED, RUNNING].includes(status)) {
    return "Running";
    // } else if([RUNNING].includes(status)) {
    //   return "Running"
  } else if ([PROCESSING_REGISTERED_USERS].includes(status)) {
    return "Processing Data";
  } else if ([FINISHED_COLLECTING_DATA].includes(status)) {
    return "Finsihed Processing Data";
  } else if ([NOT_STARTED].includes(status)) {
    return "Not Started";
  } else if ([JOINING].includes(status)) {
    return "Registration Open";
  } else if ([ARCHIVED].includes(status)) {
    return "Finished - Hidden";
  } else if ([FINISHED].includes(status)) {
    return "Finished";
  } else if ([BRACKETS_CREATED].includes(status)) {
    return "Brackets Created";
  } else if ([PENDING_REVISION].includes(status)) {
    return "Pending Revision";
  } else if ([PENDING_TO_APROVE].includes(status)) {
    return "Pending To Approve";
  } else if ([PENDING_ADJUSTMENTS].includes(status)) {
    return "Changes Required";
  }

  return status || "";
};

export function getOrganizationsFromSession(session) {
  return session?.user?.organizations || [];
}

export function isNonEmptyObject(obj) {
  if (!obj || typeof obj !== "object" || Array.isArray(obj)) return false;

  return Object.keys(obj)?.length > 0;
}

export function truncateWithMiddleDots(str = "", limitLength = 8, substringLength = 4) {
  if (str.length < limitLength) return str;
  return `${str.substring(0, substringLength)}...${str.substring(str.length - substringLength)}`;
}

export function copyTextToClipboard(text, message = "Copied successfully to clipboard") {
  if (!text) return;
  navigator.clipboard.writeText(text);
  notifySuccess(message);
}

export function capitalizeFirstLetter(str = "") {
  if (str.length < 2) return str.toUpperCase();
  return str.slice(0, 1).toUpperCase() + str.slice(1)?.toLowerCase();
}

// export const sanitizeHTML = htmlCode => {
//   const sanitizedHTML = DOMPurify.sanitize(htmlCode);
//   return sanitizedHTML;
// };ß

export function getPlayers(leaderboard) {
  // get the players of leaderboard (leaderboard is an array of objects, inside each object there is a playerData object)
  const players = leaderboard.map(player => player.playerData);
  // from playerData object, get the property players (which is an array of objects and get the length of each array)
  const playersLength = players.map(player => player.players?.length);
  // sum all the length of players array
  const totalPlayers = playersLength.reduce((a, b) => a + b, 0);
  return totalPlayers;
}

export function formatNumberWithLetters(number = 1) {
  const remainder10 = number % 10;
  const remainder100 = number % 100;

  // Handling special cases for 11, 12, and 13
  if (remainder10 === 1 && remainder100 !== 11) {
    return number + "st";
  } else if (remainder10 === 2 && remainder100 !== 12) {
    return number + "nd";
  } else if (remainder10 === 3 && remainder100 !== 13) {
    return number + "rd";
  } else {
    return number + "th";
  }
}

export function teamJoinStatusFormatter(status = "") {
  switch (status) {
    case "accepted":
      return "Accepted";

    case "pending-confirm":
      return "Pending Confirm";

    case "pending-accept":
      return "Pending Accept";

    case "deleted":
      return "Deleted";

    default:
      return status;
  }
}

export const padStart = (val, padAmount = 2, padItem = "0") => {
  try {
    let str = val.toString();
    return str.padStart(padAmount, padItem);
  } catch (err) {
    return "00";
  }
};

export const cleanRulesForm = ({ clearTitle, clearContent }) => {
  clearTitle("");
  clearContent("");
  return;
};

export function rangeArray(x, y) {
  if (x > y) [x, y] = [y, x];
  return Array.from({ length: y - x + 1 }, (_, i) => x + i);
}

export function createArrayFromNumber(number = 1) {
  return [...Array(number).keys()];
}

// Adds an s to a term if number is 1.
export function pluralize(num = 1, term) {
  if (!term) return ``;
  return `${term}${num !== 1 ? "s" : ""}`;
}

export function timeAgo(timeArg) {
  const isValidTimestamp = moment(timeArg).isValid();
  if (!isValidTimestamp) return "N/A";

  const timeStamp = moment(timeArg);
  const current = moment();

  const difference = moment.duration(current - timeStamp);

  const data = difference._data || {};

  const { days, hours, minutes, months, years } = data;

  if (years > 0) return `${years} ${pluralize(years, "year")} ago`;
  if (months > 0) return `${months} ${pluralize(months, "month")} ago`;
  if (days > 0) return `${days} ${pluralize(days, "day")} ago`;
  if (hours > 0) return `${hours} ${pluralize(hours, "hour")} ago`;
  return `${minutes} ${pluralize(minutes, "minute")} ago`;
}
