import axiosClient from "./axiosClient";

export function getAnalyticsData({ organizationId }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  return axiosClient
    .get(`/admPanel/organization/perfResume/${organizationId}`, { headers: { Authorization: headers.encryptedHeader } })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function getAnalyticsTable({ month, year, organizationId }, headers) {
  if (!organizationId) throw new Error("Please enter a valid organization id");
  if (!month) throw new Error("Please select a month");
  if (!year) throw new Error("Please select a year");

  return axiosClient
    .get(`/admPanel/organization/detailPerfResume/${organizationId}/${month}/${year}`, {
      headers: { Authorization: headers.encryptedHeader },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}
