import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import * as S from "./styles";
import { ContactFormSchema } from "./schema";
import Flex from "../../../../../components/common/Flex";
import { Dropdown } from "../../../../../components/common/Dropdown";
import { ButtonRound } from "../../../../../components/common/Button";
import ErrorTooltip from "../../../../../components/common/ErrorTooltip";
import Text from "../../../../../components/common/Text/Text";

const AddOrEditContactInfoForm = ({
  createContactInfoMutation,
  existingData,
  updateContactInfoMutation,
  isLoadingAction = false,
  isMainOrgContactInfo = false,
}) => {
  const {
    register,
    handleSubmit,
    // trigger,
    // getValues,
    setError,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(ContactFormSchema),
  });

  function processSubmission(data) {
    if (data.role === "caster" && !data.twitchUsername) {
      setError("twitchUsername", { type: "custom", message: "Twitch username is required for casters" });
      return;
    }

    if (isMainOrgContactInfo) data.updatePools = updatePools;
    if (existingData && existingData.id) {
      updateContactInfoMutation({ id: existingData.id, ...data });
    } else {
      createContactInfoMutation(data);
    }
  }

  const [updatePools, setUpdatePools] = useState(false); // Variable to control if change in contact info should update all active pools

  useEffect(() => {
    if (existingData) {
      setValue("nickname", existingData.nickname);
      setValue("role", existingData.role);
      setValue("discordLink", existingData.discordLink);
      setValue("whatsapp", existingData.whatsapp);
      setValue("twitchUsername", existingData.twitchUsername);
      setValue("extraInfo", existingData.extraInfo);
    }
  }, [existingData]);

  return (
    <div>
      <S.Form onSubmit={handleSubmit(processSubmission)}>
        <S.RowContainer>
          <S.Label htmlFor="nickname">Nickname</S.Label>
          <S.Input {...register("nickname")} id="nickname" hasError={!!errors.nickname} />
          {errors.nickname && <ErrorTooltip top="55%" message={errors.nickname?.message} />}
        </S.RowContainer>

        <S.RowContainer>
          <S.Label htmlFor="role">Role</S.Label>
          <Dropdown
            hideArrow
            containerStyles={{ marginTop: "5px", background: "#4e3cca" }}
            selectionItemCss={{ display: "flex", alignItems: "center" }}
            options={[
              { value: "caster", name: "Caster" },
              { value: "organizer", name: "Organizer" },
            ]}
            hasError={!!errors.role}
            setDefaultNull
            value={watch("role")}
            onChange={e => {
              clearErrors("role");
              clearErrors("twitchUsername");
              setValue("role", e);
            }}
          />
          {errors.role && <ErrorTooltip top="55%" right="40px" message={errors.role?.message} />}
        </S.RowContainer>

        <S.RowContainer>
          <S.Label htmlFor="discordLink">Discord</S.Label>
          <S.Input {...register("discordLink")} id="discordLink" hasError={!!errors.discordLink} />
          {errors.discordLink && <ErrorTooltip top="55%" message={errors.discordLink?.message} />}
        </S.RowContainer>

        <S.RowContainer>
          <S.Label htmlFor="whatsapp">WhatsApp link</S.Label>
          <S.Input {...register("whatsapp")} id="whatsapp" hasError={!!errors.whatsapp} />
          {errors.whatsapp && <ErrorTooltip top="55%" message={errors.whatsapp?.message} />}
        </S.RowContainer>

        <S.RowContainer>
          <S.Label htmlFor="twitchUsername">Twitch</S.Label>
          <S.Input {...register("twitchUsername")} id="twitchUsername" hasError={!!errors.twitchUsername} />
          {errors.twitchUsername && <ErrorTooltip top="55%" message={errors.twitchUsername?.message} />}
        </S.RowContainer>

        <S.RowContainer>
          <S.Label htmlFor="extraInfo">Extra Info</S.Label>
          <S.TextArea rows={4} {...register("extraInfo")} id="extraInfo" hasError={!!errors.extraInfo} />
          {errors.extraInfo && <ErrorTooltip top="55%" message={errors.extraInfo?.message} />}
        </S.RowContainer>

        {isMainOrgContactInfo && (
          <Flex alignItems="center" columnGap="8px">
            <S.CustomCheckbox checked={updatePools} onChange={e => setUpdatePools(e.target?.checked)} />
            <Text color="#A498ED" fontSize="14px">
              Update all active tournaments with the new contact info.
            </Text>
          </Flex>
        )}

        <Flex justifyContent="center">
          <ButtonRound Round type="submit" width="98px" height="43px" disabled={isLoadingAction}>
            Submit
          </ButtonRound>
        </Flex>
      </S.Form>
    </div>
  );
};

export default AddOrEditContactInfoForm;
