import React from "react";
import * as S from "../styles";
import LeaderboardRow from "./LeaderboardRow";

const LeaderboardComponent = props => {
  let {
    displayData = [],
    leaderboardData = [],
    joinMode,
    isRoundRobin,
    setPlayersToMoveGroup,
    setPlayerToSwapGroup,
  } = props;

  if (!Array.isArray(displayData) || displayData.length < 1) {
    displayData = [{ dataKey: "score", dataOrder: 1, dataTitle: "Score" }];
  }

  const getFilteredLeaderboardData = data => {
    return (
      data
        // .filter(player => player.bestMatch && player.bestMatch.totalPoints !== undefined)
        .sort((a, b) => {
          const aPoints = a.points;
          const bPoints = b.points;

          const aNick = a.nick || a.nickname;
          const bNick = b.nick || b.nickname;

          if (aPoints === bPoints || (!a && !b)) {
            // If points are equal, or both players dont have points, sort by name
            if (aNick < bNick) return -1;

            if (aNick > bNick) return 1;

            return 0;
          } else {
            return bPoints - aPoints;
          }
        })
    );
  };

  const filteredLeaderboardData = getFilteredLeaderboardData(leaderboardData);

  return (
    <div>
      <S.LeaderboardSectionContainer>
        <S.LeaderboardSectionInnerContainer>
          {leaderboardData.length > 0 ? (
            <S.LeaderboardHeader dynamicColumnsCount={displayData?.length}>
              <div>Ranking</div>
              <div>Nickname</div>
              {displayData?.map(dataItem => (
                <div>{dataItem.dataTitle}</div>
              ))}
              <div></div>
            </S.LeaderboardHeader>
          ) : null}
          <S.LeaderboardRowsContainer>
            {filteredLeaderboardData?.map((rowData, index) => (
              <LeaderboardRow
                key={rowData.id}
                rank={index + 1}
                {...rowData}
                displayData={displayData}
                joinMode={joinMode}
                isRoundRobin={isRoundRobin}
                setPlayersToMoveGroup={setPlayersToMoveGroup}
                setPlayerToSwapGroup={setPlayerToSwapGroup}
                rowData={rowData}
              />
            ))}
          </S.LeaderboardRowsContainer>
        </S.LeaderboardSectionInnerContainer>
      </S.LeaderboardSectionContainer>
    </div>
  );
};

export default LeaderboardComponent;
