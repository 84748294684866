import styled from "styled-components";

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 52px;
`;

export const ModalTitle = styled.span`
  font-size: 28px;
  font-weight: 325;
  line-height: 33.6px;
  text-align: left;
`;

export const DateSelector = styled.div`
  background-color: #3f2faa;
  width: 211px;
  height: 37px;
  border-radius: 4px;
  padding: 12px;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  gap: 10px;
`;
