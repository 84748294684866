import styled from "styled-components";
import { breakpoints } from "../../themes";
import { Button } from "../../components/common/Button";

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  color: white;
  padding: 0px 76px 10px 81px;
  ${breakpoints.down(breakpoints.laptopL)} {
    padding: 0px 38px 10px 47px;
  }
  ${breakpoints.down(breakpoints.laptop)} {
    padding: 0px 16px 10px 16px;
  }
`;

export const SectionContainer = styled.div`
  margin-top: 45px;
  display: flex;
  column-gap: 45px;
  & > div {
    border-radius: 6px;
    flex: 1;
    padding: 35px 34px;
  }
  & > div:first-child {
    min-height: ${props => (props.isConfirmScreen ? `fit-content` : `643px`)};
    background-color: #342788;
    width: 9000px !important;
  }

  & > div:nth-child(2) {
    background: ${props => (props.isConfirmScreen ? `#342788` : `transparent`)};
    padding: ${props => (props.isConfirmScreen ? `35px 34px` : `0`)};
    height: ${props => (props.isConfirmScreen ? `438px` : `unset`)};
  }
`;

export const TemplateSelectContainer = styled.div`
  height: 40px;
  width: 100%;
  border-radius: 6px;
  background-color: rgba(78, 60, 202, 1);
  display: flex;
  align-items: center;
  border: ${props => (props.hasError ? "1px solid #ED6140" : "unset")};
`;

export const Divider = styled.div`
  margin-right: 12px;
  margin-left: 12px;
  height: 70%;
  width: 2px;
  border-right: 2px solid rgba(96, 78, 221, 1);
`;

export const TemplateInput = styled.input`
  width: 100%;
  color: #fff;
  height: 100%;
  padding: 0 12px;
  border-radius: 6px;
  background-color: transparent;
  outline: none;
  border: none;
  &::placeholder {
    color: #a498ed;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  justify-content: space-between;
  @media (max-width: 1024px) {
    margin-bottom: 10px;
  }
`;

export const NewNotifBtn = styled(Button)`
  height: 37px;
  width: 178px;
  font-size: 11px;
  border-radius: 30px;
  span {
    margin-left: 4px;
  }
  @media (max-width: 768px) {
    width: 70px;
    span {
      display: none;
    }
  }
`;

export const HeaderText = styled.p`
  font-size: 38px;
  margin: 0;
  padding: 0;

  @media (max-width: 1200px) {
    font-size: 24px;
  }
  @media (max-width: 1024px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  column-gap: 18px;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 18px;
    align-items: flex-start;
  }
`;

export const SearchInput = styled.input`
  width: 406px;
  height: 38px;
  background-color: rgba(63, 47, 170, 1);
  border-radius: 20px;
  outline: none;
  border: none;
  margin-top: 4px;
  padding: 0 16px;
  color: #fff;
  display: ${props => (!props.mobile ? "block" : "none")};

  &::placeholder {
    color: #a498ed;
  }

  @media (max-width: 1024px) {
    display: ${props => (props.mobile ? "block" : "none")};
    width: 100%;
    margin: ${props => (props.mobile ? "16px 0 32px" : "0")};
  }
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
`;
// flex justifyContent="space-between" width="100%" marginTop="24px"
export const RowContainer = styled.div`
  display: flex;
  row-gap: 8px;
  position: relative;
  flex-direction: column;
  margin-bottom: 16px;
  width: 100%;

  label {
    width: 88px;
    font-size: 13px;
  }
`;

export const TextInput = styled.input`
  height: 40px;
  border-radius: 4px;
  padding: 12px 15px;
  border: none;
  color: #fff;
  /* border: 1px solid #7a6ddb; */
  border: ${props => (props.hasError ? "1px solid #ED6140" : "unset")};
  outline: none;
  background-color: #4e3cca;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  /* input[type="number"] {
    -moz-appearance: textfield;
  } */

  width: ${props => (props.width ? "100%" : "")};
`;

export const Pill = styled.div`
  height: 21px;
  background-color: #fff;
  border-radius: 30px;
  color: #342788;
  font-size: 10px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
  padding: 0 9px;
`;

export const SubmitButton = styled(Button)`
  width: 98px;
  height: 43px;
  font-size: 16px;
  margin-top: 16px;
  border-radius: 30px;
`;

export const NotifRow = styled.div`
  width: 100%;
  height: 50px;
  border-radius: 6px;
  background-color: #3f2faa;
  display: grid;
  padding-left: 22px;
  padding-right: 22px;
  grid-template-columns: 4fr 1fr 0.5fr;
  align-items: center;

  p {
    margin: 0;
    padding: 0;
    font-size: 15px;
  }

  div:last-child {
    justify-self: end;
  }

  @media (max-width: 600px) {
    grid-template-columns: 4fr 1fr;
    height: fit-content;
    padding: 22px;

    div:last-child {
      justify-self: start;
      margin-top: 24px;
    }

    p,
    span {
      font-size: 13px;
    }
  }
`;

export const NotifContainer = styled.div`
  display: flex;
  row-gap: 12px;
  flex-direction: column;
`;

export const Status = styled.span`
  font-size: 11px;
  background-color: rgba(78, 60, 202, 1);
  width: fit-content;
  max-width: 200px;
  padding: 5px 11px;
  border-radius: 20px;
  display: flex;
  column-gap: 4px;
  align-items: center;
`;

export const ActivitySelectionMenu = styled.div`
  width: fit-content;
  height: fit-content;
  background-color: rgba(78, 60, 202, 1);
  border-radius: 4px;
  padding: 11px 14px;
  font-weight: 100;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
`;
