import styled from "styled-components";

const Section = styled.div`
  /* width */
  min-width: ${props => props.minWidth};
  max-width: ${props => (props.maxWidth ? props.maxWidth : "unset")};
  &::-webkit-scrollbar {
    width: 8px;
    height: ${props => (props.scrollHeight ? props.scrollHeight : "40px")};
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${props => props.trackbg};
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${props => props.thumbBg};
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${props => props.thumbHoverBg};
  }

  overflow-x: auto;
`;

const ScrollableSection = ({
  children,
  maxWidth,
  minWidth,
  scrollWidth = "8px",
  scrollHeight,
  trackbBg = "#4e3cca",
  thumbBg = "#342788f0",
  thumbHoverBg = "#342788",
  customStyles = {},
}) => {
  return (
    <Section
      {...{ maxWidth, minWidth, scrollWidth, scrollHeight, trackbBg, thumbBg, thumbHoverBg }}
      style={{ ...customStyles }}
    >
      {children}
    </Section>
  );
};

export default ScrollableSection;
