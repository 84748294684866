import { useMemo } from "react";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useLogin } from "../../providers/Loginprovider";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { notifyError } from "../common/ToastComponent";

function createQueryClientWithContext({ logout }) {
  function globalErrorHandler(error, isQuery = false) {
    // The onError callback is being deprecated. Hence, in order to display toasts on error for queries, we are using the approach below.
    // The alert should ONLY run for queries as we are passing onError for mutations wherver we are using them

    if (error?.status === 401 || error?.status === 403) {
      notifyError("User unauthenticated");
      logout();
    } else if (isQuery) {
      let message = "";
      if (typeof error === "string") {
        message = error;
      } else {
        message = error?.message || error?.data || "Something went wrong";
      }
      notifyError(message);
    }
  }

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
    queryCache: new QueryCache({
      onError: async (error, query) => {
        if(query.meta?.disableGlobalErrorHandler) return; // Disabling global error handler for queries given this meta property
        globalErrorHandler(error, true);
      },
    }),
    mutationCache: new MutationCache({
      onError: async error => {
        globalErrorHandler(error);
      },
    }),
    //   });
  });

  return queryClient;
}

export function useCustomQueryClient() {
  const { logout } = useLogin();

  const queryClient = useMemo(() => createQueryClientWithContext({ logout }), []);

  return queryClient;
}

function CustomQueryClientProvider({ children }) {
  const queryClient = useCustomQueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      {children} <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default CustomQueryClientProvider;
