import axiosClient from "./axiosClient";

export function createNotification({ data }, headers) {
  return axiosClient
    .post(`/admPanel/nc/create/`, data, {
      headers: { Authorization: headers.encryptedHeader },
    })
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function updateNotification({ data, notificationId }, headers) {
  if (!notificationId) throw new Error("Please provide a valid notification id");

  return axiosClient
    .put(
      `/admPanel/nc/id/${notificationId}`,
      {
        ncNotification: {
          ...data,
        },
      },
      {
        headers: { Authorization: headers.encryptedHeader },
      },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export function getNotifications({ searchValue = "" }, headers) {
  return axiosClient
    .get(
      `/admPanel/nc/${searchValue}`,

      { headers: { Authorization: headers?.encryptedHeader } },
    )
    .then(res => res.data)
    .catch(err => {
      throw err.response;
    });
}

export async function deleteNotification({ notificationId }, headers) {
  if (!notificationId) throw new Error("Please provide a valid notification id");

  try {
    const res =  await axiosClient
    .delete(
      `/admPanel/nc/id/${notificationId}`,

      { headers: { Authorization: headers?.encryptedHeader } },
    )
    
    return res.data;
  } catch(err) {
    throw err.response
  }
 
}
