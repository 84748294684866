import React, { useEffect, useState } from "react";
import * as S from "../../../views/Notifications/styles";
import Text from "../../common/Text/Text";
import Modal from "../../common/Modal/Modal";
import Container from "../../common/Container/Container";
import { Dropdown } from "../../common/Dropdown";
import { useForm } from "react-hook-form";
import { NotificationSchema } from "./schema";
import { zodResolver } from "@hookform/resolvers/zod";
import { ButtonLink, ButtonUnstyled } from "../../common/Button";
import ErrorTooltip from "../../common/ErrorTooltip";
import CheckboxDropdown from "../../common/DropdownWithCheckbox";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getGamesList } from "../../../api/game";
import { SpinnerLoader } from "../../common/SpinningLoader";
import TempalteSelect from "./TempalteSelect";
import PillDropdown from "../newTournament/regularPoolForm/PillDropdown";
import TagInput from "../../common/TagInput";
import { useGetSecHeaders } from "../../../hooks/useHeaders";
import { createNotification, updateNotification } from "../../../api/notifications";
import { notifyError, notifySuccess } from "../../common/ToastComponent";

const TRIGGER_OPTIONS = [
  { name: "New User", value: "NEW_USER" },
  { name: "User connect to a gaming account", value: "USER_GAMING_ACC_CONN" },
  { name: "User has prize to claim", value: "USER_HAS_PRIZE_TO_CLAIM" },
  { name: "User invited to team", value: "USER_INVITED_TO_TEAM" },
  { name: "New tournament created on blast", value: "NEW_TOURNAMENT" },
  { name: "Tournament about to start", value: "TOURNAMENT_ABOUT_TO_START" },
];

const MEDIUM_OPTIONS = [
  { name: "Email", value: "Email" },
  { name: "Discord", value: "Discord" },
  { name: "SMS", value: "SMS" },
];

const TAG_OPTIONS = [
  { name: "Tag#1", value: "Tag#1" },
  { name: "Tag#2", value: "Tag#2" },
  { name: "Tag#3", value: "Tag#3" },
];

const NewNotificationModal = ({ open, toggleModal, existingData, setExistingData }) => {
  const {
    watch,
    register,
    setValue,
    clearErrors,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(NotificationSchema),
    defaultValues: {
      // tags: [],
      // parameters: [],
    },
  });

  const headers = useGetSecHeaders();
  const queryClient = useQueryClient();

  const { mutate: createNotificationMutation, isLoading: loadingCreateNotification } = useMutation({
    mutationFn: variables => {
      return createNotification(
        {
          data: variables,
        },
        headers,
      );
    },
    onError: (e, variables) => {
      notifyError(e?.data?.message || e?.message || e?.data);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
      });
      notifySuccess(data?.message || `Notificaiton created successfully`);
      toggleModal();
    },
  });

  const { mutate: updateNotificaitonMutation, isLoading: loadingUpdateNotification } = useMutation({
    mutationFn: variables => {
      return updateNotification(
        {
          notificationId: existingData.id,
          data: {
            ...variables,
          },
        },
        headers,
      );
    },
    onError: e => {
      notifyError(e?.data?.message || e?.message || e?.data);
    },
    onSuccess: data => {
      queryClient.invalidateQueries({
        queryKey: ["notifications"],
      });
      notifySuccess(data?.message || `Notificaiton updated successfully`);
      toggleModal();
    },
  });

  function processSubmission(data) {
    if (existingData) {
      updateNotificaitonMutation(data);
    } else {
      createNotificationMutation(data);
    }
  }

  useEffect(() => {
    if (existingData) {
      setValue("description", existingData.description);
      setValue("trigger", existingData.trigger);
      setValue("medium", existingData.medium);
      setValue("template", existingData.templateId);
      // setValue("tags", existingData.tags);
      // setValue("parameters", existingData.parameters);
      if (existingData.audience) {
        setValue("audience", existingData.audience);
      }
    }
  }, [existingData]);

  const {
    data: activeGames,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: ["games"],
    queryFn: getGamesList,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  function resetModalValues() {
    reset();
    setExistingData(null);
  }

  const trigger = watch("trigger");
  const medium = watch("medium");
  // const tags = watch("tags");
  // const parameters = watch("parameters");
  const audience = watch("audience");

  // function handleTagChange(string) {
  //   const extraInfoArr = [...(tags || [])];
  //   if (extraInfoArr.includes(string)) {
  //     setValue(
  //       "tags",
  //       extraInfoArr.filter(item => item != string),
  //     );
  //   } else {
  //     setValue("tags", [...extraInfoArr, string]);
  //   }
  // }

  const descriptionError = errors?.description?.message;
  const mediumError = errors?.medium?.message;
  const triggerError = errors?.trigger?.message;
  const audienceError = errors?.audience?.message;
  const templateError = errors?.template?.message;
  // const tagsError = errors?.tags?.message;
  // const parametersError = errors?.parameters?.message;

  return (
    <Modal
      showModal={open}
      customStyles={{ width: "585px", height: "520px", borderRadius: "6px", overflowY: "visible", top: "50%" }}
      tabletStyles={{ width: "95%" }}
      mobileStyles={{ width: "90%" }}
      toggleModal={toggleModal}
      afterClose={resetModalValues}
    >
      <Container flex flexDirection="column" justifyContent="space-between" height="85%">
        <form onSubmit={handleSubmit(processSubmission)}>
          <S.ModalHeader>
            <Text fontSize="28px">New notification</Text>
            {/* <ButtonLink type="button">Send A Test</ButtonLink> */}
          </S.ModalHeader>

          <S.RowContainer>
            <label>Title</label>
            <S.TextInput
              {...register("description")}
              hasError={!!descriptionError}
              onInput={() => clearErrors("description")}
            />
            {!!descriptionError && <ErrorTooltip message={descriptionError} top="55%" />}
          </S.RowContainer>

          <Container flex justifyContent="space-between" columnGap="23px" width="100%">
            <S.RowContainer>
              <label>Trigger</label>
              <Dropdown
                hideArrow
                options={TRIGGER_OPTIONS}
                hasError={!!triggerError}
                // defaultValue={currentInputValues?.gameId}
                // setDefaultNull={!currentInputValues?.gameId}
                setDefaultNull={true}
                value={trigger}
                onChange={e => {
                  clearErrors("trigger");
                  setValue("trigger", e);
                }}
                containerStyles={{ background: "#4E3CCA" }}
              />
              {!!triggerError && <ErrorTooltip message={triggerError} top="55%" right="30px" />}
            </S.RowContainer>

            <S.RowContainer>
              <label>Medium</label>
              <Dropdown
                options={MEDIUM_OPTIONS}
                value={medium}
                onChange={e => {
                  clearErrors("medium");
                  setValue("medium", e);
                }}
                dropdownBtnStyles={{ width: "100%", background: "#4E3CCA" }}
                containerStyles={{ background: "#4E3CCA" }}
                setDefaultNull
              />
              {!!mediumError && <ErrorTooltip message={mediumError} top="55%" right="30px" />}
            </S.RowContainer>
          </Container>

          <S.RowContainer>
            <label>Audience</label>
            {isLoading || isFetching ? (
              <SpinnerLoader />
            ) : activeGames && Array.isArray(activeGames) ? (
              <Dropdown
                hideArrow
                options={activeGames?.map(game => ({
                  value: game.id,
                  name: game.name,
                  element: (
                    <Container flex columnGap="14px">
                      <img src={game.assets?.gameThumbnail} style={{ height: "26px", width: "26px" }} />
                      <Text>{game.visibleName}</Text>
                    </Container>
                  ),
                }))}
                hasError={!!audienceError}
                setDefaultNull
                // defaultValue={currentInputValues?.gameId}
                // setDefaultNull={!currentInputValues?.gameId}
                value={audience}
                onChange={e => {
                  clearErrors("audience");
                  setValue("audience", e);
                }}
                containerStyles={{ background: "#4E3CCA" }}
              />
            ) : null}
            {!!audienceError && <ErrorTooltip message={audienceError?.message} />}
          </S.RowContainer>

          <S.RowContainer>
            <label>Template</label>
            <TempalteSelect
              template={watch("template")}
              setTemplate={val => setValue("template", val)}
              hasError={!!templateError}
              clearErrors={clearErrors}
            />
            {templateError && <ErrorTooltip message={templateError} />}
          </S.RowContainer>

          {/* <S.RowContainer style={{ alignItems: "flex-start" }}>
            <label>
              <Container flex alignItems="center" columnGap="8px">
                Tags
              </Container>
            </label>

            <Container flex flexDirection="column" rowGap="10px" style={{ width: "100%" }}>
              <PillDropdown
                hasError={!!tagsError}
                options={TAG_OPTIONS}
                currentSelected={tags}
                onChange={handleTagChange}
                containerStyles={{ background: "#4E3CCA" }}
              />

              {tags && tags?.length ? (
                <Container flex columnGap="8px" rowGap="8px" style={{ flexWrap: "wrap" }}>
                  {tags.map(item => (
                    <S.Pill>
                      <span>{item}</span>
                      <ButtonUnstyled type="button" onClick={() => handleTagChange(item)} style={{ padding: 0 }}>
                        x
                      </ButtonUnstyled>
                    </S.Pill>
                  ))}
                </Container>
              ) : null}
            </Container>

            {!!tagsError && <ErrorTooltip message={tagsError} />}
          </S.RowContainer>

          <S.RowContainer>
            <label>Parameters</label>

            <TagInput
              hasError={!!parametersError}
              tags={parameters}
              setTags={e => {
                clearErrors("audience");
                setValue("parameters", e);
              }}
            />

            {!!parametersError && <ErrorTooltip message={parametersError} />}
          </S.RowContainer> */}

          <Container flex justifyContent="center">
            <S.SubmitButton disabled={loadingCreateNotification || loadingUpdateNotification} type="submit">
              Submit
            </S.SubmitButton>
          </Container>
        </form>
      </Container>
    </Modal>
  );
};

export default NewNotificationModal;
