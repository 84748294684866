import styled from "styled-components";
import DatePicker from "react-datepicker";

export const GraphContainer = styled.div`
  height: 400px;
  margin-left: ${props => (props.isLoading ? 0 : "-25px")};
  @media (max-width: 768px) {
    height: 300px;
  }
`;

export const LegendText = styled.span`
  font-size: 14px;
  color: #fff;
  margin-right: 24px;
`;

export const TooltipContainer = styled.div`
  width: fit-content;
  height: fit-content;
`;

export const TableContainer = styled.div`
  width: 100%;
  margin-top: 24px;
`;

export const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr 0.7fr 0.7fr;
  color: #a498ed;
  font-size: 14px;
  font-weight: 325;
  line-height: 16px;
  justify-items: start;
  padding: 0 24px;
  margin-bottom: 36px;

  @media (max-width: 990px) {
    font-size: 12px;
    padding: 0;
    margin-bottom: 18px;
  }
  @media (max-width: 475px) {
    font-size: 10px;
    padding: 0;
  }
`;

export const TableRow = styled.div`
  height: 50px;
  display: grid;
  grid-template-columns: 1fr 4fr 0.7fr 0.7fr;
  justify-items: start;
  border-radius: 6px;
  align-items: center;
  background-color: #3f2faa;

  width: 100%;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 0 24px;

  div {
    padding-left: 6px;
  }

  div:nth-child(2) {
    max-width: 700px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media (max-width: 1400px) {
    div:nth-child(2) {
      max-width: 600px;
    }
  }

  @media (max-width: 1250px) {
    div:nth-child(2) {
      max-width: 500px;
    }
  }
  @media (max-width: 1000px) {
    div:nth-child(2) {
      max-width: 400px;
    }
  }
  @media (max-width: 850px) {
    div:nth-child(2) {
      max-width: 350px;
    }
  }
  @media (max-width: 650px) {
    font-size: 14px;
    div:nth-child(2) {
      max-width: 300px;
    }
  }
  @media (max-width: 600px) {
    padding: 0px;

    div:nth-child(2) {
      max-width: 250px;
    }
  }
  @media (max-width: 500px) {
    font-size: 12px;
    div:nth-child(2) {
      max-width: 250px;
    }
  }
  @media (max-width: 470px) {
    font-size: 12px;
    div:nth-child(2) {
      max-width: 200px;
    }
  }
  @media (max-width: 400px) {
    font-size: 12px;
    div:nth-child(2) {
      max-width: 180px;
    }
  }
  @media (max-width: 375px) {
    div:nth-child(2) {
      font-size: 12px;
      max-width: 180px;
    }
  }
  @media (max-width: 350px) {
    font-size: 10px;
    div:nth-child(2) {
      max-width: 150px;
    }
  }
`;

export const TableDatePicker = styled(DatePicker)`
  height: 40px;
  outline: none;
  font-size: 16px;
  border: none;
  width: 200px;
  color: #ffffff;
  border-radius: 4px;
  background-color: #3f2faa;
  padding-left: 12px !important;
`;
