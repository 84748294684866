import React, { useEffect, useState } from "react";
import * as S from "../../../views/Notifications/styles";
import { Dropdown } from "../../common/Dropdown";
import { CaretArrow } from "../../../assets/svg";

const TempalteSelect = ({ template, setTemplate, hasError = false, clearErrors }) => {
  // const [templateType, setTemplateType] = useState("");

  // useEffect(() => {
  //   if (templateType === "HTML") setPlaceholder("Copy and paste your HTML here");
  //   if (templateType === "ID external") setPlaceholder("Add external ID");
  //   if (templateType === "Any text") setPlaceholder("Enter text");
  // }, [templateType]);
  return (
    <S.TemplateSelectContainer hasError={hasError}>
      {/* <Dropdown
        containerStyles={{ background: "#4E3CCA", width: "120px", height: "22px" }}
        dropdownStyles={{ background: "#4E3CCA" }}
        dropdownOptionStyles={{ background: "#4E3CCA" }}
        options={[
          { name: "HTML", value: "HTML" },
          { name: "ID external", value: "ID external" },
          { name: "Any text", value: "Any text" },
        ]}
        suffixIcon={<CaretArrow />}
        defaultValue={templateType}
        onChange={val => setTemplateType(val)}
      />

      <S.Divider /> */}
      <S.TemplateInput
        placeholder="Enter template here"
        value={template}
        onChange={e => {
          setTemplate(e.target.value);
          clearErrors("template");
        }}
      />
    </S.TemplateSelectContainer>
  );
};

export default TempalteSelect;
