import styled from "styled-components";
import { DefaultAvatar } from "../../../../../assets/svg";

export const AvatarContainer = styled.div`
  top: 46px;
  left: 30px;
  width: 112.8px;
  height: 112.8px;
  position: absolute;
  border-radius: 100px;
  background-size: cover;
  border: ${props => (props.hideBorder ? "unset" : "3px solid #be7eff")};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 665px) {
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const InfoContainer = styled.div`
  bottom: 11px;
  left: 160px;
  position: absolute;
  display: flex;
  column-gap: 14px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 13px;
  color: #a498ed;
  font-weight: 325;

  @media (max-width: 665px) {
    display: none;
    /* flex-direction: column;
    align-items: start;
    row-gap: 8px;
    bottom: 42px;
    left: 350px; */
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  column-gap: 7px;
  align-items: center;
`;

export const ExternalProviderBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: 8px;
  margin-right: 23px;
  @media (max-width: 665px) {
    margin-top: 8px;
    align-items: flex-start;
  }
`;

export const Avatar = styled.div`
  width: 100.8px;
  height: 100.8px;
  border-radius: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.src || "/avatar-default.png"})`};
`;

export const ImagesContainer = styled.div`
  height: 119px;
  display: flex;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${props => `url(${props.src || "/banner-default.png"})`};
`;
