import React, { useState } from "react";
import { Popover } from "antd";

import * as S from "./styles";
import { DataRow } from "../../shared/styles";
import Text from "../../../../common/Text/Text";
import { Trash } from "../../../../../assets/svg";
import { Button, IconButton } from "../../../../common/Button";
import { capitalizeFirstLetter, truncateWithMiddleDots } from "../../../../../utilities";
import Container from "../../../../common/Container/Container";
import { CheckBox } from "../../../../common/Checkbox";
import CheckboxDropdown from "../../../../common/DropdownWithCheckbox";

const OrganizationRow = ({ data = {}, userId, mutatePermission }) => {
  const options = [
    {
      name: "Create",
      value: "CREATE",
    },
    {
      name: "Read",
      value: "READ",
    },
    {
      name: "Update",
      value: "UPDATE",
    },
    {
      name: "Delete",
      value: "DELETE",
    },
  ];

  // EXPLANATION: The pervPermission and currentPermission state are used to manage the state of the permissions dropdown
  // The dropdown value is controlled via the currentPermission state
  // The currentPermission state is updated with the new dropdown value as soon as the onChange effect of the dropdown is called (when the user uses the dropdown)
  // The onChange also calls the POST request to the update permissions API call.
  // If the API request fails, the currentPermission state is reverted to the old stateusing the prevPermission state.
  // If the API request is successful, the prevPermission state is also updated with the new value
  const [currentPermissions, setCurrentPermissions] = useState(data.userPermissions || []);
  const [openDelPopover, setOpenDelPopover] = useState(false);

  const [prevPermission, setPrevPermission] = useState(currentPermissions);
  function permissionUpdateErrorCb() {
    setCurrentPermissions(prevPermission);
  }
  function permissionUpdateSuccessCb(val) {
    setPrevPermission(val);
  }
  const hideDelPopover = () => {
    setOpenDelPopover(false);
  };

  const handleOpenChange = newOpen => {
    setOpenDelPopover(newOpen);
  };

  return (
    <DataRow>
      <S.OrganizationRowWrapper>
        <Text fontSize="13px">{truncateWithMiddleDots(data.organizationId)}</Text>
        <Text fontSize="13px">{data.organizationName}</Text>
        <CheckboxDropdown
          options={options}
          title={
            currentPermissions.length <= 0
              ? ``
              : currentPermissions.length > 1
              ? `${currentPermissions.length} selected`
              : `${capitalizeFirstLetter(currentPermissions[0])}`
          }
          selectedValues={currentPermissions}
          onSelectedValuesChange={vals => {
            setCurrentPermissions(vals);
            mutatePermission({
              organizationId: data.organizationId,
              selectedOrganizationId: data.id,
              permissions: vals,
              userId: userId,
              errorCb: permissionUpdateErrorCb,
              successCb: () => permissionUpdateSuccessCb(vals),
            }); // data.id will hold the id of this row's organization
          }}
          dropdownBtnStyles={{
            width: "100px",
            height: "25px",
            background: "#4E3CCA",
            borderRadius: "4px",
          }}
          dropdownOptionStyles={{
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            marginTop: "8px",
            width: "50%",
            // background: "orange",
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
          closeOnClickOutside
        />
      </S.OrganizationRowWrapper>

      {/* =========================================== */}

      <Popover
        content={
          <Container marginTop="22px">
            <Button
              width="100%"
              background="#3F2FAA"
              height="50.885px"
              borderRadius="30px"
              onClick={() => {
                hideDelPopover();
                // handleDeleteRowClick();
              }}
            >
              Confirm
            </Button>
          </Container>
        }
        zIndex="9999"
        title="Are you sure you want to delete this organization?"
        trigger="click"
        open={openDelPopover}
        onOpenChange={handleOpenChange}
        color="#7265C8"
        overlayInnerStyle={{
          color: "brown !important",
          width: "220px",
          padding: "19px",
          textAlign: "center",
        }}
        overlayStyle={{
          color: "green !important",
        }}
        overlayClassName="match-report-popover"
      >
        <IconButton onClick={() => setOpenDelPopover(true)}>
          <Trash />
        </IconButton>
      </Popover>
      {/* =========================================== */}
    </DataRow>
  );
};

export default OrganizationRow;
