import React from "react";
import styled from "styled-components";

const Switch = styled.div`
  position: relative;
  width: 45px;
  height: 24px;
  background: #4e3cca;
  border-radius: 32px;
  padding: 4px;
  transition: 300ms all;
  opacity: ${props => (props.loading || props.disabled ? 0.5 : 1)};
  cursor: ${props => (props.loading ? "wait" : props.disabled ? "default" : "pointer")};
  &:before {
    transition: 300ms all;
    content: "";
    position: absolute;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    top: 50%;
    left: 4px;
    background: #a498ed;
    box-shadow: 0px 4px 4px rgba(48, 40, 98, 0.2);
    transform: translate(0, -50%);
  }
`;

const SwitchInput = styled.input`
  opacity: 0;
  position: absolute;
  display: none;

  &:checked + ${Switch} {
    background: #6c47ff;

    &:before {
      transform: translate(20px, -50%);
      background: #fff;
    }
  }
`;

const SwitchLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

const ToggleSwitch = ({ checked = false, toggleRef = null, disabled = false, onChange, loading = false }) => {
  return (
    <SwitchLabel>
      <SwitchInput
        type="checkbox"
        checked={checked}
        ref={toggleRef}
        onChange={e => onChange(e)}
        disabled={disabled || loading}
      />
      <Switch disabled={disabled} loading={loading} />
    </SwitchLabel>
  );
};

export default ToggleSwitch;
