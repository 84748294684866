import styled from "styled-components";

export const Button = styled.button`
  width: ${props => (props.width ? props.width : "auto")};
  height: ${props => (props.height ? props.height : "31px")};
  /* padding-left: 16px; */
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : "16px")};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : "16px")};
  padding-top: ${props => (props.paddingRight ? props.paddingRight : "0px")};
  padding-bottom: ${props => (props.paddingRight ? props.paddingRight : "0px")};

  padding: ${props => (props.padding ? props.padding : "")};
  /* padding-right: 16px; */
  border: ${props => (props.border ? props.border : "none")};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : "8px")};
  color: ${props => (props.color ? props.color : "#fff")};
  font-size: ${props => (props.fontSize ? props.fontSize : "12px")};
  line-height: ${props => (props.lineHeight ? props.lineHeight : "28px")};
  background: ${props =>
    !props.background ? "linear-gradient(141.97deg, #1DC5EC -117.24%, #AA57FF 92.36%)" : props.background};
  opacity: ${props => (props.disabled ? "0.5" : "1")};
  cursor: ${props => (props.disabled ? "default" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${props => (props.margin ? props.margin : "0px")};
  gap: ${props => (props.gap ? props.gap : "0px")};
  min-width: ${props => (props.minWidth ? props.minWidth : "unset")};

  &:hover {
    ${props => ({ ...props.hoverStyles })};
  }
`;

export const IconButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  width: min-content;
  border: none;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const ButtonOutline = styled(Button)`
  background: transparent;
  border: 1px solid #cccccc66;
  border-radius: 4px;
  ${props => ({ ...props })};
`;

export const ButtonOutlineRound = styled(Button)`
  background: transparent;
  border-radius: 100px;
  border: 1px solid #cccccc66;
  ${props => ({ ...props })};
`;

export const ButtonUnstyled = styled(Button)`
  background: ${props => (props.background ? props.background : "unset")};
  border: 0px;
  color: ${props => (props.color ? props.color : "#a498ed")};
  font-weight: 350;
  font-size: 13px;
  min-width: ${props => (props.minWidth ? props.minWidth : "unset")};
  /* ${props => ({ ...props })}; */
`;

export const ButtonRound = styled(Button)`
  border-radius: 100px;
  ${props => ({ ...props })};
`;

export const ButtonLink = styled(Button)`
  background: transparent;
  text-decoration: underline;
  color: #A498ED;
  font-weight: 350;
  font-size: 12px;
`;
