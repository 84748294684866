import { useEffect, useState } from "react";

function useDebounce(value, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => setDebouncedValue(value), delay);

    return () => clearTimeout(timeout); // This will run each time the value changes, before the next useEffect
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;