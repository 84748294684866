import moment from "moment";
import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

import * as S from "./styles";
import nameMap from "../../../constants/analyticsNameMap";
import { getAnalyticsData } from "../../../api/analytics";
import LoadingSkeleton from "../../common/LoadingSkeleton";
import { notifyWarning } from "../../common/ToastComponent";
import { useLogin } from "../../../providers/Loginprovider";
import { useGetSecHeaders } from "../../../hooks/useHeaders";

// Tooltip Styles
const tooltipContentStyle = { background: "#342788", padding: 16, border: "none", borderRadius: 8 };
const tooltipLabelStyle = { fontSize: 14, marginBottom: 12, fontWeight: "bolder" };
const tooltipItemStyle = { color: "#fff", fontSize: 12, lineHeight: 1.4 };

const AnalyticsBarGraph = () => {
  const headers = useGetSecHeaders();
  const { orgIdState } = useLogin();

  const {
    data: resp,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["analytics-chart", orgIdState],
    queryFn: () => getAnalyticsData({ organizationId: orgIdState }, headers),
    refetchOnWindowFocus: false,
    enabled: !!headers?.encryptedHeader && !!orgIdState,
  });

  const analyticsData = resp?.data?.data;
  const analyticsError = resp?.error;

  useEffect(() => {
    if (analyticsError || isError) notifyWarning("There was a problem loading analytics graph data");
  }, [isError, analyticsError]);

  if (analyticsError || isError) {
    return null;
  }
  if (isLoading) {
    return (
      <S.GraphContainer isLoading>
        <ResponsiveContainer width="100%" height="100%">
          <LoadingSkeleton />
        </ResponsiveContainer>
      </S.GraphContainer>
    );
  }

  return (
    <S.GraphContainer>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={analyticsData}>
          <CartesianGrid vertical={false} opacity={0.3} />
          <XAxis
            tickFormatter={(val) => moment(val, "M")?.format("MMMM")}
            dataKey="month"
            style={{
              fontSize: 10,
              fill: "#fff",
            }}
          
          />
          <YAxis
            style={{
              fontSize: 14,
              fill: "#fff",
            }}
          />
          <Tooltip
            cursor={{ fill: "#BE7EFF40" }}
            itemStyle={tooltipItemStyle}
            contentStyle={tooltipContentStyle}
            labelStyle={tooltipLabelStyle}
            labelFormatter={value => <span>{moment(value, "M")?.format("MMMM")}</span>}
            formatter={(value, name) => [value, nameMap[name]]}
          />
          <Legend wrapperStyle={{ top: "102%" }} formatter={value => <S.LegendText>{nameMap[value]}</S.LegendText>} />
          {/** Use content prop in order to update Legend UI */}
          <Bar dataKey="numOftournament" fill="#6C47FF" activeBar={<Rectangle />} />
          <Bar dataKey="joinedUsers" fill="#E547FF" activeBar={<Rectangle />} />
          <Bar dataKey="uniqueUsers" fill="#BE7EFF" activeBar={<Rectangle />} />
        </BarChart>
      </ResponsiveContainer>
    </S.GraphContainer>
  );
};

export default AnalyticsBarGraph;
