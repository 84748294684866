import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 41px;
  display: flex;
  padding: 0 12px;
  border-radius: 6px;
  align-items: center;
  background-color: rgba(78, 60, 202, 1);
  overflow: auto;
  margin-right: 42px;
  border: ${props => (props.hasError ? "1px solid #ED6140" : "unset")};
  &::-webkit-scrollbar {
    /* width: 0px; */
    height: 0;
  }
`;

export const Input = styled.input`
  color: #fff;
  width: 100%;
  min-width: 200px;
  height: 100%;
  border: none;
  outline: none;
  margin-left: 4px;
  background-color: transparent;
  /* background-color: lightyellow; */
  font-size: 12px;
  padding-bottom: 0px;
`;

export const TagsContainer = styled.div`
  display: flex;
  column-gap: 8px;
`;

export const Tag = styled.span`
  height: 21px;
  display: flex;
  padding: 0 7px;
  font-size: 12px;
  border-radius: 30px;
  align-items: center;
  color: rgba(52, 39, 136, 1);
  background-color: rgba(248, 248, 248, 1);
`;

export const TagRemoveBtn = styled.button`
    border: none;
    font-size: 12px;
    margin-left: 12px;
    color: rgba(52, 39, 136, 1);
    background-color: transparent;
    cursor: pointer;


`